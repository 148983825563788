import React, { Fragment, ReactChild, ReactElement } from 'react';
import { Placement } from 'tippy.js';
import Tippy from '@tippy.js/react';
import styled from 'styled-components';
import sassVars from "../../styles/variables.module.scss";
import '../../styles/tooltip.scss';

const { gray100 } = sassVars;

interface ICommonProps {
    className?: string;
}

interface IOwnProps extends ICommonProps {
    tooltipContent?: ReactChild;
    children: ReactChild;
    theme?: string;
    capitalize?: boolean;
    placement?: Placement;
    tooltipClassName?:string;
    customClass?:string;
    trigger?:string;
}

const OverflowTooltip = React.memo(
    ({
        tooltipContent,
        children,
        theme = 'custom',
        capitalize,
        className = '',
        placement = 'top',
        tooltipClassName='',
        customClass ='',
        trigger = 'mouseenter',
    }: IOwnProps) => {
        return children ? (
            <StyledTippy
                content={<div className={customClass}><span className="nextlineSpan">{tooltipContent || children}</span></div>}
                className={`se-tooltip ${tooltipClassName} with-tooltip${capitalize ? ' capitalize' : ''}`}
                allowHTML={true}
                arrow={true}
                theme={theme}
                placement='top'
                enabled
                animateFill
                animation="shift-away"
                trigger= {'mouseenter'}
                >
                <span className={`overflow-wrapper  ${className}`.trim()}>
                    {['span', 'a'].includes(
                        (children as ReactElement).type as string
                    ) ? (
                        children
                    ) : (
                        <span className={capitalize ? 'capitalize' : ''}>
                            {children}
                        </span>
                    )}
                </span>
            </StyledTippy>
        ) : (
            <Fragment />
        );
    }
);

const StyledTippy = styled(Tippy)`
    ul {
        padding-left: 5px;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .nextlineSpan {
        white-space: pre-line;
        text-align:left;
    }

    .tooltip-info-header {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        padding-bottom: 1px;
      }
    
    .tooltip-info-description { 
        color: #656579;
        font-family: EYInterstate;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
    }

    .tooltip-info-spacer {
        margin-top:1%;
    }

      .tooltip-class {
        font-size: 14px;
        padding: 8px;
        border-radius: 4px;
        width: 68vh;
    }
`;

OverflowTooltip.displayName = 'OverflowTooltip';

export default OverflowTooltip;
