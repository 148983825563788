import { Guid as guid } from 'guid-typescript';
import { authProvider } from "./msalConfig";

const sessionIdKey = 'session_id';

export const initializeSession = (isNewChatInitialized: boolean = false): void => {
  const sessionId = sessionStorage.getItem(sessionIdKey);
  if (sessionId === null || isNewChatInitialized) {
    sessionStorage.setItem(sessionIdKey, guid.create().toString());
  }
};

export const getCurrentSessionId = (): string | null => {
  const sessionId = sessionStorage.getItem(sessionIdKey);
  return sessionId;
};

export const setCurrentSessionId = (sessionId: string): void => {
  sessionStorage.setItem(sessionIdKey, sessionId);
}
export const logoutandClearSession = (): void => {
  localStorage.clear();
  const currentUrl =
    window.location.pathname === "error"
      ? window.location.origin
      : window.location.href;
  window.location.href =
    "https://login.microsoftonline.com/common/oauth2/logout?client_id=" +
    authProvider.getCurrentConfiguration().auth.clientId +
    "&post_logout_redirect_uri=" +
    encodeURIComponent(currentUrl);
};

export const setNewSessionId = (): void => {
  sessionStorage.setItem(sessionIdKey, guid.create().toString());
};
