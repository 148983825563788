const apiRoutesChat = {
  qnaApi: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/sui/extract_and_get_mr_answers_streaming`,
  saveuserFeedback: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/feedback/save`,
  exportCSVChat: "e-gpt/sql-query-to-csv",
  sqlPrompt: "",
  clearChat: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/sui/clear_user_session`,
  getAllReport: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/sui/get_all_mr_reports`,
  marketREportsSectorGeography: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SUI/get_mr_filter_json`,
  getPdf : `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/sui/get_pdf`,
  getMultiplePdf : `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/sui/download_zip`,
};

export default apiRoutesChat;
