import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../store';
import DASHBOARD_IMAGE from '../../assets/images/dashboard.svg';
import { useNavigate } from 'react-router-dom';
import { clearChatHistory, setClearLoader, setSelectedChatThread } from '../../store/actions/openAiActions';
import { getCurrentSessionId } from '../../services/session.service';
import { clearChat } from '../openai/services/ChatService';



const MarketReportLanding = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showPrivacyAndTermsModal = useSelector(
    (store: IStore) => store.common.showPrivacyAndTermsModal
  );
  const userData = useSelector((store: IStore) => store.userProfile?.userData);
  const displayName = userData?.displayName.split(' ')?.[0];

  const navigateToMarketReport = async() => {
    navigate('/market-report');

     dispatch(setClearLoader(true));
        const sessionId = getCurrentSessionId();
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "session-id": sessionId || "",
          },
        };
    
        try {
          await clearChat(sessionId);
          dispatch(clearChatHistory());
          dispatch(setClearLoader(false));
          dispatch(setSelectedChatThread({}));
        } catch (error) {
          dispatch(setClearLoader(false));
        }
  };

  return (
    <div>
      <StyleAppStoreComponent>
        <label className='welcome-text top-text'>Welcome, {displayName}</label>
        <div className='text top-text'>Let's view, download or query on Market Reports on different sectors</div>
        <div className='dashboard-landing'>
          <div className='dashboard-left'>
            <div className='left-text'>Market Reports</div>
            <div className='bold-text'>Explore Comprehensive <br /> Market Insights</div>
            <div className='text text-bottom'>Access, download, or analyze reports from diverse sectors to stay ahead of market trends</div>
            <button className='launch-button' onClick={navigateToMarketReport}>Launch</button>
          </div>
          <div className='dashboard-right'>
            <img src={DASHBOARD_IMAGE} alt='dashboard' />
          </div>
        </div>
      </StyleAppStoreComponent>
    </div>
  );
};

const StyleAppStoreComponent = styled.div`
  background: ##1A1A24;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-left: 12px;
  margin-bottom: 24px;
  width: 98%;

  .welcome-text {
    font-size: 28px;
    font-family: EYInterstate;
    font-weight: 400;
    line-height: 36px;
    color: #DEDEE2;
  }
  .text {
    font-size: 16px;
    font-family: EYInterstate;
    font-weight: 300;
    line-height: 24px;
    color: #A4A3B1;
    margin-top: 8px;
  }

 .dashboard-landing {
  width: 98%;
  max-width: 1900px;
  height: auto;
  min-height: 424px;
  border-radius: 6px;
  border: 1px solid #3A3A4A;
  display: flex;
  justify-content: space-between;
  background: #23232F;
  margin-top: 24px;
  margin-bottom: 200px;
  padding: 65px;
}
  .dashboard-left {
    width: 70%;
  }
  
  .dashboard-right {
    width: 30%;
  }

  .left-text {
    font-size: 14px;
    font-family: EYInterstate;
    font-weight: 300;
    line-height: 20px;
    color: #FF9831;
  }
  
  .bold-text {
    font-size: 46px;
    line-height: 60px;
    font-family: EYInterstate;
    font-weight: 400;
  }
    .text-bottom{
      margin: 24px 0 30px 0;
    }

    .launch-button{
      height: 48px;
      width: 158px;
      background: rgb(255, 255, 255);
      color: rgb(26, 26, 36);
      border-radius: 6px;
      outline: none;
      border: 1px solid #fff;
      margin-top: 10px;
    }

    .top-text {
      width: 100%;
      position: relative;
      left: 1%;
    }
    .dropdown-container {
      width: 100%;
      margin-top: 24px;
      position: relative;
      left: 1%;
    }
    .chips {
      margin-top: 10px;
      display: flex; 
      flex-wrap: wrap; 
      gap: 8px;
      margin-top: 10px;
    }
   
  .motif-chip {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 16px;
    margin-right: 8px;
    font-size: 14px;
  }

  .more-chips-container {
    display: inline-block; // This makes the container an inline-level block container.
    position: relative; // This establishes a new positioning context for the absolute positioning of the Popup.
  }

  .more-chips {
    cursor: pointer;
    color: blue;
    // Other styles for .more-chips
  }

  .popup {
    position: absolute;
    left: 110%; // Position the popup to the right of the "+{number} more" text
    top: 0; // Align the popup with the top of the "+{number} more" text
    background-color: #2e2e38;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    white-space: nowrap; // Prevent the popup content from wrapping
    // Add any additional styles for the popup
  }

  .popup-content {
    background-color: #17171c;
    padding: 20px;
    border-radius: 5px;
  }

  .popup-chip {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .chip-close {
    margin-left: 8px;
  }
`;

export default MarketReportLanding;