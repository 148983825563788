import React, { useState } from 'react';
import styled from 'styled-components';
import scssVar from '../../../styles/variables.module.scss';
import FooterModal from '../footerModal/FooterModal';
import { closeIcon } from '../../../commonImage';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '../../../store';

const Footer = (): JSX.Element => {
  const [openModal, setOpenModal] = useState<'privacy' | 'terms' | 'client' | null>(null);
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);

  return (
    //@ts-ignore
    <StyleFooter isRightSideBarOpen={isRightSideBarOpen}>
      <FooterModal
        show={!!openModal}
        type={openModal}
        onClose={(): void => setOpenModal(null)}
        headerIcon={<img onClick={() => setOpenModal(null)} className='se-close' src={closeIcon} />}
      />
      <div className='footer-main'>
        <div className='ie-sidebar-footer'>
          <div className='ie-row'>
            <div className='footer-col-8'>
              <p className='ie-body-sm'>
              EY refers to the global organization, and may refer to one or more, of the member firms of Ernst & Young Global Limited, each of which is a separate legal entity. Ernst & Young Global Limited, a UK company limited by guarantee, does not provide services to clients
              </p>
            </div>
            <div className={'text-right footer-col-4'}>
              <div
                className='ie-h5'
                onClick={(): void => {
                  localStorage.setItem('acceptPolicy', 'true');
                  setOpenModal('privacy');
                }}>
                Privacy Notice
              </div>
              {/* <div
                className='ie-h5'
                onClick={(): void => {
                  localStorage.setItem('acceptPolicy', 'true');
                  setOpenModal('terms');
                }}>
                Terms of Use
              </div> */}
              {/* <div
                className='ie-h5'
                onClick={(): void => {
                  localStorage.setItem('acceptPolicy', 'true');
                  setOpenModal('client');
                }}>
                  Client Demo Disclaimer
              </div>
              */}
              <div className='ie-h5'> 
                <a
                  href={'https://discovercontent.ey.net/knd24073j61gtw5l'} 
                  target='_blank'
                  className='engagement'
                  rel='noreferrer'>
                  Engagement Management Guidance
                </a>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyleFooter>
  );
};

const StyleFooter = styled.div<{
  isRightSideBarOpen: boolean;
}>`
  .footer-main {
    display: flex !important;
    overflow: hidden !important;
    padding: 0 !important;
    color: #1a1a24 !important;
    width: '100%';
    margin-left: ${(props) => props.isRightSideBarOpen && 'auto'};
    position: relative;
    z-index: 1;
    & .ie-body-sm {
      font-size: 10px !important;
      padding-left: 34px;
      font-family: ${scssVar.fontInterstate};
      line-height: 16px;
      font-weight: 600;
    }
    a {
      font-size: 10px !important;
      font-weight: 500 !important;
    }
    & .ie-h5 {
      color: #fff;
      cursor: pointer;
      display: inline-block;
      padding-right: 30px;
      font-family: ${scssVar.fontInterstate};
      font-size: 10px !important;
      line-height: 16px;
      letter-spacing: 0px;
      font-weight: 500 !important;
      text-align: right;
      & .engagement {
        color: #fff;
        text-decoration: none;
      }
    }
    & .text-right {
      padding: 15px 0 0 0;
      text-align: right;
    }
    .ie-sidebar-footer {
      width: 99%;
    }
    .ie-row {
      margin-right: -12px;
      margin-left: -12px;
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      background: #23232F;
    }
  }
`;

export default Footer;
