import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { ModalType } from '../../models/ModalType';
import Modal from '../common/modal/Modal';
import LoadIndicator from '../common/loadIndicator/LoadIndicator';

interface IOwnProps {
  show: boolean;
  closeModal: () => void;
  pdfUrl: string;
  fileName: string;
}

const MarketPreview: React.FC<IOwnProps> = ({ show, closeModal, pdfUrl, fileName }: IOwnProps) => {
  const [pdfUrlNew, setPdfUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (pdfUrl) {
      const byteCharacters = atob(pdfUrl);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      setPdfUrl(blobUrl);
      setIsLoading(false);

    }
  }, [pdfUrl]);

  return (
    //@ts-ignore
    <Modal
      isOpen={show}
      type={ModalType.Preview}
      headerTitle={fileName}
      actionBtnEnabled={false}
      showModalFooter={false}
      showHeaderIcon={true}
      width={window.innerWidth * 0.8}
      customHeaderClass='preview'
      customClass={'previews'}
      showHeaderTitleIcon={false}
      onCloseBtnClicked={closeModal}>
      <StyledPreviewModal>
      {isLoading ? (
          <div className="loader preview-loading">
            <LoadIndicator />
          </div>
        ) : (
          <iframe
            src={pdfUrlNew}
            className='preview-iframe'
            loading={'lazy'}
            name={fileName}
            referrerPolicy={'no-referrer'}></iframe>
        )}
      </StyledPreviewModal>
    </Modal>
  );
};

const StyledPreviewModal = styled.div`
  padding: 0px;
  background: rgb(51 51 51);
  color: black;
  overflow: hidden;
  .ie-error {
    text-align: left;
  }
  & .text-center {
    text-align: center;
  }
  .icon-i-sign-out {
    display: inline;
    font-size: 3.4375rem;
    margin-left: 2px;
  }
  .confirmation-text {
    margin-top: 1.375rem;
    margin-bottom: 1.875rem;
    letter-spacing: 0;
    line-height: 22px;
    display: inline;
    vertical-align: 7px;
    font-size: 14px;
    line-height: 22px;
  }
  .preview-iframe {
    height: 90vh;
    width: 100%;
    background-olor: rgb(51 51 51);
    border: none;
  }
`;

export default React.memo(MarketPreview);
