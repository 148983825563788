import React, { useState } from "react";
import styled from "styled-components";
import CHAT_ICON from "../../assets/images/chat.svg";
import CHAT_ICON_HEADER from "../../assets/images/chatIcon.svg";
import CROSS_BUTTON from "../../assets/images/crossButton.svg";
import { numberWithCommas } from "../../utils/commonUtils";
import OpenAISidebar from "../openai/component/Sidebar";

const MarketReportNumber = (props: any): JSX.Element => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <StyledNumberPage selectedSourceRadio={props.selectedSourceRadio}>
            <div> Showing {numberWithCommas(props.rowData?.length)} Market Reports</div>
            {!isSidebarOpen && (
            <div className="button-container">
                <button className="button chat-button" onClick={toggleSidebar}>
                    <img src={CHAT_ICON} alt="" />{props.selectedRows === 0 ? "Chat with all reports" : `Chat with ${props.selectedRows} ${props.selectedRows === 1 ? "report" : "reports"}`}
                </button>
            </div>
            )}
            {isSidebarOpen && (
                <OpenAISidebar isSideBarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            )}

        </StyledNumberPage>
    );
};

const StyledNumberPage = styled.div<{ selectedSourceRadio: string }>`
   display: flex;
    flex-direction: row;
    width: 96%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    left: 22px;
    align-items: center;
    top: ${(props): string => props.selectedSourceRadio === 'All' ? '15px' : '60px'};
    padding: 5px;

     .button-container {
        flex-shrink: 0;
    }

    .chat-button {
        height: 44px;
        border: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background: #fff;
        border-radius: 6px;
        color: #1A1A24;
        font-family: EYInterstate;
        font-size: 16px;
        font-weight: 400;
        min-width: 212px;
    }

    .button: hover {
        background: #A4A3B1;
        cursor: pointer;
        color: #fff;
    }
`;

const SidebarOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    z-index: 999;
    transition: display 0.5s ease-in-out;
    overflow: auto;
`;

const Sidebar = styled.div<{ isOpen: boolean }>`
    width: 100%;
    max-width: 689px;
    height: 100%;
    background-color: #1A1A24;
    box-shadow: -2px 0 5px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.5s ease-in-out;
    overflow-y: auto;
    .close-button {
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
        transition: transform 0.5s ease-in-out;
    }
    
    .chat-header { 
        display: flex;
        justify-content: space-between;
        background: #2E2E38;
        border-bottom: 1px solid #5F5F72;
        padding: 12px;
        align-items: center;
        height: 8%;
    }

    .chat-label {
        font-size: 20px;
        font-weight: 500;
        color: #1A1A24;
        display: flex;
        align-items: center;
    }

    .label {
        color: #fff;
        padding-left: 20px;
        font-weight: 400px;
        font-size: 16px;
    }
`;

export default MarketReportNumber;