import { useDispatch, useSelector } from "react-redux";
import { downloadFilenameGenerator } from ".";
import { BACKEND_API_URL } from "../../../app.constant";
import apiRoutesChat  from "../../../services/api";
import {
  getCurrentSessionId,
 } from "../../../services/session.service";
import { IStore } from "../../../store";
import { RESPONSE_BY } from "../configs/type/types";

//@ts-ignore
export const exportToCSV = async (query: string | undefined, source: RESPONSE_BY.OPENAI | RESPONSE_BY.EMBROYNIC,userData, isEGPTOnly:boolean) => {
  if (query) {
    const sessionId = getCurrentSessionId();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userData?.rawIdToken || userData?.sid || ""}`
      },
      body: JSON.stringify({ query: query }),
    };

    const file = await fetch(`${BACKEND_API_URL}apigateway/api/v1/${apiRoutesChat.exportCSVChat}`, requestOptions)
    if (!file.ok) {
      throw new Error("Something went wrong")
    } else {

      const response = await file.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      const extension = response.type === "application/zip" ? "zip" : "csv";
      link.download = downloadFilenameGenerator(source, extension, isEGPTOnly);
      link.click();
    }
  }
}