import React from "react";
import "./LinkButton.scss";
import styled from "styled-components";

type LinkButtonProps = {
  onClick: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
};
const LinkButton: React.FC<LinkButtonProps> = ({
  onClick,
  children,
  disabled,
  className = "linkButton",
}) => {
  return (
    <StyledLinkButton>
    <button
      className={className}
      onClick={() => onClick()}
      disabled={disabled}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      {children}
    </button>
    </StyledLinkButton>
  );
};

const StyledLinkButton = styled.div`

`;


export default LinkButton;

