import { type IAppConfig } from '../models/IAppConfig';
import axios, {
  type AxiosResponse,
  type AxiosRequestConfig,
  type AxiosInstance,
  type CancelToken,
  type AxiosError
} from 'axios';
import { type IdTokenResponse } from 'react-aad-msal';
import { getCurrentSessionId } from './session.service';
import { authProvider } from './msalConfig';
import { Guid as guid } from 'guid-typescript';
import { X_TENANT_ID } from '../app.constant';
import qs from 'query-string';
import { store } from "../store";
import { setUSerEmail, showPrivacyAndTermsModal, showUnauthorizedAccess } from '../store/actions/commonActions';
import * as microsoftTeams from '@microsoft/teams-js';
import { jwtDecode } from 'jwt-decode';

let api: AxiosInstance;
let authTokenRequest: Promise<IdTokenResponse> | null;

export enum apiRoutes {
  userRoles = 'authorization/roles',
  profilePicture = 'user/photo',
  signOut = 'user/sign-out',
  acceptPrivacy = 'paans/acceptpolicy',
  getPrivacyPolicy = 'paans/policytype',
  authorizationApps = 'authorization/apps',
  errorLog = 'errorLog/Save',
  getAllReport = 'get_all_mr_reports',
}

export const getAuthToken = async (): Promise<IdTokenResponse> => {
  if (!authTokenRequest) {
    authTokenRequest = authProvider.getIdToken();
  }
  return await authTokenRequest;
};

export const getTeamsAuthToken = async (): Promise<object> => {
  return await new Promise<object>((resolve, reject) => {
    microsoftTeams.initialize();
    const authTokenRequest = {
      successCallback: (token: string) => {
        const userObject: any = jwtDecode(token);
        store.dispatch(setUSerEmail(userObject?.preferred_username));
        resolve({ token });
      },
      failureCallback: (error: string) => {
        console.error('Error from getAuthToken: ', error);
      },
      resources: []
    };
    microsoftTeams.authentication.getAuthToken(authTokenRequest);
  });
};

export const initApi = (appConfig: IAppConfig): void => {
  api = axios.create({
    baseURL: `${appConfig.baseApiUrl}ie/api/v1/`
  });

  const sessionId = getCurrentSessionId();

  api.interceptors.request.use(async (config: AxiosRequestConfig): Promise<any> => {
    // Check and acquire a token before the request is sent
      return await getAuthToken()
        .then((res: IdTokenResponse) => {
          if (res.idToken.rawIdToken && config?.headers) {
            store.dispatch(setUSerEmail(res.idToken.preferredName));
            config.headers.Authorization = `Bearer ${res.idToken.rawIdToken}`;
            return config;
          }
        })
        .then((config) => {
          if (config?.headers) {
            setRequestHeaders(config, sessionId);
          }
          return config;
        })
        .catch(() => {
          localStorage.clear();
          authProvider.loginRedirect();
        })
        .finally(() => {
          authTokenRequest = null;
        });
   
  });

  // intercept responses and look for errors
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      // if error status is 451 set store to hide indicators
      // and show privacy modal
      if (error?.response?.config?.url !== apiRoutes.userRoles && error.response?.status === 451) {
        //   store.dispatch(clearLoadingIndicators());
        if (error.response?.data?.showPolicy) {
          store.dispatch(showPrivacyAndTermsModal(true, error.response?.data?.policyTypeList));
        }
      } else if (error.response?.status === 403) {
        //   store.dispatch(clearLoadingIndicators());
          store.dispatch(showUnauthorizedAccess(true));
      } else if (error.response?.status === 401) {
        if (error?.response?.data?.Errors[0].ErrorCode === 5) {
          // Session idle time-out
          localStorage.clear();
          const currentUrl =
            window.location.pathname === 'error' ? window.location.origin : window.location.href;
          window.location.href =
            'https://login.microsoftonline.com/common/oauth2/logout?client_id=' +
            authProvider.getCurrentConfiguration().auth.clientId +
            '&post_logout_redirect_uri=' +
            encodeURIComponent(currentUrl);
        }
      }
      return await Promise.reject(error);
    }
  );
};

const getRequestConfig = (params = {}, config: AxiosRequestConfig = {}): AxiosRequestConfig => {
  const requestConfig = {
    params,
    ...config,
    paramsSerializer: (params: any): string => {
      return qs.stringify(params);
    }
  };

  return requestConfig;
};

const unwrapData = (r: AxiosResponse): any => {
  return r.data || {};
};
const unwrapResponse = (response: AxiosResponse): any => {
  return response || {};
};

export const get = async <T = any>(
  path: string,
  queryParams = {},
  config: AxiosRequestConfig = {},
  hasCustomCancellationHandler?: boolean
): Promise<T> => {
  // const requestConfig = getRequestConfig(queryParams, config);
  return await api
    .get(path, queryParams)
    .then(unwrapData)
    .catch(async (error: AxiosError) => {
      // handle cancellation token
      if (axios.isCancel(error) && !hasCustomCancellationHandler) {
        return null;
      }
      return await Promise.reject(error);
    });
};

export const post = async <T = any>(
  path: apiRoutes | any,
  data: any,
  responseType?: string,
  sessionId?: string,
  cancelToken?: CancelToken,
  hasCustomCancellationHandler = false,
): Promise<T> => {
  return await api
    .post(path, data, {
      cancelToken,
      responseType: responseType as AxiosRequestConfig['responseType'],
      headers: { 'Session-Id': sessionId}
    })
    .then(unwrapData)
    .catch(async (error: AxiosError) => {
      // handle cancellation token
      if (axios.isCancel(error) && !hasCustomCancellationHandler) {
        return null;
      }

      return await Promise.reject(error);
    });
};

export const deleteMethod = async <T = any>(
  path: string,
  queryParams = {},
  config: AxiosRequestConfig = {}
): Promise<T> => {
  const requestConfig = getRequestConfig(queryParams, config);
  return await api
    .delete(path, requestConfig)
    .then(unwrapData)
    .catch(async (error: AxiosError) => {
      return await Promise.reject(error);
    });
}

export const downloadDoc = async <T = any>(
  path: string,
  queryParams = {},
  config: AxiosRequestConfig = {},
  hasCustomCancellationHandler?: boolean
): Promise<T> => {
  return await api
    .get(path, {params:queryParams,headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    responseType: "blob"})
    .then(unwrapResponse)
    .catch(async (error: AxiosError) => {
      // handle cancellation token
      if (axios.isCancel(error) && !hasCustomCancellationHandler) {
        return null;
      }
      return await Promise.reject(error);
    });
};

export const getWithStatus = async <T = any>(
  path: string,
  queryParams = {},
  config: AxiosRequestConfig = {},
  hasCustomCancellationHandler?: boolean
): Promise<T> => {
  const requestConfig = getRequestConfig(queryParams, config);
  return await api
    .get(path, requestConfig)
    .then(unwrapResponse)
    .catch(async (error: AxiosError) => {
      // handle cancellation token
      if (axios.isCancel(error) && !hasCustomCancellationHandler) {
        return null;
      }
      return await Promise.reject(error);
    });
};


export const postWithStatus = async <T = any>(
  path: apiRoutes | any,
  data: any,
  sessionId?: string,
  cancelToken?: CancelToken,
  hasCustomCancellationHandler = false
): Promise<T> => {
  return await api
    .post(path, data, {
      cancelToken,
      headers: { 'Session-Id': sessionId }
    })
    .then(unwrapResponse)
    .catch(async (error: AxiosError) => {
      // handle cancellation token
      if (axios.isCancel(error) && !hasCustomCancellationHandler) {
        return null;
      }

      return await Promise.reject(error);
    });
};

const setRequestHeaders = (
  config: AxiosRequestConfig<any>,
  sessionId: string | null
) => {
  if (config?.headers) {
    config!.headers['Request-Id'] = guid.create();
    config!.headers['X-Correlation-Id'] = guid.create();
    config!.headers['X-Window-Id'] = sessionId;
    config!.headers['X-Tenant-Id'] = X_TENANT_ID;
  }
};
