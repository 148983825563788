import React, { useCallback } from "react";
import { useEffect, useRef, useState } from "react";
import SendMessage from "../../component/SendMessage";
import ChatReply from "../../component/ChatReply";
import InputBox from "../../component/InputBox";
import LinkButton from "../../component/LinkButton/LinkButton";
import DownloadIcon from "../../assets/download.svg";
import PageConfig from "../../configs/ui/en.json";
import {
  getCurrentSessionId,
  initializeSession,
} from "../../../../services/session.service";
import "./StrategyEdge.scss";
import { IStore } from "../../../../store";
import { HttpStatusCode } from "../../configs/base/enum";
import { IAccountInfo } from "react-aad-msal";
import {useDispatch, useSelector } from "react-redux";
import { Packer } from "docx";
import {
  clearChatHistory,
  clearDownloadFileMessage,
  setChatTable,
  setChatHistory,
  setChatSummary,
  setChunkLoading,
  setClearLoader,
  setDownloadFileMessage,
  setDownloadFileMessagePPT,
  setDownloadFileMessageWord,
  setWhoelChatTable,
  toggleLoader,
  setChatErrors,
  setSelectedChatThread,
  setChatSummaryData,
  setRelevantFiles,
} from "../../../../store/actions/openAiActions";
import { IQuery, RESPONSE_BY } from "../../configs/type/types";
import LABELS from "../../configs/ui/en.json";
import useExportToFile from "../../configs/hooks/useExportToFile";
import {
  clearChat,
  // saveDownloadChatActivity,
  // saveUserChatHistory,
} from "../../services/ChatService";
import apiRoutesChat from "../../../../services/api";
import { BACKEND_API_URL, downloadIcon } from "../../../../app.constant";
import {
  IsJSONResponse,
  getComputedRequestObject,
  getErrorLabel,
  handleSessionID,
  handleUserChatThreadHistory,
} from "../../utils";
import { getCurrentChatSessionId } from "../../services/session.services";
import errorStatus from "../../../../utils/ErrorStatus";
import { getChatResponse } from "../../../../services/chat.service";

type StrategyEdgeProps = {
  accountInfo?: IAccountInfo;
  isEGPTOnly: boolean;
  tryQueryText?: string;
};

const suggestedQueries: string[] = [];
let chunkLoader = true;

const StrategyEdge = ({
  accountInfo,
  isEGPTOnly,
  tryQueryText,
}: StrategyEdgeProps) => {
  const [seQuery, setSeQuery] = useState<IQuery[]>([]);
  const [chatBoxHeight, setChatBoxHeight] = useState(window.innerHeight / 1.4);
  const [minScrollHeight, setMinScrollHeight] = useState(310);
  const [queryText, setQueryText] = useState("");
  const refToScroll = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLTextAreaElement>(null);
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const {
    chatData,
    isClearLoading,
    chatDataSummmary,
    isLoading,
    isSideBarOpen,
    chunkLoading,
    selectedChatThread,
    chatSummmaryDataNew,
  } = useSelector((state: any) => state.openAiChat);
  const dispatch = useDispatch();
  const { exportToWord } = useExportToFile();
  const [chatLoadCompleted, setChatLoadCompleted] = useState(false);
  const [controller, setController] = useState<any>(null);
  const [
    isCompletelyAbortedResposne,
    setIsCompletelyAbortedResposne,
  ] = useState<boolean>(false);
  const [isParitallyAbortedResponse, setIsParitallyAbortedResponse] = useState<
    boolean
  >(false);
  const [firstResponseTime, setFirstResponseTime] = useState(0);
  const [totalResponseTime, setTotalResponseTime] = useState<number | null>();
  const selectedFileNames = useSelector((state: any) => state.common.selectedFileNames);
  const filtersData = useSelector((state: any) => state.common.filtersData);
  const selectedSourceRadio = useSelector((state: any) => state.common.selectedSourceRadio);

  const timeoutError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: LABELS.chat_reply.SE_TOKEN_ERROR_MSG,
        content: [],
        responseType: "error",
        sources: isEGPTOnly ? ["emb"] : ["emb", "openai"],
      },
    },
  ];



  useEffect(() => {
    setSeQuery(() => chatDataSummmary?.table);
  }, [chatDataSummmary, chunkLoading]);

  const handleOnChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQueryText(ev.target.value);
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      refToScroll?.current?.scroll({
        left: 0,
        top: refToScroll.current.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  useEffect(() => {
    initializeSession();
  }, []);

  useEffect(() => {
    refInput.current?.focus();
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [seQuery]);

  useEffect(() => {
    setSeQuery(() => chatDataSummmary?.table);
  }, [chatDataSummmary, chunkLoading]);

  const updateFirstResponseTime = (firstByteTime: any, startTime: number) => {
    if (firstByteTime === null) {
      firstByteTime = performance.now();
      setFirstResponseTime((firstByteTime - startTime) / 1000);
    }
  };

  const updateTotalResponseTime = (startTime: number, isStaticResponse: boolean = false) => {
    if (isStaticResponse) {
      setTotalResponseTime(null);
      return;
    } else {
      const endTime = performance.now(); // Record the end time
      const duration = (endTime - startTime) / 1000; // Calculate the duration
      setTotalResponseTime(duration); // Update state with the response time
    }
  };

  let replyServerError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: LABELS.chat_reply.OPENAI_ERROR_MSG,
        content: [],
        responseType: 'error',
        sources: ['Market Reports']
      }
    }
  ];
  const replyNotFoundError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: `\n\n\n${LABELS.chat_reply.OPENAI_NOT_FOUND_ERROR_MSG}`,
        content: [],
        responseType: 'error',
        sources: ['Market Reports']
      }
    }
  ];

  const sendChatQuery = async (summary: string) => {
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    scrollToBottom();
    let chunks = '';
    let restSummaryPart = '';
    const controller = new AbortController();
    setController(controller);
    let check = false;
    const startTime = performance.now();
    let firstByteTime: any = null;
    try {
      const summaryData = JSON.stringify({
        "query": summary,
          "mr_files": selectedFileNames.length > 0 ? selectedFileNames : [],
          "source": selectedSourceRadio,
          "sectors":filtersData?.checkedsector || [],
          "subsectors":filtersData?.checkedSubsector || [],
          "geographies":filtersData?.checkedgeography || [],
          "reporttype":filtersData?.checkedReportType || [],
          "content_ages": filtersData?.selectedContentAge || "",
      });

      const response: any = await getChatResponse(summaryData, controller, userData, );
      const { status, statusText } = response;
      if (controller.signal.aborted) {
        const stopGenerationErrorMsg = [
          {
            [RESPONSE_BY.STRATEGY_EDGE]: {
              summary: LABELS.chat_reply.STOP_GENERATION_MSG,
              content: [],
              responseType: 'stop_generation',
              sources: [],
              isCompletelyAbortedResposne: true,
              isParitallyAbortedResponse: false
            }
          }
        ];
        dispatch(setChatErrors([...stopGenerationErrorMsg]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        setChatLoadCompleted(true);
      } else if (
        status === HttpStatusCode.InternalServerError ||
        status === HttpStatusCode.TimedOut ||
        status === HttpStatusCode.BadRequest ||
        statusText === LABELS.SERVER_ERROR
      ) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(setChatErrors([...replyServerError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        setTimeout(() => {
          return scrollToBottom();
        }, 100);
        setChatLoadCompleted(true);
      } else if (status === HttpStatusCode.NotFound) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(setChatErrors([...replyNotFoundError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        setTimeout(() => {
          return scrollToBottom();
        }, 100);
        setChatLoadCompleted(true);
      } else {
        updateFirstResponseTime(firstByteTime, startTime);
        const reader = response.body.getReader();
        reader
          .read()
          .then(function pump({ done, value }: any) {
            const decodedChunks = new TextDecoder().decode(value);
            chunks += decodedChunks.replaceAll('\n\n', '\n');
            dispatch(setChunkLoading(true));
            if (!check) {
              dispatch(
                setChatTable([
                  {
                    SEResponse: {
                      Content: [],
                      Sources: ['Market Reports'],
                      downloadURL: ''
                    }
                  }
                ])
              );
              if (chunks) dispatch(setChatSummary(decodedChunks));
              check = true;
            } else {
              if (chunks && !chunks.includes('relevant_files:')) {
                dispatch(setChatSummary(decodedChunks));
                scrollToBottom();
              } else {
                restSummaryPart += decodedChunks;
              }
            }
            if (done) {
              const restChunks = restSummaryPart.split('relevant_files:');
              if (restChunks.length > 1) {
                dispatch(setChatSummary(restChunks[0]));
              }
              updateTotalResponseTime(startTime);
              setChatLoadCompleted(true);
              dispatch(setChunkLoading(false));
              dispatch(setWhoelChatTable(true));
              dispatch(toggleLoader(false));
              scrollToBottom();
              setRelevantTableData(chunks);
              chunks = '';
              return;
            }
            return reader.read().then(pump);
          })
          .catch((err: any) => {
            dispatch(setChunkLoading(false));
            setChatLoadCompleted(true);
            scrollToBottom();
            dispatch(toggleLoader(false));
            updateTotalResponseTime(startTime, true);
          });
      }
    } catch (error) {
      setChatLoadCompleted(true);
      dispatch(setChunkLoading(false));
      if (controller.signal.aborted) {
        dispatch(setChatErrors([...timeoutError]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        updateTotalResponseTime(startTime, true);
      } else {
        dispatch(setChatErrors([...replyServerError]));
        dispatch(toggleLoader(false));
        setChatLoadCompleted(true);
        scrollToBottom();
        updateTotalResponseTime(startTime, true);
      }
    }
  };
  
  const fileRegex = new RegExp('relevant_files:', 'i');
  const setRelevantTableData = (str: string) => {
    try {
      const splitwiseFiles = str.split(fileRegex);
      if (splitwiseFiles.length > 1) {
        const filesstring = splitwiseFiles[1].replaceAll('```', '');
        const result = JSON.parse(filesstring.replace(/\n/g, '').replace(/\t/g, ''));
        if (result.length) {
          const updatedRes = result.map((res: any) => {
            let pagelist = res.page_number.reduce((newPages: any, page: any) => {
              if (newPages.includes(page)) {
                return newPages;
              }
              return [...newPages, page];
            }, []);
            return { ...res, page_number: pagelist };
          });
          dispatch(setRelevantFiles(updatedRes));
        }
      }
    } catch (er: any) {
      return errorStatus(er?.response?.status);
    }
  };

  const handleSendMessage = (
    query?: string
  ) => {
    if (!isClearLoading && !isLoading) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        // setRegeneratedMessageId(0);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
      }
    }
  };
  
  const handleResetMessage = async () => {
    refToScroll?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });

    dispatch(setClearLoader(true));
    const sessionId = getCurrentSessionId();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "session-id": sessionId || "",
      },
    };

    try {
      await clearChat(sessionId);
      dispatch(clearChatHistory());
      setQueryText("");
      dispatch(setClearLoader(false));
      dispatch(setSelectedChatThread({}));
    } catch (error) {
      dispatch(setClearLoader(false));
    }
  };

  const getErrorMessageSummary = (status = 404, isEgptOnly: boolean) => {
    let errorMessage = getErrorLabel(status, isEgptOnly);
    const sources = isEgptOnly ? ["emb"] : ["emb", "openai"];
    replyServerError = [
      {
        [RESPONSE_BY.STRATEGY_EDGE]: {
          summary: errorMessage,
          content: [],
          responseType: "error",
          sources: sources,
        },
      },
    ];
    return replyServerError;
  };

  const saveChatConversation = async () => {
    if (chatDataSummmary?.table?.length === 0) return;
    let requestObject = getComputedRequestObject(
      selectedChatThread,
      isEGPTOnly,
      chatDataSummmary,
      firstResponseTime,
      totalResponseTime
    );
    if (requestObject?.response) {
      let res = IsJSONResponse(requestObject.response)
        ? JSON.parse(requestObject.response)
        : requestObject.response;
      requestObject.response = JSON.stringify(res);
    }
    // const response: any = await saveUserChatHistory(requestObject);
    // if (response && response?.threadId) {
    //   if (response.threadId !== selectedChatThread?.threadId) {
    //     // setThreadsUnSelected(currentUserChatThreads);
    //     handleUserChatThreadHistory(requestObject, response.threadId, dispatch);
    //   }
    // }
    setChatLoadCompleted(false);
  };

  useEffect(() => {
    if (chatLoadCompleted) saveChatConversation();
  }, [chatLoadCompleted]);


  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === "Enter") {
      ev.preventDefault();
      handleSendMessage();
    }
  };
  const handleDocDownload = async () => {
    dispatch(setDownloadFileMessageWord());
    await exportToWord(seQuery, userData.profileImage, isEGPTOnly);
    dispatch(clearDownloadFileMessage());
  };

  const handleWindowResize = useCallback(() => {
    const scrHeight = window.innerHeight;
    if (scrHeight <= 654 && scrHeight > 523 && isSideBarOpen) {
      setChatBoxHeight(window.innerHeight / 1.95);
      setMinScrollHeight(280);
    } else if (scrHeight < 654 && scrHeight > 523) {
      setChatBoxHeight(window.innerHeight / 1.75);
      setMinScrollHeight(240);
    } else if (scrHeight <= 523) {
      if (isSideBarOpen) {
        setChatBoxHeight(window.innerHeight / 2.3);
        setMinScrollHeight(150);
      } else {
        setChatBoxHeight(window.innerHeight / 1.9);
        setMinScrollHeight(150);
      }
    }
  }, [window.innerHeight, isSideBarOpen]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isSideBarOpen]);

  const handleScrollToBottom = () => {
    scrollToBottom();
  };

  const handleStopGeneration = () => {
    controller.abort();
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    if (chunkLoading) {
      setIsParitallyAbortedResponse(true);
      let lastChatData =
        chatDataSummmary?.table[chatDataSummmary?.table?.length - 1]
          ?.SEResponse || undefined;
      if (lastChatData) {
        if (
          !("isCompletelyAbortedResposne" in lastChatData) &&
          !("isParitallyAbortedResponse" in lastChatData)
        ) {
          lastChatData.isCompletelyAbortedResposne = false;
          lastChatData.isParitallyAbortedResponse = true;
        }
      }
      setChatLoadCompleted(true);
    } else {
      setIsCompletelyAbortedResposne(true);
    }
  };

  const getResponseFlag = (SEResponse: any, flag: string) => {
    if (flag in SEResponse) {
      return SEResponse[flag];
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (tryQueryText && tryQueryText.length <= 1000) {
      setQueryText(tryQueryText);
    }
    handleSendMessage(tryQueryText);
  }, [tryQueryText]);

  return (
    <>
      {seQuery?.length > 0 && (
        <div className="download-btn-box">
          <LinkButton
            onClick={() => handleDocDownload()}
            disabled={isLoading || isClearLoading}
            className="download-link-btn"
          >
            <img
              src={downloadIcon}
              className="download-button"
            />
            <span className="download-text">Download</span>
          </LinkButton>
        </div>
      )}
      {seQuery?.length > 0 && (
        <>
          <div className="suggested-queries" ref={refToScroll}>
            <div className="scrollable-area">
              {seQuery?.map((chat: IQuery, ind: number) => {
                const { user, SEResponse, LLMResponse } = chat;
                return (
                  <React.Fragment key={ind}>
                    {user && (
                      <SendMessage
                        key={ind}
                        message={user}
                        isNew={true}
                        userQueryWithResponse={chat}
                        loader={isLoading}
                        handleSendMessage={handleSendMessage}
                        isEGPTOnly={isEGPTOnly}
                        chatData={{ SEResponse }}
                      />
                    )}

                    {SEResponse && (
                      <ChatReply
                        chatData={{ SEResponse }}
                        key={ind.toString() + "__strctrd-se-chats"}
                        isNew={true}
                        isSE={
                          SEResponse &&
                          "Sources" in SEResponse &&
                          SEResponse.Sources?.includes("Embryonic")
                            ? true
                            : false
                        }
                        messageLoading={isLoading}
                        chunkLoading={chunkLoading}
                        scrollToBottom={handleScrollToBottom}
                        convStyleEmbryonic={isEGPTOnly}
                        isLastItem={ind === seQuery.length - 1}
                        isCompletelyAbortedResposne={getResponseFlag(
                          SEResponse,
                          "isCompletelyAbortedResposne"
                        )}
                        isParitallyAbortedResponse={getResponseFlag(
                          SEResponse,
                          "isParitallyAbortedResponse"
                        )}
                        handleSendMessage={handleSendMessage}
                      />
                    )}
                  </React.Fragment>
                );
              })}
              {isLoading && !chunkLoading && (
                <ChatReply
                  loader={isLoading}
                  isNew={true}
                  isSE={isEGPTOnly}
                  convStyleEmbryonic={isEGPTOnly}
                />
              )}
              <div className="stop-generation-container">
                {(isLoading || chunkLoading) && (
                  <button
                    onClick={handleStopGeneration}
                    className="stop-generation-button"
                  >
                    Stop Generating
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <InputBox
        selectedSegment={true}
        queryText={queryText}
        refInput={refInput}
        hasQueries={seQuery?.length > 0}
        handleOnChange={handleOnChange}
        handleResetMessage={handleResetMessage}
        handleSendMessage={handleSendMessage}
        handleKeyDown={handleKeyDown}
        disabled={isLoading}
      />
    </>
  );
};

export default StrategyEdge;
