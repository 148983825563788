import axios , {AxiosError}  from "axios";
import { apiRoutes, get, post } from "../../../services/api.service";
import apiRoutesChat from "../../../services/api";


export const exportCSVChat = (reqBody: any): Promise<any> => {
  return post(apiRoutesChat.exportCSVChat, reqBody)
  .then((response) => {
      return response;
    })
    .catch((error: AxiosError) => {
      const { response } = error;
      if (response?.status === 400 || response?.status === 404) {
        return response ; 
    }
      return error;
    });
};

export const clearChat = (reqBody: any): Promise<any> => {
    return post(apiRoutesChat.clearChat, "", reqBody)
    .then((response) => {
        return response;
      })
      .catch((error: AxiosError) => {
        const { response } = error;
        if (response?.status === 400 || response?.status === 404) {
            return response ; 
        }
        return error;
      });
  };

  // export const saveUserChatHistory = (reqBody: any): Promise<any> => {
  //   return post(apiRoutesChat.saveChatHistory, reqBody)
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch((error: AxiosError) => {
  //       const { response } = error;
  //       if (response?.status === 400 || response?.status === 404) {
  //         return {"threadId":1849,"messageId":2705};
  //       }
  //       return {"threadId":1849,"messageId":2705}
  //     });
  // };

// export const saveDownloadChatActivity = async (activityType: string): Promise<any> => {
//   try {
//     const data = await post(apiRoutesChat.saveDownloadChatActivity, {
//       activity: activityType,
//       activityinfo: ""
//     });
//     return data;
//   } catch (error: any) {
//     if (!error?.response?.status)
//       return error;
//   }
// };

export const downloadReport = (reqBody: any): Promise<any> => {
  return get(apiRoutesChat.getPdf+'/'+reqBody)
  .then((response) => {
      return response;
    })
    .catch((error: AxiosError) => {
      const { response } = error;
      if (response?.status === 400 || response?.status === 404) {
          return response ; 
      }
      return error;
    });
};

export const downloadMultipleFiles = (reqBody: any): Promise<any> => {
  return post(apiRoutesChat.getMultiplePdf, reqBody, 'blob')
  .then((response) => {
      return response;
    })
    .catch((error: AxiosError) => {
      const { response } = error;
      if (response?.status === 400 || response?.status === 404) {
          return response ; 
      }
      return error;
    });
};

export const downloadReportBlob = (reportBlob: any) => {
  const linkSource = `data:application/pdf;base64,${reportBlob.pdf_data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = reportBlob.pdf_filename;
  downloadLink.click();
}

export const downloadMultipleFilesBlob = (reportBlob: any) => {
  if (reportBlob instanceof Blob) {
    const url = URL.createObjectURL(reportBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Market Reports.zip';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } else {
    console.error('The provided data is not a Blob.');
  }
}