import React, { useEffect, useState } from 'react';
import './CustomDropdown.scss';
import Dropdown from './Dropdown';
import DropdownFooter from './DropdownFooter';
import { useSelector } from 'react-redux';
type ICustomDropdwnoProps = {
  setdropdownValue: (val: string) => void;
  toggleDropdown?: () => void;
  clearDropdownValues?: () => void;
  selectedContentAge?: string;
  options: {
    value: string | number;
    label: string;
  }[];
  placeholder?: string;
  showOptions: boolean;
  isSideBarOpen?: boolean;
  isLoading?: boolean;
  customRef?: any;
  isContentAgeWithRadio?: boolean;
  dropdownType?: string;
  mrFlag?: boolean;
};
const CustomDropdown = ({
  options = [],
  placeholder,
  showOptions,
  selectedContentAge = '',
  isSideBarOpen,
  isLoading,
  customRef,
  setdropdownValue,
  toggleDropdown,
  clearDropdownValues,
  isContentAgeWithRadio = false,
  dropdownType = 'radio',
  mrFlag = false
}: ICustomDropdwnoProps) => {
  const [selectedOption, setSelectedOption] = useState('');
  const selecteDropdownValue = (val: string) => {
    setdropdownValue(val);
    setSelectedOption(val);
    toggleDropdown?.();
  };

  const clearContentAgeDropdownValues = () => {
    setSelectedOption('');
    clearDropdownValues?.();
  };

  useEffect(() => {
    if (selectedContentAge === '') {
      setSelectedOption('');
    }
  }, [selectedContentAge]);

  return (
    <div className={`customdropdown ${mrFlag && `mrClass`}`} ref={customRef}>
      <Dropdown
        isLoading={isLoading}
        disabled={isLoading}
        options={[{ label: ' ', value: ' ' }]}
        onFocus={toggleDropdown}
        placeholder={placeholder}
        classCss={'no-class customdropdown_next'}
        showBackground={selectedContentAge !== '' ? true : false}
        isSideBarOpen={isSideBarOpen}
      />
      <div
        className={`customdropdown__options ${isSideBarOpen ? 'sidebar-open' : 'sidebar-close'} ${isContentAgeWithRadio ? 'contentagewithradio__options' : ''}`}>
        {options && showOptions && (
          <>
            {options.map((opt: any, ind: number) => {
              return isContentAgeWithRadio ? (
                <label className='contentagewithradio-dropdown' key={ind.toString() + '__options'}>
                  <input
                    className={`customdropdown__option-menu ${+selectedOption === ind && selectedContentAge !== '' ? 'bg-selected' : 'bg-unselected'}`}
                    type={dropdownType}
                    value={selectedOption}
                    checked={selectedOption === opt.value}
                    onChange={() => selecteDropdownValue(opt.value)}
                  />
                  {opt.label}
                </label>
              ) : (
                <option
                  key={ind.toString() + '__options'}
                  className={`customdropdown__option-menu ${+selectedOption === ind && selectedContentAge !== '' ? 'bg-selected' : 'bg-unselected'}`}
                  onClick={() => selecteDropdownValue(opt.value)}>
                  {opt.label}
                </option>
              );
            })}{' '}
            <DropdownFooter
              customClass={'customdropdown__footer'}
              closeFilter={toggleDropdown}
              clearAll={isContentAgeWithRadio ? clearContentAgeDropdownValues : clearDropdownValues}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
