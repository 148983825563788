import { IQuery } from "../../components/openai/configs/type/types";
import {
  CLEAR_CHAT_HISTORY,
  GET_CHAT_HISTORY,
  SET_CHAT_HISTORY,
  SET_DOWNLOAD_FILE_MESSAGE,
  SET_ERROR_MESSAGE,
  TOGGLE_GPT_SIDEBAR,
  TOGGLE_LOADER,
  TOOBLE_CLEAR_LOADER,
  SET_CHAT_SUMMARY,
  SET_CLEAR_SUMMARY,
  SET_CHAT_TABLE,
  SET_WHOLE_CHAT_TABLE,
  SET_CHUNK_DATA_LOADING,
  SET_CHAT_ERRORS,
  SET_SELECTED_CHAT_THREAD,
  SET_CHATDATA_SUMMARY,
  SET_RELEVANT_FILES,
} from "../actionType";
import { AnyAction } from "redux";

export type State = {
  chatDataSummmary_11: any;
  chatData: IQuery[];
  isSideBarOpen: boolean;
  isLoading: boolean;
  isClearLoading: boolean;
  downloadFileMessage?: string;
  errorMessage?: string;
  chatDataSummmary: any;
  chunkLoading: boolean;
  selectedChatThread: any;
  chatSummmaryDataNew: any;
};

export const initialStateOpenAiChat: State = {
  chatData: [],
  isSideBarOpen: false,
  isLoading: false,
  isClearLoading: false,
  downloadFileMessage: "",
  errorMessage: "",
  chatDataSummmary: {
    table: [],
  },
  chatDataSummmary_11: {
    table: [],
  },
  chunkLoading: false,
  selectedChatThread: {},
  chatSummmaryDataNew: {},
};

export const openaiReducer = (
  state = initialStateOpenAiChat,
  action: AnyAction
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CHAT_HISTORY: {
      return {
        ...state,
      };
    }
    case SET_CHAT_ERRORS: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable = { ...state.chatDataSummmary };
      existingTable.table[tableLength - 1] = Object.assign(
        {},
        ...state.chatDataSummmary.table,
        { ...payload[0] },
        { ...payload[1] }
      );

      return {
        ...state,
        chatDataSummmary: { ...existingTable },
      };
    }

    case SET_CHAT_HISTORY: {
      return {
        ...state,
        chatDataSummmary: {
          table: [...state.chatDataSummmary.table, { ...payload[0] }],
        },
      };
    }

    case SET_CHAT_TABLE: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable = { ...state.chatDataSummmary };
      existingTable.table[tableLength - 1] = Object.assign(
        {},
        ...state.chatDataSummmary.table,
        { ...payload[0] },
        { ...payload[1] }
      );

      return {
        ...state,
        chatDataSummmary: { ...existingTable },
      };
    }

    case SET_CHAT_SUMMARY: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable = { ...state.chatDataSummmary };

      if (tableLength > 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            summary: existingTable.table[tableLength - 1].SEResponse.summary
              ? existingTable.table[tableLength - 1].SEResponse.summary.concat(
                  payload
                )
              : payload,
          }
        );
      }
      return {
        ...state,
        chatDataSummmary: { ...existingTable },
      };
    }

    case SET_WHOLE_CHAT_TABLE: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable = { ...state.chatDataSummmary };

      if (tableLength >= 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            showTable: payload,
          }
        );
        existingTable.table[tableLength - 1].LLMResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].LLMResponse,
          {
            showSummary: payload,
          }
        );
      }
      return {
        ...state,
        chatDataSummmary: { ...existingTable },
      };
    }
    case SET_CLEAR_SUMMARY: {
      return {
        ...state,
        chatDataSummmary: "",
      };
    }
    case CLEAR_CHAT_HISTORY: {
      return {
        ...state,
        chatDataSummmary: {
          table: [],
        },
      };
    }

    case TOGGLE_GPT_SIDEBAR: {
      return {
        ...state,
        isSideBarOpen: payload,
      };
    }
    case TOGGLE_LOADER: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case TOOBLE_CLEAR_LOADER: {
      return {
        ...state,
        isClearLoading: payload,
      };
    }
    case SET_DOWNLOAD_FILE_MESSAGE: {
      return {
        ...state,
        downloadFileMessage: payload,
      };
    }

    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: payload,
      };
    }
    case SET_CHUNK_DATA_LOADING: {
      return {
        ...state,
        chunkLoading: payload,
      };
    }
    case SET_SELECTED_CHAT_THREAD: {
      return {
        ...state,
        selectedChatThread: action.thread
      };
    }

    case SET_CHATDATA_SUMMARY: {
      return {
        ...state,
        chatSummmaryDataNew: action.payload,
      };
    }

    case SET_RELEVANT_FILES: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable: any = { ...state.chatDataSummmary };
      if (tableLength > 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            relevantFiles: payload.map((c: any) => {
              return { ...c, authors: c.authors };
            })
          }
        );
      }

      return {
        ...state,
        chatDataSummmary: { ...existingTable }
      };
    }
    
    default: {
      return state;
    }
  }
};
