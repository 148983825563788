import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MessageEditMode from "../MessageEditMode/MessageEditMode";
import OptionsButton from "../OptionsButton/OptionsButton";
import { IQuery, IQueryContent, RESPONSE_BY } from "../../configs/type/types";
import USER_IMG from "../../assets/person.svg";
import { IStore } from "../../../../store";
import LABELS from "../../configs/ui/en.json";
import useExportToFile from "../../configs/hooks/useExportToFile";
import { exportToPpt } from "../../utils/exportToPPT";
import {
  clearDownloadFileMessage,
  setDownloadFileMessage,
  setDownloadFileMessageExcel,
  setDownloadFileMessagePPT,
  setDownloadFileMessageWord,
} from "../../../../store/actions/openAiActions";

type SendMessageProps = {
  message: IQueryContent;
  isNew?: boolean;
  loader: boolean;
  userQueryWithResponse?: any;
  handleSendMessage: (ev: any) => void;
  isEGPTOnly?: boolean;
  chatData?: IQuery;
};

const SendMessage = ({
  message,
  isNew,
  loader,
  userQueryWithResponse,
  handleSendMessage,
  isEGPTOnly,
  chatData

}: SendMessageProps) => {
  const dispatch = useDispatch();
  const { exportToCSV, exportToPpt } = useExportToFile();
  const [showOptions, setShowOptions] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const isSideBarOpen = useSelector(
    (state: any) => state.openAiChat.isSideBarOpen
  );
  const [replyData, setReplyData] = React.useState<IQueryContent | null>();

  
  React.useEffect(() => {
    let data: IQueryContent | null = chatData
      ? chatData[RESPONSE_BY.STRATEGY_EDGE] || chatData[RESPONSE_BY.OPENAI]
      : null;
    setReplyData(data);
  }, [chatData]);

  const queryImageClass = isNew
    ? "openaiquery__img-container"
    : "openaiquery__img-container-dark";

  // const handlePPTDownload = async () => {
  //   dispatch(setDownloadFileMessagePPT());
  //   await exportToPPT(isEGPTOnly?RESPONSE_BY.EMBROYNIC :RESPONSE_BY.OPENAI, userQueryWithResponse,isEGPTOnly);
  //   dispatch(clearDownloadFileMessage());
  // };

  const handlePPTDownload = async () => {
    // setShowUploadInitiated({ action: 'ppt', flag: true });
    await exportToPpt(
      userQueryWithResponse,
    );
    // setTimeout(() => {
    //   setShowUploadInitiated({ action: '', flag: false });
    // }, 1000);
  };

  return (
    <div
      className={!isSideBarOpen ? "openaiquery" : "openaiquery sidebar-open"}
    >
      <div className={queryImageClass}>
        {userData.profileImage ? (
          <img
            className="openaiquery__image-round"
            alt="Profile"
            src={userData.profileImage}
          />
        ) : (
          <USER_IMG />
        )}
      </div>
      {isEditMode ? (
        <MessageEditMode
          message={message.summary}
          handleSendMessage={handleSendMessage}
          setIsEditMode={setIsEditMode}
        />
      ) : (
        <div
          className={ userData.profileImage ?  "openaiquery__query-box" : 'openaiquery__query-box_without_profile'}
          onMouseOver={() => {
            setShowOptions(true);
          }}
          onMouseLeave={() => {
            setShowOptions(false);
          }}
        >
          {message.summary}
          {showOptions && !loader && (
            <OptionsButton
              options={[
                {
                  label: LABELS.send_message.EXPORT_PPT_BTN,
                  onClick: handlePPTDownload,
                  display: true,
                },
                ...(replyData?.sqlQuery
                  ? [
                      {
                        label: LABELS.chat_reply.EXPORT_EXCEL_BTN,
                        onClick: async () => {
                          dispatch(setDownloadFileMessageExcel());
                          await exportToCSV(
                            replyData?.sqlQuery,
                            isEGPTOnly
                              ? RESPONSE_BY.EMBROYNIC
                              : RESPONSE_BY.OPENAI, isEGPTOnly ?? false
                          );
                          dispatch(clearDownloadFileMessage());
                        },
                        display: chatData?.[RESPONSE_BY.STRATEGY_EDGE] ? true : false
                      }
                    ]
                  : []),

                {
                  label: LABELS.send_message.EDIT_BTN,
                  onClick: () => setIsEditMode(true),
                  display: true,
                },
               
              ]}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default SendMessage;
