import { AxiosError, HttpStatusCode } from "axios";
import { getAuthorizationToken, routeToLogin } from "../utils/commonUtils";
import { getCurrentSessionId } from "./session.service";
import errorStatus from "../utils/ErrorStatus";
import apiRoutesChat from "./api";
import { apiRoutes, get, post } from "./api.service";

type IUserData = {
  displayName: string;
  email: string;
  isExternal: boolean;
  profileImage: string;
  sid: { preferredName: string; rawIdToken?: string };
  rawIdToken?: string;
};

export const handleFetchException = (error: any) => {
  if (error?.response?.status === HttpStatusCode.Unauthorized) {
    routeToLogin();
  } else return errorStatus(error?.response?.status);
};

export const getChatResponse = async (summaryData: string, controller: AbortController, userData?: IUserData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Session-Id': getCurrentSessionId() || '',
      'jwt-token': userData?.sid.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
    },
    body: summaryData,
    signal: controller && controller.signal ? controller.signal : null
  };
  return fetch(apiRoutesChat.qnaApi, { ...requestOptions })
    .then((response) => {
      if (response.status === HttpStatusCode.Unauthorized) {
        routeToLogin();
      } else return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const fetchAllReport = async (filters?: any) => {
  const request = {
    sector: filters?.checkedsector || [],
    content_age: filters?.selectedContentAge ? Number(filters.selectedContentAge) : null,
    report_type: filters?.checkedReportType || []
  }
  return post(apiRoutesChat.getAllReport, request)
    .then((data) => {
      return data;
    }).catch((error: AxiosError) => {
      return { response: [] };
    });
};

export const getMRSectorsHierarchy = async () => {
  return get(apiRoutesChat.marketREportsSectorGeography)
    .then((data) => {
      return data;
    }).catch((error: AxiosError) => {
      return { response: {} };
    });
};