import React, { Fragment, useEffect, useState } from "react";
import LoadingIndicator from "../LoadingIndicator";
import {
  IContent,
  IQuery,
  IQueryContent,
  RESPONSE_BY,
  RESPONSE_TYPE,
} from "../../configs/type/types";
import SE_IMG from "../../assets/strategyedge.svg";
import OPENAI_IMG from "../../assets/openai.svg";
import OptionsButton from "../OptionsButton/OptionsButton";
import { convertNumber } from "../../utils/numberConverter";
import { useDispatch, useSelector } from "react-redux";
import { sanitizeHeaderText } from "../../utils";
import LABELS from "../../configs/ui/en.json";
import {
  clearDownloadFileMessage,
  setDownloadFileMessageExcel,
  setDownloadFileMessagePPT,
} from "../../../../store/actions/openAiActions";
import useExportToFile from "../../configs/hooks/useExportToFile";
import PageConfig from "../../configs/ui/en.json";
import Lodingchunks from "../../assets/Bars-1s-200px.svg";
import "./ChatReply.scss";
import { COPY_ICON, COPY_SUCCESS_ICON, GENERATE_APP_URL, openAIIconRobot } from "../../../../app.constant";
import { convertArrayToPlainTextTable } from "../../../../utils/commonUtils";
import DynamicTextRenderer from "./ChatSteamResponseFormatting";
import TableMarketReport from "./TableMarketReport";

type GetChatResponseProps = {
  chatData?: IQuery;
  loader?: boolean;
  isNew?: boolean;
  content?: IContent[];
  isSE: boolean;
  source?: string;
  messageLoading?: boolean;
  chunkLoading?: boolean;
  userQueryWithResponse?: any;
  scrollToBottom?: () => void;
  convStyleEmbryonic?: boolean;
  isLastItem?:boolean
  isCompletelyAbortedResposne?: boolean; 
  isParitallyAbortedResponse?: boolean;
  handleSendMessage?:any
};

type OptionsButtonProps = {
  options: { onClick: any; label: string }[];
};

export type ParsedTableData = { headers: string[]; values: string[][] };

export const errorMessage = {
  SEError:
    "Oops! This data is not covered by Embryonic. Please refer to secondary research for more information on this.",
  OpenAIError: "Oops! No results found. Please try and refine your question.",
};

export const getSourcesList = (replySources: Array<any>, isEGPTOnly: boolean) => {
  const result = isEGPTOnly ? [] : replySources.slice(1);
  const uniqueSrc = [ ...result];
  return uniqueSrc.filter((string) => Boolean(string));
};
export const prepareContentForTable = (content: IContent[]) => {
  const tableData: ParsedTableData = { headers: [], values: [] };
  content.forEach((el) => {
    if (el && typeof el === "object") {
      let valuesSegment: any[] = [];
      Object.entries(el).forEach(([key, value]) => {
        if (!tableData.headers.includes(key)) {
          tableData.headers.push(key);
        }
        if (
          key.toLowerCase().includes("year") ||
          key.toLowerCase().includes("id") ||
          key.toLowerCase().includes("quarter") ||
          key.toLowerCase().includes("count") ||
          key.toLowerCase().includes("date") ||
          key.toLowerCase().includes("Code") ||
          key.toLocaleLowerCase().includes("portfolio")
        ) {
          valuesSegment.push({ val: value });
        } else {
          valuesSegment.push(convertNumber(value, 1));
        }
      });
      tableData.values.push(valuesSegment);
    }
  });
  return tableData;
};

const ContentTable = ({
  tableContent,
  tableLength,
}: {
  tableContent?: ParsedTableData;
  tableLength?: number;
}) => {
  return (
    <div className="table-wrapper">
      <table className="content-table">
        <tbody>
          <tr>
            {tableContent?.headers.map((header: string) => (
              <th key={`table-header-${header}`}>
                {sanitizeHeaderText(header)}
              </th>
            ))}
          </tr>
          {tableContent?.values.map((valArr: string[], i) => (
            <tr key={`table-row-${i}`}>
              {valArr.map((val: any, i) => (
                <td key={`table-row-value-${i}`}>
                  <span title={val.title ? val.title : null}>{val.val}</span>
                </td>
              ))}
            </tr>
          ))}
       
        </tbody>
      </table>
    </div>
  );
};

const GetChatResponse = ({
  chatData,
  loader,
  isNew,
  isSE,
  messageLoading,
  chunkLoading = false,
  userQueryWithResponse,
  scrollToBottom,
  convStyleEmbryonic,
  isLastItem,
  isCompletelyAbortedResposne = false,
  isParitallyAbortedResponse = false,
  handleSendMessage
}: GetChatResponseProps) => {
  const dispatch = useDispatch();
  const { exportToCSV, exportToPpt } = useExportToFile();
  const [collapseHiddenLinks, setCollapseHiddenLinks] = React.useState(true);
  const [replyData, setReplyData] = React.useState<IQueryContent | null>();
  const [tableContent, setTableContent] = React.useState<ParsedTableData>();
  const [visibleLinks, setVisibleLinks] = React.useState<string[]>([]);
  const [hiddenLinks, setHiddenLinks] = React.useState<string[]>([]);
  const [showOptions, setShowOptions] = React.useState(false);
  const [tokenSqlErrorMessage, setTokenSqlErrorMessage] = React.useState("");
  const [hasSourceLink, setHasSourceLink] = React.useState(true);
  const [showCopyText, setShowCopyText] = useState(false);
  const [showCopyFailure, setShowCopyFailure] = useState(false);
  const isSideBarOpen = useSelector(
    (state: any) => state.openAiChat.isSideBarOpen
  );

  const chatbotImageClass = isNew
    ? "chatreply__img-container"
    : "chatreply__img-container-dark";

  React.useEffect(() => { //@ts-ignore
    let data: IQueryContent | null = chatData
      ? chatData[RESPONSE_BY.STRATEGY_EDGE] || chatData[RESPONSE_BY.OPENAI]
      : null;
    if(data && isLastItem)
      data.isChunkLoading = chunkLoading;    
    setReplyData(data);
  }, [chatData]);

  React.useEffect(() => {
    if(replyData && isLastItem)
      replyData.isChunkLoading = chunkLoading;
    if (replyData?.Content) {
      if (replyData?.Content.length > 10) {
        setTableContent(
          prepareContentForTable(replyData?.Content.slice(0, 10))
        );
      } else {
        setTableContent(prepareContentForTable(replyData?.Content));
      }
    }

    if (
      replyData &&
      !convStyleEmbryonic 
    ) {
      let sources = getSourcesList(replyData.Sources?? [],convStyleEmbryonic??false);
      sources = sources.slice(1);
      if (sources.length > 0) {
        setVisibleLinks(sources.slice(0, 1));
        setHiddenLinks(sources.slice(1));
        sources?.map((links) => {
          if (links.includes("://") || links.includes("www.")) {
            setHasSourceLink(true);
          } else {
            setHasSourceLink(false);
          }
        });
      }
    }
  }, [replyData]);

  const getInfoSources = React.useCallback(
    (replyData: any, replySources: any) => {
      return getSourcesList(replyData, replySources);
    },
    [replyData?.summary]
  );

  const formatLink = (link: string) => {
    const sourceName = link?.split("-")[0];
    return sourceName
      ? sourceName.replaceAll("[", "").replaceAll("]", "").replace("-", "")
      : link.replaceAll("[", "").replaceAll("]", "").replace("-", "");
  };

  useEffect(() => {
    if (
      (replyData?.summary && replyData?.summary?.includes(LABELS.TOKEN_ERR)) ||
      replyData?.summary?.includes("SQL_ERROR") ||
      replyData?.summary?.includes("https://aka.ms/oai/quotaincrease")
    ) {
      setTokenSqlErrorMessage(LABELS.chat_reply.SE_TOKEN_ERROR_MSG);
    } else {
      setTokenSqlErrorMessage("");
    }
  }, [replyData?.summary]);

  useEffect(() => {
    scrollToBottom?.();
  }, [replyData?.showTable]);

  const convertReleventFilesToArray = () => {
    const relevantFiles = replyData?.relevantFiles;
    const tableArray: any = {
      headers:[sanitizeHeaderText('Document Title'), sanitizeHeaderText('Page no.s'), sanitizeHeaderText('Source')],
      values: [],
    };
    relevantFiles?.map((file: any) => {
      let referenceDocumentsContentArray: any = [];
      let pageNo = '';
      referenceDocumentsContentArray.push({ val: file.file_name });
        file.page_number.length &&
          file.page_number.forEach((numer: any, i: number) => {
            pageNo = pageNo + numer + (i === file.page_number.length - 1 ? '' : ', ');
          });
        referenceDocumentsContentArray.push({ val: pageNo });
        referenceDocumentsContentArray.push({ val: file?.source });
      tableArray.values.push(referenceDocumentsContentArray);
    });
    return tableArray;
  };

  const handleCopy = async () => {
    try {
      const summaryText = replyData?.summary || "";

      const tableDataText =
        replyData?.relevantFiles && replyData?.relevantFiles?.length > 0
          ? convertArrayToPlainTextTable(tableContent)
          : "";

      const relevantFilesTableContent = convertReleventFilesToArray();

      const relevantFilesTableDataText =
        replyData?.relevantFiles && replyData?.relevantFiles?.length > 0
          ? convertArrayToPlainTextTable(relevantFilesTableContent)
          : "";

      let textToCopy = "";
      if (summaryText.length > 0) {
        textToCopy += summaryText;
      }

      if (summaryText.length > 0 && tableDataText.length > 0) {
        textToCopy += "\n";
      }

      if (relevantFilesTableDataText.length > 0) {
        textToCopy += '\nMarket Reports : \n' + relevantFilesTableDataText;
      }

      // if (tableDataText.length > 0) {
      //   textToCopy += tableDataText;
      // }
      // Use the Clipboard API to copy the text
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setShowCopyText(true);
        })
        .catch((err) => {
          setShowCopyText(false);
          setShowCopyFailure(true);
        });
    } catch (err) {
      setShowCopyFailure(true);
    }
  };
  const handleCopyWrapper = (): void => {
    handleCopy().catch((error) => {
      setShowCopyFailure(true);
    });
  };

  useEffect(() => {
    if (showCopyText) {
      setTimeout(() => {
        setShowCopyText(false);
      }, 3000);
    }
    if (showCopyFailure) {
      setTimeout(() => {
        setShowCopyFailure(false);
      }, 3000);
    }
  }, [showCopyFailure, showCopyText]);

  const renderCopyButton = () => {
    return (
      <Fragment>
        <div className="chat-reply-copy-response-icon" onClick={()=> handleCopyWrapper()} title={showCopyFailure? "Failed to copy response to clipboard" : showCopyText ? "Copied to clipboard" : "Copy response"}>
          <div
            className={(!showCopyText || showCopyFailure) ?"copy-button":"copy-success-button"}
          >
            {(!showCopyText || showCopyFailure) &&<> <img
              className="copy-icon-img"
              src={COPY_ICON}
              alt="copy icon"
            /> <span className="copy-text">Copy</span></>}
           
            {showCopyText && <><img
              className="copy-icon-img"
              src={COPY_SUCCESS_ICON}
              alt="copied icon"
            /><span className="copy-text">Copied</span></>}
          </div>          
        </div>
      </Fragment>
    );
  };

  return (
    <>
      <div className={!isSideBarOpen ? "chatreply" : "chatreply sidebar-open"}>
        <div className="chatreply-table-seperate">
        <div className={chatbotImageClass}>
          {!loader &&
            (convStyleEmbryonic ? (
              <img src={openAIIconRobot} />
            ) : (
              <img src={openAIIconRobot} />
            ))}
          {loader &&
            (convStyleEmbryonic ? (
              <img src={openAIIconRobot} />
            ) : (
              <img src={openAIIconRobot} />
            ))}
        </div>
        {// replyData?.summary && (
          !loader && (
            <div
              className={
                !isSideBarOpen
                  ? "chatreply__resp-box"
                  : "chatreply__resp-box sidebar-open"
              }
            >
              <div
                className={"chatreply__resp-box__resp"}
                onMouseOver={() => {
                  setShowOptions(true);
                }}
                onMouseLeave={() => {
                  setShowOptions(false);
                }}
              >

                {/* {showOptions &&
              replyData &&
              !messageLoading &&
              replyData?.ResponseType !== RESPONSE_TYPE.ERROR && (
                <OptionsButton
                  key={Math.random()}
                  options={[
                    ...(replyData?.sqlQuery
                      ? [
                          {
                            label: LABELS.chat_reply.EXPORT_EXCEL_BTN,
                            onClick: async () => {
                              dispatch(setDownloadFileMessageExcel());
                              await exportToCSV(
                                replyData?.sqlQuery,
                                convStyleEmbryonic
                                  ? RESPONSE_BY.EMBROYNIC
                                  : RESPONSE_BY.OPENAI,
                                convStyleEmbryonic
                              );
                              dispatch(clearDownloadFileMessage());
                            },
                            display: chatData?.[RESPONSE_BY.STRATEGY_EDGE]
                              ? true
                              : false,
                          },
                        ]
                      : []),
                  ]}
                />
              )
              } */}
                <div>
                  <span className={isCompletelyAbortedResposne ? 'stop-generation-label' : ''}>

                    <DynamicTextRenderer text={replyData?.ResponseType !== RESPONSE_TYPE.ERROR &&
                      replyData?.summary
                      ? chatData?.[RESPONSE_BY.OPENAI] &&
                        replyData.summary.startsWith(PageConfig.SORRY_ERR) &&
                        !replyData.summary.startsWith(PageConfig.SORRY_ERR1)
                        ? PageConfig.LLM_Error
                        : chatData?.[RESPONSE_BY.STRATEGY_EDGE] &&
                          replyData.summary.startsWith(PageConfig.SORRY_ERR) &&
                          !replyData.summary.startsWith(PageConfig.SORRY_ERR1)
                          ? PageConfig.chat_reply.EMBROYNIC_ERROR
                          : replyData.summary.trim()
                      : ""}
                    />

                    {isParitallyAbortedResponse &&
                      <div className='partial-stop-generation-label'>
                        <span className='stop-generation-label'>
                          {LABELS.chat_reply.PARTIAL_STOP_GENERATION_MSG}
                        </span>
                      </div>}
                    {chunkLoading && chatData?.[RESPONSE_BY.STRATEGY_EDGE] && isLastItem && (
                      <span>
                        <LoadingIndicator
                          color="#FFF"
                          parentWidth="8px"
                          zeroPercentheight="28px"
                          fifltyPercentheight="10px"
                          indicatorHeight={"19px"}
                        />
                      </span>
                    )}
                    {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                      chatData?.[RESPONSE_BY.STRATEGY_EDGE] &&
                      !tokenSqlErrorMessage &&
                      PageConfig.chat_reply.EMBROYNIC_ERROR}
                    {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                      chatData?.[RESPONSE_BY.STRATEGY_EDGE] &&
                      tokenSqlErrorMessage.length > 0 &&
                      tokenSqlErrorMessage}
                    {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                      chatData?.[RESPONSE_BY.OPENAI] &&
                      LABELS.chat_reply.OPENAI_ERROR_MSG}
                  </span>
                </div>
                  <span className={"left-child"}>
                    Source: Market Reports
                  </span>
                  {replyData?.showTable &&
                    (replyData?.relevantFiles?.length ?? 0) > 0 && (
                      <TableMarketReport tableContent={replyData?.relevantFiles || []} />
                    )}
                {/* @ts-ignore  */}
                {(replyData?.ResponseType === RESPONSE_TYPE.ERROR || replyData?.['responseType'] === RESPONSE_TYPE.ERROR) && (
                  <div>
                    <span className="left-child"></span>
                    <span className="right-child">{renderCopyButton()}</span>
                  </div>
                )}
              </div>
              {!chunkLoading && replyData?.ResponseType !== RESPONSE_TYPE.ERROR && replyData?.ResponseType !== undefined && (
                <div className="download-disclaimer-div">
                  <div className="disclaimer">
                    {`** To download the entire information, click on `}
                    <span
                      style={{ fontWeight: "700", cursor: "pointer" }}
                      onClick={async () => {
                        dispatch(setDownloadFileMessageExcel());
                        await exportToCSV(
                          replyData?.sqlQuery,
                          convStyleEmbryonic
                            ? RESPONSE_BY.EMBROYNIC
                            : RESPONSE_BY.OPENAI, convStyleEmbryonic ?? false
                        );
                        dispatch(clearDownloadFileMessage());
                      }}
                    >{`Export to excel.`}</span>
                  </div>
                </div>
              )}
              {replyData?.Sources && (
                <div
                  className={
                    isSideBarOpen
                      ? "chatreply__resp-box__footer slider"
                      : "chatreply__resp-box__footer"
                  }
                >
                  <div className="chatreply__resp-box__footer__source-box">
                    <span className="right-child">
                      {!replyData.isChunkLoading && renderCopyButton()}
                    </span>
                  </div>
                  {visibleLinks?.length > 0 && (
                    <div
                      className={
                        isSideBarOpen
                          ? hasSourceLink
                            ? "chatreply__resp-box__footer__links-box slider"
                            : "chatreply__no-links-gap"
                          : hasSourceLink
                            ? "chatreply__resp-box__footer__links-box"
                            : "chatreply__no-links-gap"
                      }
                    >
                      {(collapseHiddenLinks
                        ? visibleLinks
                        : [...visibleLinks, ...hiddenLinks]
                      ).map((link: string, i) => {
                        const linkHref = link.includes("://")
                          ? link.split("://")[1]
                          : link.split("www.")[1];
                        return linkHref ? (
                          <a
                            className="chatreply__resp-box__footer__links-box__source-link"
                            target="_blank"
                            key={`sources-link-${i}`}
                            href={`http://${linkHref
                              .replaceAll("[", "")
                              .replaceAll("]", "")}`}
                          >
                            {formatLink(link)}
                          </a>
                        ) : (
                          <span
                            className={
                              hasSourceLink
                                ? "chatreply__resp-box__footer__links-box__source-link"
                                : "chatreply__no-links"
                            }
                            key={`sources-link-${i}`}
                          >
                            {formatLink(link)}
                          </span>
                        );
                      })}
                      {collapseHiddenLinks &&
                        hiddenLinks &&
                        hiddenLinks?.length > 0 && (
                          <a
                            href={""}
                            onClick={(e) => {
                              e.preventDefault();
                              setCollapseHiddenLinks(false);
                            }}
                            className={
                              hasSourceLink
                                ? "chatreply__resp-box__footer__links-box__source-link"
                                : "chatreply__no-links"
                            }
                          >
                            + {hiddenLinks?.length} more
                          </a>
                        )}
                    </div>
                  )}
                </div>
              )}

            </div>
          )}
          </div>
          
      {loader && !chunkLoading && (
        <div className={"chatreply__loader-container"}>
          <LoadingIndicator
            color="#FFF"
            parentWidth="13px"
            zeroPercentheight="38px"
            fifltyPercentheight="16px"
          />
        </div>
      )}
    </div>

    {isLastItem &&
        messageLoading === false &&
        (replyData?.suggestions?.followup.length > 0 ||
          replyData?.suggestions?.download.length > 0) && (
          <div className='chatreply__suggestions_container'>
          <div className='chip-container'> 
              {replyData?.suggestions?.followup.length > 0 &&
                replyData?.suggestions?.followup.map(
                  (question: any, index: number) =>
                    question &&
                    question.length > 0 && (
                      <div className='chip'  onClick={() => handleSendMessage(null, question)}>
                        {question}
                      </div> 
                    )
                )}

                {/* {replyData?.suggestions?.portfolio?.length > 0 &&
                                replyData?.suggestions?.portfolio.map(
                                  (company: any, index: number) =>
                                    company &&
                                  company.length > 0 && (
                                      <div className='chip'  onClick={() => {
                                          const queryObject = { companyName: encodeURIComponent(company) };
                                          const queryValue = JSON.stringify(queryObject);

                                          const companyProfileUrl =  GENERATE_APP_URL + `company-profile?q=${queryValue}`;

                                          window.open(companyProfileUrl, '_blank');
                                        }}>
                                        {COMPANYPROFILE_DOWNLOAD_SUGGESTION_QUESTION}
                                      </div> 
                                    )
                                )}

              
                {replyData?.suggestions?.sector?.length > 0 &&
                                replyData?.suggestions?.sector.map(
                                  (Sector: any, index: number) =>
                                    Sector &&
                                  Sector.length > 0 && (
                                      <div className='chip'  onClick={() => {

                                          const sectorTrendUrl = GENERATE_APP_URL + 'sector-trend'

                                          window.open(sectorTrendUrl, '_blank');
                                        }}>
                                        {SECTORTRENDDOWNLOADSUGGESTION}
                                      </div>
                                    )
                                )} */}

              {replyData?.sqlQuery &&
                replyData?.suggestions?.download.length > 0 &&
                replyData?.suggestions?.download.map(
                  (question: any, index: number) =>
                    question &&
                    question.length > 0 && (
                      <div className='chip' onClick = {async () => {
                              dispatch(setDownloadFileMessageExcel());
                              await exportToCSV(
                                replyData?.sqlQuery,
                                convStyleEmbryonic
                                  ? RESPONSE_BY.EMBROYNIC
                                  : RESPONSE_BY.OPENAI, convStyleEmbryonic ?? false
                              );
                              dispatch(clearDownloadFileMessage());
                            }}>
                        {question}
                      </div>
                    )
                )}
            </div>
          </div>
        )}
    </>
     );
};
export default React.memo(GetChatResponse);
