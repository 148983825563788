import { type AnyAction } from 'redux';
import { type Loop, type LoopReducer } from 'redux-loop';
import * as commonActions from '../actions/commonActions';

export interface ICommonState {
  userEmail: string;
  isSideBarOpen?: boolean;
  isRightSideBarOpen?: boolean;
  eyIPInterRadio?: string;
  checkedCheckboxes?: Array<string>;
  validURLinputValue?: string;
  chatSearchInput?: string;
  anySourceSelected?: boolean;
  selectedSourceOnNewChat?: string;
  isSurveyDocOpen: boolean;
  isFavouriteStatus?: boolean;
  policyTypeList?:any;
  showPrivacyAndTermsModal?: boolean;
  showUnauthorizedAccess?: boolean;
  isByodDocOpen?: boolean;
  triggerDnfOpenAiSearchValue?:boolean;
  selectedFileNames?: string[];
  filterDropDownObj?: any;
  filtersData?: any;
  selectedSourceRadio?: string;
}

export const initialState: ICommonState = {
  userEmail: '',
  isSideBarOpen: false,
  isRightSideBarOpen: false,
  eyIPInterRadio: '',
  checkedCheckboxes: [],
  validURLinputValue: '',
  chatSearchInput: '',
  anySourceSelected: false,
  selectedSourceOnNewChat: '',
  isSurveyDocOpen: false,
  isFavouriteStatus: false,
  policyTypeList:[],
  showPrivacyAndTermsModal: false,
  showUnauthorizedAccess: false,
  isByodDocOpen: false,
  triggerDnfOpenAiSearchValue:false,
  selectedFileNames: [],
  filterDropDownObj: {checkedsector: [], checkedSubsector: [], checkedReportType: [], checkedgeography: [], selectedContentAge: ''},
  filtersData: {checkedsector: [], checkedSubsector: [], checkedReportType: [], checkedgeography: [], selectedContentAge: ''},
  selectedSourceRadio: 'TBRC'
};

export const commonReducer: LoopReducer<ICommonState> = (
  state: ICommonState = initialState,
  action: AnyAction
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
): ICommonState | Loop<ICommonState, AnyAction> => {
  switch (action.type) {
    case commonActions.SET_USER_EMAIL: {
      return {
        ...state,
        userEmail: action.email
      };
    }

    case commonActions.SHOW_PRIVACY_AND_TERMS_MODAL: {
      return {
        ...state,
        showPrivacyAndTermsModal: action.data,
        policyTypeList:action.policyTypeList
      };
    };

   case commonActions.SHOW_UNAUTHORIZED_ACCESS: {
      return {
        ...state,
        showUnauthorizedAccess: action.value
      };
    }

    case commonActions.SET_CHUNK_RESPONSE: {
      return {
        ...state,
        chatSearchInput: action.text
      };
    }

    case commonActions.IS_MR_CHIP_ACTIVE: {
      return {
        ...state,
        isMRChipActive: action.value
      }
    }
    
    case commonActions.SET_FILE_NAME_FOR_DOWNLOAD: {
      return {
        ...state,
        selectedFileNames: action.fileNames
      };
    }

    case commonActions.SET_FILTER_DROPDOWN: {
      return {
        ...state,
        selectedFileNames: action.filterDropDownObj
      };
    }

    case commonActions.SET_FILTERS_FOR_API: {
      return {
        ...state,
        filtersData: action.filtersData,
        selectedSourceRadio: action.selectedSourceRadio
      };
    }


    default:
      return state;
  }

  
};
