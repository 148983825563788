import { useDispatch,useSelector } from "react-redux"
import { clearDownloadFileMessage, setErrorMessage, clearErrorMessage } from "../../../../store/actions/openAiActions"
import { exportToCSV } from "../../utils/exportToCSV"
import { exportToPpt } from "../../utils/exportToPPT"
import exportToWord from "../../utils/exportToWord"
import { RESPONSE_BY } from "../../configs/type/types"
import { IStore } from "../../../../store"

const useExportToFile = () => {
    const dispatch = useDispatch()
    const userData = useSelector((state: IStore) => state.userProfile?.userData);
    const csvExport = async (query: string | undefined, source: RESPONSE_BY.OPENAI | RESPONSE_BY.EMBROYNIC, isEGPTOnly: boolean) => {
        try {
            await exportToCSV(query, source,userData, isEGPTOnly)
        } catch (error) {
            dispatch(clearDownloadFileMessage())
            dispatch(setErrorMessage("Something went wrong"))
            setTimeout(() => {
                dispatch(clearErrorMessage())

            }, 5000)
        }
    }
    return { exportToCSV: csvExport, exportToPpt, exportToWord }
}

export default useExportToFile