import { createStore, compose, type AnyAction, type StoreEnhancer, applyMiddleware } from 'redux';
import { install, type StoreCreator, combineReducers } from 'redux-loop';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  commonReducer,
  type ICommonState,
  initialState as commonInitialState
} from './reducers/commonReducer';
import { actionBlockerMiddleware } from './middlewares/actionBlockerMiddleware';
import redirectOnErrorMiddleware from './middlewares/redirectOnErrorMiddleware';
import { PRODUCTION } from '../app.constant';
import {
  type IUserProfileState,
  userProfileReducer,
  initialState as userProfileInitialState
} from './reducers/userProfileReducer';
import { initialStateOpenAiChat, openaiReducer } from './reducers/openaiReducer';

export interface IStore {
  common: ICommonState;
  userProfile: IUserProfileState;
  openAiChat: any;
}

export const rootReducer = combineReducers<IStore, AnyAction>({
  common: commonReducer,
  userProfile: userProfileReducer,
  openAiChat: openaiReducer,
});

export const initialState: IStore = {
  common: commonInitialState,
  userProfile: userProfileInitialState,
  openAiChat: initialStateOpenAiChat,
};

export const enhancedCreateStore = createStore as StoreCreator;

export let enhancer: StoreEnhancer<any, AnyAction> = compose(
  install<IStore>(),
  applyMiddleware(actionBlockerMiddleware, redirectOnErrorMiddleware)
);

if (process.env.NODE_ENV !== PRODUCTION) {
  // @ts-expect-error - Redux DevTools typings are not up to date
  if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    const composeEnhancers = composeWithDevTools({
      trace: true,
      traceLimit: 25
    });
    enhancer = compose(
      install<IStore>(),
      composeEnhancers(applyMiddleware(actionBlockerMiddleware, redirectOnErrorMiddleware))
    );
  }
}

export const store = enhancedCreateStore(rootReducer, initialState, enhancer);
