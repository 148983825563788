import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../modal/Modal';
import scssVar from '../../../styles/variables.module.scss';
import { ModalType } from '../../../models/ModalType';
import LoadIndicator from '../loadIndicator/LoadIndicator';
import { customFileUploadIcon, feedbackMenuIcon, feedbackSuccessIcon } from '../../../commonImage';
import {
  MotifFormField,
  MotifLabel,
  MotifOption,
  MotifSelect,
  MotifTextArea,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
  MotifErrorMessage
} from '@ey-xd/motif-react';
import { saveUserFeedback } from '../../../services/userFeedbackService';
import { HttpStatusCode } from '../../../ui-constants/base/enum';
import { FEEDBACK_ATTACHMENT_MAX_LIMIT } from '../../../app.constant';
import {
  formatFileSize,
  isContainSpecialCharacters,
  truncateString
} from '../../../utils/commonUtils';
import Dropzone from 'react-dropzone';
const { lightgray, feedbackModalWidth, grey50 } = scssVar;

interface IOwnProps {
  show: boolean;
  onClose: () => void;
  headerIcon: React.ReactChild;
}

const FeedbackModal: React.FC<IOwnProps> = ({ show, onClose, headerIcon }: IOwnProps) => {
  const loggedUserName = localStorage.getItem('accountName');
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackDescriptionLength, setFeedbackDescriptionLength] = useState(0);
  const [feedbackCategory, setFeedbackCategory] = useState('');
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const feedbackCategoryList = [
    {
      id: 1,
      category: 'Functionality Related Observation'
    },
    {
      id: 2,
      category: 'Data Related Observation'
    },
    {
      id: 3,
      category: 'Query Response Observation'
    },
    {
      id: 4,
      category: 'Others'
    }
  ];
  const [userSelectedFiles, setUserSelectedFiles] = useState<any>([]);
  const [isFeedbackFormSubmitted, setIsFeedbackFormSubmitted] = useState(false);
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState<string>('');
  const allowedTypes = [
    'application/msword', // .doc, .dot
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template', // .dotx
    'application/vnd.ms-excel', //.xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/pdf', // .pdf
    'image/jpeg', // .jpg, .jpeg
    'image/png', // .png
    'text/csv', // .csv
    'text/plain' // .txt
  ];

  const submitUserFeedback = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('FeedbackCategory', feedbackCategory);
    formData.append('Description', feedbackDescription);
    formData.append('Attachments', userSelectedFiles?.length > 0 ? userSelectedFiles[0] : []);
    const feedbackSubmissionResponse = await saveUserFeedback(formData);
    if (feedbackSubmissionResponse?.status === HttpStatusCode.Success) {
      setIsLoading(false);
      resetFeedbackModal();
      setIsFeedbackFormSubmitted(true);
    } else {
      setIsLoading(false);
      setFeedbackErrorMessage(feedbackSubmissionResponse?.message);
    }
  };

  const onCloseButtonClick = () => {
    resetFeedbackModal();
    onClose();
  };

  const resetFeedbackModal = () => {
    setFeedbackCategory('');
    setFeedbackDescription('');
    setFeedbackDescriptionLength(0);
    setUserSelectedFiles([]);
    setIsFeedbackFormSubmitted(false);
    setFeedbackErrorMessage('');
  };

  const onCategoryChange = (e: any) => {
    setFeedbackCategory(e);
  };

  const onFeedbackDescriptionChange = (e: any) => {
    const newValue = e.target.value;
    if (newValue.length <= 1000) {
      setFeedbackDescriptionLength(newValue.length);
      setFeedbackDescription(newValue);
    }
  };

  const handleOnDrop = useCallback((acceptedFiles: any) => {
    setFeedbackErrorMessage('');
    const fileItemsArray = acceptedFiles ? Array.from(acceptedFiles) : [];
    if (fileItemsArray.length === 0) return;
    const isTooManyFilesSelected = fileItemsArray.some(
      (file: any) => file.error === 'Too many files'
    );

    let totalFilesize = 0;
    fileItemsArray.forEach((file: any) => {
      totalFilesize += file.size;
    });
    const isLargeFileSelected = totalFilesize > FEEDBACK_ATTACHMENT_MAX_LIMIT;

    let isIncorrectFileFormat = false;
    let isInValidFileName = false;
    for (let index = 0; index < fileItemsArray.length; index++) {
      let currentFile: any = fileItemsArray[index];
      if (!allowedTypes.includes(currentFile?.type)) {
        isIncorrectFileFormat = true;
        break;
      }
      if (isContainSpecialCharacters(currentFile.name)) {
        isInValidFileName = true;
        break;
      }
    }

    if (isTooManyFilesSelected) {
      setFeedbackErrorMessage('Too many files selected');
      setUserSelectedFiles([]);
      return;
    } else if (isIncorrectFileFormat) {
      setFeedbackErrorMessage('Incorrect file format');
      setUserSelectedFiles([]);
      return;
    } else if (isLargeFileSelected) {
      setFeedbackErrorMessage('Invalid file size');
      setUserSelectedFiles([]);
      return;
    } else if (isInValidFileName) {
      setFeedbackErrorMessage('Invalid - Special characters included in file name');
      setUserSelectedFiles([]);
    } else {
      setUserSelectedFiles(fileItemsArray);
    }
  }, []);

  const handleRemoveFiles = (selectedItem: any) => {
    const filteredFiles = userSelectedFiles.filter((file: any) => file.name !== selectedItem.name);
    setUserSelectedFiles(filteredFiles);
    setFeedbackErrorMessage('');
  };

  const getCustomUploadIcon = () => {
    return (
      <span className='custom-upload-icon'>
        <img src={customFileUploadIcon}></img>
      </span>
    );
  };

  return (
    <Modal
      isOpen={show}
      type={ModalType.Feedback}
      headerTitle=''
      actionBtnEnabled={
        !feedbackCategory || !feedbackDescription || feedbackErrorMessage?.length > 0 ? false : true
      }
      actionBtnText={isFeedbackFormSubmitted ? '' : 'Submit'}
      onActionBtnClicked={() => {
        submitUserFeedback();
      }}
      onCancelBtnClicked={() => onCloseButtonClick()}
      cancelBtnText={isFeedbackFormSubmitted ? '' : 'Cancel'}
      onCloseBtnClicked={() => onCloseButtonClick()}
      onSkipBtnClicked={() => {}}
      headerIcon={headerIcon}
      showHeaderIcon={true}
      headerTitleIcon={isFeedbackFormSubmitted ? '' : feedbackMenuIcon}
      showHeaderTitleIcon={isFeedbackFormSubmitted ? false : true}
      width={+feedbackModalWidth}
      customHeaderClass='user-feedback-header'
      isLoading={isLoading}>
      <StyledFeedbackModal
        isLoading={isLoading}
        isFeedbackFormSubmitted={isFeedbackFormSubmitted}
        isAttachmentSelected={userSelectedFiles?.length > 0}
        isErrorMessageShown={feedbackErrorMessage?.length > 0 ? true : false}>
        {isLoading ? (
          <div className='loader'>{<LoadIndicator color={'#000000'} />}</div>
        ) : (
          <>
            <div className='feedback-modal-container'>
              {!isFeedbackFormSubmitted ? (
                <>
                  <h1 className='user-info'>
                    Hi {loggedUserName ?? loggedUserName}, please share your feedback
                  </h1>
                  <div className='flex-box feedback-category-div'>
                    <span className='label'>Please select the feedback category</span>
                    <MotifFormField className={'valid-field'}>
                      {!feedbackCategory ? (
                        <MotifLabel className='fieldLable'> {'Select category'}</MotifLabel>
                      ) : (
                        ''
                      )}
                      <MotifSelect
                        className='Input-motif'
                        ariaLabelledBy='select-label'
                        onChange={onCategoryChange}
                        value={feedbackCategory}
                        required
                        visibleOptions={4}>
                        {feedbackCategoryList.map((item: any) => {
                          return (
                            <MotifOption
                              className={item.category}
                              value={item.category}
                              key={item.id}>
                              {item.category}
                            </MotifOption>
                          );
                        })}
                      </MotifSelect>
                    </MotifFormField>
                  </div>
                  <div className='flex-box'>
                    <span className='label'>
                      Share the feedback (required) <span className='required'></span>
                    </span>
                    <MotifFormField className={'valid-field'}>
                      {!feedbackDescription ? (
                        <MotifLabel className='fieldLable'> {'Enter a description...'}</MotifLabel>
                      ) : (
                        ''
                      )}
                      <MotifTextArea
                        label='inputdescription'
                        onChange={onFeedbackDescriptionChange}
                        className='InputDescription'
                        value={feedbackDescription}
                        maxLength={1000}
                        autocomplete='off'
                      />
                      <span className='feedback-description-info'>{`(${feedbackDescriptionLength}/1000)`}</span>
                    </MotifFormField>
                  </div>
                  <div className='flex-box'>
                    <MotifFormField>
                      <Dropzone onDrop={handleOnDrop} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className='custom-file-uploader'>
                            <input {...getInputProps()} />
                            <div className='custom-icon-div'>{getCustomUploadIcon()}</div>
                            <span className='file-uploader-label-text'>
                              Click to upload or drag and drop
                              <br></br>
                              PPT, PDF, PNG, JPG, DOC, XLS, CSV or TXT (Max file size : 2 MB)
                            </span>
                          </div>
                        )}
                      </Dropzone>
                      {userSelectedFiles.map((currentFile: any) => {
                        return (
                          <>
                            <MotifFileUploaderItem
                              aria-label='Item aria-label text'
                              fileIcon={
                                <MotifIcon src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQgMkg2Yy0xLjEgMC0xLjk5LjktMS45OSAyTDQgMjBjMCAxLjEuODkgMiAxLjk5IDJIMThjMS4xIDAgMi0uOSAyLTJWOGwtNi02em0yIDE2SDh2LTJoOHYyem0wLTRIOHYtMmg4djJ6bS0zLTVWMy41TDE4LjUgOUgxM3oiLz48L3N2Zz4=' />
                              }
                              fileName={truncateString(currentFile.name, 55)}
                              id={currentFile.lastModified.toString()}
                              onRemove={() => {
                                handleRemoveFiles(currentFile);
                              }}
                              removable
                              error={currentFile?.error?.length > 0 ? true : false}
                              title={`${currentFile?.name}${currentFile.size ? ' - ' + formatFileSize(currentFile.size) : ''}`}
                            />
                          </>
                        );
                      })}
                      {feedbackErrorMessage?.length > 0 && (
                        <MotifErrorMessage className='api-error-message'>
                          {feedbackErrorMessage}
                        </MotifErrorMessage>
                      )}
                    </MotifFormField>
                  </div>
                </>
              ) : (
                <div className='feedback-success-div'>
                  <span className={`feedback-success-icon`}>
                    <img src={feedbackSuccessIcon} />
                  </span>
                  <br></br>
                  <span className='success-message'>Thanks for your feedback</span>
                </div>
              )}
            </div>
          </>
        )}
      </StyledFeedbackModal>
    </Modal>
  );
};

const StyledFeedbackModal = styled.div<{
  isLoading?: boolean;
  isFeedbackFormSubmitted?: boolean;
  isAttachmentSelected?: boolean;
  isErrorMessageShown?: boolean;
}>`
  display: ${(props) => (props.isLoading ? 'flex' : '')};
  align-items: ${(props) => (props.isLoading ? 'center' : '')};
  justify-content: space-around;
  min-height: ${(props) =>
    props.isFeedbackFormSubmitted
      ? '180px'
      : props.isAttachmentSelected && props.isErrorMessageShown
        ? '525px'
        : props.isAttachmentSelected || props.isErrorMessageShown
          ? '490px'
          : '435px'};
  max-height: auto;
  overflow-y: hidden;
  background-color: rgb(35, 35, 47);
  .loader {
    position: relative;
    bottom: 30px;
  }

  * {
    font-family: ${scssVar.fontInterstate} !important;
    font-size: ${'13px'} !important;
  }
  .motif-file-uploader-item .motif-file-uploader-item-file-name {
    text-align: left;
  }
  .motif-chip .motif-chip-button {
    background-color: white !important;
  }
  .user-feedback-header {
    align-items: center;
  }
  .feedback-modal-container {
    margin: 0% 5%;
  }
  .feedback-description-info {
    display: flex;
    flex-direction: row-reverse;
  }
  .feedback-category-div {
    margin-top: 3%;
  }
  .motif-file-uploader-label-text {
    white-space: pre-wrap;
    font-weight: bold;
  }
  .feedback-modal-container h1 {
    margin-top: 7px;
  }
  .success-message {
    font-size: 20px !important;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }
  .feedback-success-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .feedback-success-icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 48px;
    width: 48px;
    background: #e7fce8;
    border-radius: 200px;
    padding: 12px;
    gap: 8px;
  }
  .user-info {
    font-size: 18px !important;
    margin-top: 15px;
  }
  .api-error-message {
    color: #c70117;
    font-size: 14px !important;
    margin-top: 5%;
  }
  textarea {
    resize: none !important;
  }
  .motif-file-uploader-item-file-name {
    white-space: pre;
    width: 90%;
  }
  #feedback-form-file-uploader-label .motif-icon {
    height: 40px;
    width: 40px;
    background: rgb(243, 243, 245);
    border-radius: 200px;
    padding: 5px;
    padding-top: 10px;
  }
  .motif-file-uploader .motif-icon .custom-upload-icon svg {
    width: 75%;
    height: 75%;
  }
  .motif-file-uploader-item .motif-chip-button.motif-chip-removable {
    padding-left: 10px !important;
  }
  .motif-file-uploader-item .motif-close-icon {
    margin-left: -0.7rem;
  }
  .file-uploader-label-text {
    font-weight: 500;
    font-size: 15px;
    display: flex;
    text-align: center;
    justify-content: space-around;
  }
  .custom-file-uploader {
    border: none;
    background-image: linear-gradient(to right, #c3c3cb 50%, transparent 50%),
      linear-gradient(to right, #c3c3cb 50%, transparent 50%),
      linear-gradient(to bottom, #c3c3cb 50%, transparent 50%),
      linear-gradient(to bottom, #c3c3cb 50%, transparent 50%);
    background-position:
      left top,
      left bottom,
      left top,
      right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size:
      20px 1px,
      20px 1px,
      1px 20px,
      1px 20px;
    padding: 0.85rem 1.4285714286rem;
    cursor: pointer;
  }
  .custom-upload-icon {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(243, 243, 245);
    border-radius: 200px;
  }
  .custom-icon-div {
    text-align: center;
    display: flex;
    justify-content: center;
  }
`;

export default React.memo(FeedbackModal);
