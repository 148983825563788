import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MotifPaginationSelect, MotifPaginationSelectItem } from '@ey-xd/motif-react';
import MotifTable from '@ey-xd/motif-react/components/table';
import CustomHeader from './CustomHeader';
import './MarketReportMotifCSS.css';
import MarketReportNumber from './MarketReportNumber';
import { fetchAllReport } from '../../services/chat.service';
import { openLinkInSameTab, splitName } from '../../utils/commonUtils';
import LoadIndicator from '../common/loadIndicator/LoadIndicator';
import MarketReportsHome from '../customDropdown/MarketReportsHome';
import {
  downloadMultipleFiles,
  downloadMultipleFilesBlob,
  downloadReport,
  downloadReportBlob
} from '../openai/services/ChatService';
import MotifToast from '@ey-xd/motif-react/components/toast';
import { useDispatch, useSelector } from 'react-redux';
import { setFileNameForDownload, setFiltersForAPI } from '../../store/actions/commonActions';
import OverflowTooltip from '../common/OverflowTooltip';
import MarketPreview from './MarketPreview';
import { IStore } from "../../store";


const MarketReportTable = (): JSX.Element => {
  const tableRef = useRef<{ api?: { sizeColumnsToFit: () => void } } | null>(null);
  const [rowData, setRowData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [isLoading, setIsLoading] = useState(true);
  const [showDownloadInPorgress, setShowDownloadInProgress] = useState(false);
  const [fileName, setSelectedFileName] = useState('');
  const [selectedMultipleFileName, setSelectedMultipleFileName] = useState<string[]>([]);
  const [disableDownload, setDisableDownload] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number>(0);
  const dispatch = useDispatch();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [checkedsector, setCheckedsector] = useState<[]>([]);
  const [checkedSubsector, setCheckedSubSector] = useState<[]>([]);
  const [checkedgeography, setCheckedgeography] = useState<[]>([]);
  const [checkedReportType, setCheckedReportType] = useState<[]>([]);
  const [selectedContentAge, setSelectedContentAge] = useState<string>('');
  const [selectedSourceRadio, setSelectedSourceRadio] = useState('TBRC');
  const [isMarketPreview, setIsMarketPreview] = useState(false);
  const [pdfFileName, setPdfFileName] = useState('');
  const [filters, setFilter] = useState<any>({ checkedsector: [], checkedSubsector: [], checkedReportType: [], checkedgeography: [], selectedContentAge: '' });
  const userData = useSelector((store: IStore) => store.userProfile?.userData);
  const displayName = userData?.displayName.split(' ')?.[0];
  const [gridAPI, setGridAPI] = useState<any>(null);
  const [numberRowsSelected, setNumberRowsSelected] = useState(0);
  const [filterAppiled, setFilterApplied] = useState(false);
  const [filteredTableDataLength, setFilteredTableDataLength] = useState(0);

  useEffect(() => {
    let obj = { checkedsector: [], checkedSubsector: [], checkedReportType: [], checkedgeography: [], selectedContentAge: '' };
    if (selectedSourceRadio === 'TBRC') {
      obj.checkedsector = checkedsector;
      obj.checkedReportType = checkedReportType;
      obj.selectedContentAge = selectedContentAge;
      obj.checkedgeography = [];
      obj.checkedSubsector = [];
      setFilter({ ...obj })
    }
    else if (selectedSourceRadio === 'IBIS World') {
      obj.checkedsector = checkedsector;
      obj.checkedSubsector = checkedSubsector;
      obj.checkedgeography = checkedgeography;
      obj.checkedReportType = checkedReportType;
      obj.selectedContentAge = selectedContentAge;
      setFilter({ ...obj });
    }
    else {
      obj.checkedsector = [];
      obj.checkedSubsector = [];
      obj.checkedReportType = [];
      obj.checkedgeography = [];
      obj.selectedContentAge = '';
      setFilter({ ...obj });
    }

    return () => {
      obj.checkedsector = [];
      obj.checkedSubsector = [];
      obj.checkedReportType = [];
      obj.checkedgeography = [];
      obj.selectedContentAge = '';
      setFilter({ ...obj });
    }
  }, [checkedsector, checkedSubsector, checkedReportType, checkedgeography, selectedContentAge])

  useEffect(() => {
    dispatch(setFiltersForAPI(filters, selectedSourceRadio));
  }, [filters])

  const onClickSearchbutton = async () => {
      setFilterApplied(false);
      await fetchAllReport(filters).then((response) => {
        if (response) {
          if(filteredTableDataLength !== response.length) {
            setFilterApplied(true);
          }
          setRowData(response);
          setOriginalData(response);
          setIsLoading(false);
          setNumberRowsSelected(response?.length);
        } else {
          setFilterApplied(false);
          setRowData([]);
          setOriginalData([]);
          setIsLoading(false);
          setNumberRowsSelected(0);
        }
      }).catch((error) => {
        setRowData([]);
        setFilterApplied(false);
        setOriginalData([]);
        console.error("Error fetching chat response:", error);
        setIsLoading(false);
        setNumberRowsSelected(0);
      });
      dispatch(setFileNameForDownload([]));
      setSelectedRows(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllReport().then((response) => {
        if (response) {
          setFilterApplied(false);
          setFilteredTableDataLength(response.length);
          setRowData(response);
          setOriginalData(response);
          setIsLoading(false);
          setNumberRowsSelected(response?.length);
        } else {
          setFilteredTableDataLength(0);
          setRowData([]);
          setOriginalData([]);
          setIsLoading(false);
          setNumberRowsSelected(0);
        }
      }).catch((error) => {
        setRowData([]);
        setOriginalData([]);
        console.error("Error fetching chat response:", error);
        setIsLoading(false);
        setNumberRowsSelected(0);
      });
    };
    fetchData();
  }, []);

  
   const closeModal = () => {
    setPdfUrl('');
    setPdfFileName('');
    setIsMarketPreview(!isMarketPreview);
  };

  const onReportClick = async (params: any) => {
    setIsMarketPreview(!isMarketPreview);
    setPdfFileName(params);
    const reportBlob = await downloadReport(params);
    if (reportBlob) {
      const blobUrl = reportBlob.pdf_data;
      setPdfUrl(blobUrl);
    }
   };

  const getColumnDefs = (selectedDataSource: string, selectedRowCount: number) => {
    const commonColumns = [
      {
        field: "",
        checkboxSelection: true,
        flex: 0.3,
      },
      {
        field: "Source",
        headerName: "Data source",
        sortable: true,
        flex: 0.6,
        sort: "desc",
      },
      {
        field: "PdfFile",
        headerName: "Report name",
        flex: 1,
        sortable: true,
        sort: "desc",
        cellRenderer: (params: any) => {
          return(
          <OverflowTooltip tooltipContent={params.data.PdfFile}>
              <span onClick={() => onReportClick(params.data.PdfFile)}>{params.data.PdfFile}</span>
            </OverflowTooltip>
          )
        },
      }
    ];

    if (selectedDataSource === 'IBIS World') {
      return [
        ...commonColumns,
        {
          field: "geography",
          headerName: "Geography",
          flex: 0.6,
          sortable: true,
          sort: "desc",
        },
        {
          field: "Sector",
          headerName: "Sector",
          flex: 0.6,
          sortable: true,
          sort: "desc",
          cellRenderer: (params: any) => {
            return (
              <OverflowTooltip tooltipContent={params.data.Sector}>
                {params.data.Sector}
              </OverflowTooltip>
            )
          },
        },
        {
          field: "subSector",
          headerName: "Sub sector",
          flex: 0.6,
          sortable: true,
          sort: "desc",
        },
        {
          field: "Date",
          headerName: "Date of publishing",
          flex: 0.6,
          sortable: true,
          sort: "desc",
        },
      ];
    } else if (selectedDataSource === 'TBRC') {
      return [
        ...commonColumns,
        {
          field: "Sector",
          headerName: "Sector",
          flex: 0.6,
          sortable: true,
          sort: "desc",
          cellRenderer: (params: any) => {
            return (
              <OverflowTooltip tooltipContent={params.data.Sector}>
                {params.data.Sector}
              </OverflowTooltip>
            )
          },
        },
        {
          field: "Pages",
          headerName: "Report type",
          flex: 0.6,
          sortable: true,
          sort: "desc",
          cellRenderer: (params: any) => {
            if (params.data.Pages > 70) {
              return (
                <OverflowTooltip tooltipContent = {"Detailed Report (300 pages)"}>
                  {"Detailed Report (300 pages)"}
                </OverflowTooltip>
              )
            } else {
              return (
                <OverflowTooltip tooltipContent = {"Short Report (70 pages)"}>
                  {"Short Report (70 pages)"}
                </OverflowTooltip>
              )
            }
          }
        },
        {
          field: "Date",
          headerName: "Date of publishing",
          flex: 0.6,
          sortable: true,
          sort: "desc",
        },
      ];
    } else {
      return [
        ...commonColumns,
        {
          field: "geography",
          headerName: "Geography",
          flex: 0.6,
          sortable: true,
          sort: "desc",
        },
        {
          field: "Sector",
          headerName: "Sector",
          flex: 0.6,
          sortable: true,
          sort: "desc",
          cellRenderer: (params: any) => {
            return (
              <OverflowTooltip tooltipContent={params.data.Sector}>
                {params.data.Sector}
              </OverflowTooltip>
            )
          },
        },
        {
          field: "subSector",
          headerName: "Sub sector",
          flex: 0.6,
          sortable: true,
          sort: "desc",
          cellRenderer: (params: any) => {
            return (
              <OverflowTooltip tooltipContent={params.data.subSector}>
                {params.data.PdfFile}
              </OverflowTooltip>
            )
          },
        },
        {
          field: "reportType",
          headerName: "Report type",
          flex: 0.6,
          sortable: true,
          sort: "desc",
        },
        {
          field: "Date",
          headerName: "Date of publishing",
          flex: 0.8,
          sortable: true,
          sort: "desc",
        },
      ]
    }
  };
  const [columnDefs, setColumnDefs] = useState(getColumnDefs(selectedSourceRadio, selectedMultipleFileName?.length));

  useEffect(() => {
    setColumnDefs(getColumnDefs(selectedSourceRadio, selectedMultipleFileName?.length));
    const filteredTableData = originalData.length > 0 && selectedSourceRadio === "All"
      ? originalData
      : originalData.length > 0 && originalData?.filter((row: any) => row.Source === selectedSourceRadio) || [];
    setRowData(filteredTableData);
  }, [selectedSourceRadio, originalData]);

  const onRowSelection = (event: any) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedRows(selectedRows?.length);
    // if (selectedRows.length > 10) {
    //   event.node.setSelected(false);
    // } else {
    if (selectedRows.length === 1) {
      const fileName = selectedRows[0].PdfFile;
      setSelectedFileName(fileName);
      dispatch(setFileNameForDownload([fileName]));
      setSelectedMultipleFileName([]);
    } else if (selectedRows.length > 1) {
      const fileNames = selectedRows.map((row: any) => row.PdfFile);
      setSelectedMultipleFileName(fileNames);
      dispatch(setFileNameForDownload(fileNames));
      setSelectedFileName('');
    } else {
      setSelectedMultipleFileName([]);
      setSelectedFileName('');
      dispatch(setFileNameForDownload([]));
    }
    // }
  };

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current && tableRef.current.api) {
        tableRef.current.api.sizeColumnsToFit();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleReportSearch = (event: any) => {
    gridAPI.setQuickFilter(event.target.value);
    const displayedRows = [];
    gridAPI.forEachNodeAfterFilterAndSort((node: any) => {
        displayedRows.push(node.data);
    });
    setNumberRowsSelected(displayedRows?.length);
  }

  const handleSectorProfileDownload = () => {
    const linkObject = {
      url: process.env.REACT_APP_GENERATE_APP_SECTORTREND_URL,
      name: 'Sector Trend'
    };
    openLinkInSameTab(linkObject, '');
  }

  const getFormattedFileNames = () => {
    return {
      file_names: selectedMultipleFileName
    };
  };

  const handledReportDownload = async () => {
    if (fileName) {
      setShowDownloadInProgress(true);
      setDisableDownload(true);
      const reportBlob = await downloadReport(fileName.toString());
      if (reportBlob) {
        setShowDownloadInProgress(false);
        setDisableDownload(false);
        downloadReportBlob(reportBlob);
      }
    } else if (selectedMultipleFileName.length > 0) {
      setShowDownloadInProgress(true);
      setDisableDownload(true);
      const formattedFileNames = getFormattedFileNames();
      const reportBlob = await downloadMultipleFiles(formattedFileNames);
      if (reportBlob) {
        setShowDownloadInProgress(false);
        setDisableDownload(false);
        downloadMultipleFilesBlob(reportBlob);
      }
    }
  }

  if (isLoading) {
    return (
      <div className="loader loading">
        <LoadIndicator />
      </div>
    );
  }

  const onGridReady = (params: any) => {
    setGridAPI(params.api);
  }

  return (
    <div>
      <StyleAppStoreComponent>
        <label className='welcome-text top-text'>Welcome, {displayName}</label>
        <div className='text top-text'>Let's view, download or query on Market Reports on different sectors</div>
      </StyleAppStoreComponent>
      <StyledMarketReportTable selectedSourceRadio={selectedSourceRadio}>
        <MarketReportsHome
          checkedsector={checkedsector}
          setCheckedsector={setCheckedsector}
          checkedSubsector={checkedSubsector}
          setCheckedSubSector={setCheckedSubSector}
          checkedgeography={checkedgeography}
          setCheckedgeography={setCheckedgeography}
          checkedReportType={checkedReportType}
          setCheckedReportType={setCheckedReportType}
          selectedContentAge={selectedContentAge}
          setSelectedContentAge={setSelectedContentAge}
          selectedSourceRadio={selectedSourceRadio}
          setSelectedSourceRadio={setSelectedSourceRadio}
          onClickSearchbutton = {onClickSearchbutton}
        />
        <MarketReportNumber rowData={originalData} selectedRows={selectedRows} selectedSourceRadio={selectedSourceRadio} />
        <div className={"ag-theme-quartz custom-table"}>
          <CustomHeader
            handleReportSearch={handleReportSearch}
            handleSectorProfileDownload={handleSectorProfileDownload}
            handledReportDownload={handledReportDownload}
            rowData={rowData}
            showDownloadInPorgress={disableDownload}
            selectedRows={selectedRows}
            selectedSourceRadio={selectedSourceRadio}
            numberRowsSelected = {numberRowsSelected}
            filterAppiled = {filterAppiled}
          />
          {rowData?.length === 0 ?
            <NoDataContainer>No Report Found</NoDataContainer>
            :
            <MotifTable
              ref={tableRef}
              rowData={rowData}
              columnDefs={columnDefs}
              suppressCellFocus
              suppressRowClickSelection
              rowSelection="multiple"
              onRowSelected={(event: any) => onRowSelection(event)}
              pagination
              paginationPageSize={pageSize}
              onGridReady={onGridReady}
              paginationSelect={
                <MotifPaginationSelect>
                  <MotifPaginationSelectItem
                    onClick={function noRefCheck() {
                      setPageSize(15);
                    }}  //@ts-ignore
                    selected
                  >
                    Show 15
                  </MotifPaginationSelectItem>
                  <MotifPaginationSelectItem
                    onClick={function noRefCheck() {
                      setPageSize(25);
                    }}
                  >
                    Show 25
                  </MotifPaginationSelectItem>
                  <MotifPaginationSelectItem
                    onClick={function noRefCheck() {
                      setPageSize(50);
                    }}
                  >
                    Show 50
                  </MotifPaginationSelectItem>
                </MotifPaginationSelect>
              }
            />
          }
        </div>
        {showDownloadInPorgress && (
          <MotifToast
            className="documents-ready"
            variant="success"
            actionName=""
            position="top"
            onClose={() => {
              setShowDownloadInProgress(false);
            }}
          >
            <div>
              Downloading..
            </div>
          </MotifToast>
        )}
        {isMarketPreview && (
          <MarketPreview show={isMarketPreview} closeModal={closeModal} pdfUrl={pdfUrl || ""} fileName={pdfFileName} />
        )}
      </StyledMarketReportTable>
    </div>
  );
};

const StyledMarketReportTable = styled.div<{ selectedSourceRadio: string }>`
  background: #1A1A24;
  min-height: 90vh;

  .custom-table {
    margin-top: ${(props): string => props.selectedSourceRadio === 'All' ? '24px' : '70px'};
    margin-left: 24px;
    margin-bottom: 24px;
    width: 96%;
    border: 1px solid #3A3A4A;
    border-radius: 8px;
  }
  .ag-header-cell-text {
    color: #DEDEE2;
    font-size: 12px;
    font-weight: 400;
  }
  .motif-table {
    border: none;
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: #dedee2;
  font-size: 18px;
`;

const StyleAppStoreComponent = styled.div`
  background: ##1A1A24;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-left: 12px;
  margin-bottom: 24px;
  width: 98%;

  .welcome-text {
    font-size: 28px;
    font-family: EYInterstate;
    font-weight: 400;
    line-height: 36px;
    color: #DEDEE2;
  }
  .text {
    font-size: 16px;
    font-family: EYInterstate;
    font-weight: 300;
    line-height: 24px;
    color: #A4A3B1;
    margin-top: 8px;
  }

  .top-text {
    width: 100%;
    position: relative;
    left: 1%;
  }
`;

export default MarketReportTable;