import {
  BorderStyle,
  Document,
  ImageRun,
  Packer,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  WidthType,
  TextRun
} from 'docx';
import { ParsedTableData, getSourcesList, prepareContentForTable } from '../component/ChatReply/ChatReply';
import { downloadFilenameGenerator, sanitizeHeaderText } from '.';
import LABELS from '../../../ui-constants/en.json';
import AppConst from '../../../ui-constants/appConstants.json';
import { decodeHTML } from '../../../utils/commonUtils';

import {
  IQuery,
  IQueryContent,
  RESPONSE_BY,
  RESPONSE_TYPE,
  ItableCntentProps,
} from "../../../models/IQueryType";

const noBorder = {
  style: BorderStyle.NONE,
  size: 0,
  color: "ff0000",
};
const singleBorder = {
  style: BorderStyle.SINGLE,
  size: 1,
  color: "e8e8ee",
};
const noBorders = {
  top: noBorder,
  bottom: noBorder,
  left: noBorder,
  right: noBorder,
};

const tableCellStyling = {
  borders: {
    top: singleBorder,
    bottom: singleBorder,
    left: singleBorder,
    right: singleBorder,
  },
  shading: {
    color: "f7f7f8",
    fill: "000000",
    type: ShadingType.SOLID,
  },
};
const emptyRow = new TableRow({
  children: [
    new TableCell({
      borders: noBorders,
      children: [new Paragraph("")],
    }),
  ],
});

const generateSectorTable = (tableContent: any, source: string) => {
  if (tableContent?.length && tableContent?.length > 0) {
    const headersCells: TableCell[] = [];
    const valueRows: TableRow[] = [];

    headersCells.push(
      new TableCell({
        ...tableCellStyling,

        children: [
          new Paragraph({
            text: sanitizeHeaderText(
           'Document Title'
            ),
            style: 'tableHeader',
            spacing: {
              after: 100
            }
          })
        ]
      }),
      new TableCell({
        ...tableCellStyling,

        children: [
          new Paragraph({
            text: sanitizeHeaderText('Page no.s'),
            style: 'tableHeader',
            spacing: {
              after: 100
            }
          })
        ]
      })
    );
      headersCells.push(
        new TableCell({
          ...tableCellStyling,
          children: [
            new Paragraph({
              text: sanitizeHeaderText('Source'),
              style: 'tableHeader',
              spacing: {
                after: 100
              }
            })
          ]
        })
      );
    tableContent.forEach((data: any) => {
      const cells: TableCell[] = [];
      let fileNameContent = '';
      let fileParts = '';
      let pageNo = '';
      if (data.page_number.length > 0) {
        fileNameContent = data.file_name;
      }
      data.page_number.length &&
        data.page_number.forEach((numer: any, i: number) => {
          pageNo = pageNo + numer + (i === data.page_number.length - 1 ? '' : ', ');
        });

      cells.push(
        new TableCell({
          ...tableCellStyling,
          children: [
            new Paragraph({ text: fileNameContent }),
            // new Paragraph({ text: 'Market Reports' })
          ]
        })
      );
        cells.push(
          new TableCell({
            ...tableCellStyling,
            children: [new Paragraph({ text: pageNo })]
          })
        );
      cells.push(
        new TableCell({
          ...tableCellStyling,
          children: [
            new Paragraph({
              text: data?.source
            })
          ]
        })
      );
      valueRows.push(
        new TableRow({
          children: cells
        })
      );
    });

    return new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      layout: TableLayoutType.AUTOFIT,
      rows: [
        // Header
        new TableRow({
          tableHeader: true,
          children: headersCells,
        }),
        ...valueRows,
      ],
    });
  } else {
    return new Table({
      rows: [emptyRow],
    });
  }
};

const seTokenError = (replyData: string) => {
  if (
    replyData?.includes(LABELS.TOKEN_ERR) ||
    replyData?.includes("https://aka.ms/oai/quotaincrease") ||
    replyData?.includes("SQL_ERROR")
  ) {
    return true;
  } else {
    return false;
  }
};

const generateDocSummary = (
  line: string,
  type: string,
  totalLength: number = 0,
  ind: number,
  isParitallyAbortedResponse: boolean,
  isCompletelyAbortedResposne: boolean,
  sourceSection?: any,
  source?: any,
) => {
  let modifiedSource: any = [];
  const pathName = window.location.pathname;
  sourceSection.map((source: any, index: number) => {
    if (isParitallyAbortedResponse && index === 0) {
      modifiedSource.push(new TextRun({
        text: LABELS.chat_reply.PARTIAL_STOP_GENERATION_MSG,
        break: 1,
        color: '656579',
        italics: true
      }))
    }
    else{
    modifiedSource.push(new TextRun({
      text: index === 0 ? `Source: ${source}` : `${source}`,
      break: 1,
    })
    );
  }
  });

  if (seTokenError(line)) {
    return new Paragraph({
      children: [
        new TextRun({ text: LABELS.chat_reply.EMBROYNIC_ERROR }),
        new TextRun({ text: '', break: 1 }),
        ...modifiedSource
      ],
    });
  }

  if (RESPONSE_BY.STRATEGY_EDGE === type && totalLength - 1 === ind) {
    return new Paragraph({
      children: [
        new TextRun({ text: line, color: isCompletelyAbortedResposne ? '656579' : '', italics: isCompletelyAbortedResposne ? true : false  }),
        new TextRun({ text: '' , break: 1}),
        ...modifiedSource
      ]
    });
  }
 
  // if (line.startsWith(LABELS.SORRY_ERR) && !line.startsWith(LABELS.SORRY_ERR1)) {
  //   return new Paragraph({
  //     children: [
  //       new TextRun({ text: LABELS.GENERIC_OOPS_ERROR }),
  //       new TextRun({
  //         text: ' Source: Secondary research',
  //         break: 1
  //       })
  //     ]
  //   });
  // }
  return new Paragraph(line);
};

const generateDocument = async (
  data: any,
  userImg: string | undefined,
  source: any,
  checkedCheckboxes?: any,
  eyIPInterRadio?: any,
  validURLinputValue?: any
) => {
  const IMAGES = {
    [RESPONSE_BY.USER]: userImg ? userImg : AppConst.AVATAR_IMAGE,
    [RESPONSE_BY.MARKET_REPORT]: AppConst.COMMON_DATASOURCE_IMG
  };
  const paragraphs: any = [];

  const tableRowWithCol = (
    respData: any,
    respImg: any,
    tableContent?: any,
    respType?: any,
    sourceSection?: any
  ) => {
    const totalLength = decodeHTML(respData.summary).split("\n");
    const { length } = totalLength;
    return (
      paragraphs.push(
        new TableRow({
          children: [
            new TableCell({
              borders: noBorders,
              margins: {
                right: 200,
              },
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: respImg,
                      transformation: {
                        width: 30,
                        height: 30,
                      },
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              borders: noBorders,
              children:[
                ...(respType !== "user"
                  ? decodeHTML(respData.summary).replaceAll('#', '')
                    .replaceAll('*', '')
                      .split('\n')
                      .map((line: string, ind: number) => {
                        return generateDocSummary(
                          line,
                          respType,
                          length,
                          ind,
                          respData?.isParitallyAbortedResponse,
                          respData?.isCompletelyAbortedResposne,
                          sourceSection
                        );
                      })
                  : [new Paragraph(respData.summary?.replace(/\n\r+$/, '').trim())]),
                 generateSectorTable(tableContent, source)
              ]
            })
          ]
        })
      ),
      paragraphs.push(emptyRow)
    );
  };

  data.forEach(async (chat: any, ind: number) => {
    const { SEResponse, LLMResponse, user } = chat;
    let tableContent = null;
    let sources: string[] | undefined = [];
    const sourceSection = ["Market Reports"];
    if (
      SEResponse?.ResponseType === RESPONSE_TYPE.TABLE &&
      SEResponse.Content &&
      SEResponse.Content.every((el: any) => Boolean(el) && typeof el === 'object')
    ) {
      tableContent = prepareContentForTable(SEResponse?.Content || LLMResponse?.Content);
    } else if (
      SEResponse?.relevantFiles &&
      SEResponse?.relevantFiles.every((el: any) => Boolean(el) && typeof el === 'object')
    ) {
      tableContent = SEResponse?.relevantFiles?.map((el: any) => {
        const obj: any = {
          file_name: el.file_name ?? '',
          page_number: el.page_number || [],
          authors: el?.authors || [],
          sector: el?.sector || [],
          geography: el?.geography || [],
          contentAge: el?.contentAge || '',
          source: el?.source || ''
        };
        return obj;
      });
    }

    if (user) {
      tableRowWithCol(user, IMAGES[RESPONSE_BY.USER], null, RESPONSE_BY.USER, sourceSection);
    }
    if (SEResponse) {
        tableRowWithCol(
          SEResponse,
          IMAGES[RESPONSE_BY.MARKET_REPORT],
          tableContent,
          RESPONSE_BY.STRATEGY_EDGE,
          sourceSection
        );
      }
  });

  const doc = new Document({
    styles: {
      default: {
        document: {
          run: {
            size: "11pt",
            font: "Calibri",
          },
        },
      },
      paragraphStyles: [
        {
          id: "tableHeader",
          run: {
            bold: true,
          },
        },
        {
          id: "summary",
          quickFormat: true,
        },
      ],
    },
    sections: [
      {
        children: [
          new Table({
            rows: paragraphs,
          }),
        ],
      },
    ],
  });
  return doc;
};

const exportToWord = async (
  data: IQuery[],
  userImage?: string,
  source?: any,
  checkedCheckboxes?: any,
  eyIPInterRadio?: any,
  validURLinputValue?: any
) => {
  const clonedData = structuredClone(data);
  const doc = await generateDocument(
    clonedData,
    userImage,
    source,
    checkedCheckboxes,
    eyIPInterRadio,
    validURLinputValue
  );
  const blob = await Packer.toBlob(doc);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = downloadFilenameGenerator(source, 'docx');
  link.click();
};

export default exportToWord;
