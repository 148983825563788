import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { faChevronUp, faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DropdownHierarchy.scss';
import isEqual from 'lodash/isEqual'; // Import lodash for deep comparison

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { includeParentsIfAllChildrenChecked } from '../../utils/commonUtils';
const iconClass = 'icon';
const DropdownHierarchy: React.FC<any> = ({
  treeNode,
  setCheckBoxValue,
  setClearFilter,
  disabled = false,
  hasDefaultChecks = false,
  defaultExpanded = [],
  checkedNodes = []
}: any) => {
  const [checked, setChecked] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>(defaultExpanded);
  const [totalchecked, setTotalchecked] = useState<string[]>([]);

  const extractAllNodes = (allNodes: any) => {
    let childrenArray: Array<any> = [];
    allNodes &&
      allNodes.children &&
      allNodes.children.map((currentEntity: any) => {
        if (currentEntity.children) {
          const recursiveChildren: Array<string> =
            extractAllCheckedChildrenRecursive(currentEntity);
          childrenArray = [...childrenArray, ...recursiveChildren];
        }
        childrenArray = [...childrenArray, currentEntity.value];
      });
    childrenArray.push(allNodes.value);
    return childrenArray;
  };

  const extractAllCheckedChildrenRecursive = (entity: any): Array<string> => {
    let allChildren: Array<any> = [];
    const children: Array<any> = entity.children;
    children &&
      children.map((currentEntity: any) => {
        if (currentEntity.children) {
          const recursiveChildren: Array<any> = extractAllCheckedChildrenRecursive(currentEntity);
          allChildren = [...allChildren, ...recursiveChildren];
        }
        allChildren.push(currentEntity.value);
      });
    return allChildren;
  };

  const handleCheck = useCallback((checked: any) => {
    const allCheckedIncludingParents: any = includeParentsIfAllChildrenChecked(checked, treeNode);
    setTotalchecked(allCheckedIncludingParents);
    setCheckBoxValue(allCheckedIncludingParents);
    setChecked(checked);
  }, [treeNode, setCheckBoxValue]);

  const handleExpand = (expanded: any) => {
    setExpanded(expanded);
  };

  const extractCheckedValues = (nodes: any): string[] => {
    let checkedValues: string[] = [];

    nodes.forEach((node: any) => {
      if (node.checked) {
        checkedValues.push(node.value);
      }
      if (node.children) {
        checkedValues = [...checkedValues, ...extractCheckedValues(node.children)];
      }
    });
    return checkedValues;
  };

  useEffect(() => {
    if (hasDefaultChecks) {
      const checkedValues = extractCheckedValues(treeNode);
      if (!isEqual(checkedValues, checked)) {
        setChecked(checkedValues);
        setCheckBoxValue(checkedValues);
        setTotalchecked(checkedValues);
      }
    }
  }, [treeNode, checked, setCheckBoxValue, setTotalchecked]);

  const prevCheckedNodesRef = useRef();

  useEffect(() => {
    if (!isEqual(prevCheckedNodesRef.current, checkedNodes)) {
      handleCheck(checkedNodes);
      prevCheckedNodesRef.current = checkedNodes;
    }
  }, [checkedNodes, handleCheck]);

  useEffect(() => {
    setChecked([]);
    setCheckBoxValue([]);
    setTotalchecked([]);
  }, [setClearFilter]);

  return (
    <>
      <div>
        <div className='sectorgeography'>
          <CheckboxTree
            icons={{
              expandClose: (
                <FontAwesomeIcon className={iconClass} icon={faChevronDown as IconProp} />
              ),
              expandOpen: <FontAwesomeIcon className={iconClass} icon={faChevronUp as IconProp} />,
              check: (
                <FontAwesomeIcon className='rct-icon rct-icon-check' icon={faCheck as IconProp} />
              )
            }}
            iconsClass='fa5'
            nodes={treeNode}
            checked={checked}
            expanded={expanded}
            disabled={disabled}
            expandDisabled={disabled}
            onCheck={handleCheck}
            onExpand={handleExpand}
          />
        </div>
      </div>
    </>
  );
};

export default DropdownHierarchy;
