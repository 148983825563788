import React, { useEffect, useState } from 'react';
import { Guid } from 'guid-typescript';
import './TableMarketReport.scss';
import { IRelevantFiles, ISectorContent } from '../../../../models/IQueryType';
import { sanitizeHeaderText } from '../../utils';
import { downloadReport, downloadReportBlob } from '../../services/ChatService';
import MotifToast from "@ey-xd/motif-react/components/toast";

type TableContentProps = {
  tableContent: Partial<IRelevantFiles>[];
  handleSlideDownload?: (data: any) => void;
};

const TableMarketReport = ({ tableContent, handleSlideDownload }: TableContentProps) => {
  const [showAllRecords, setShowAllRecords] = React.useState<boolean>(false);
  const [visibleRecords, setVisibleRecords] = React.useState<any>();
  const [showDownloadInPorgress, setShowDownloadInProgress] = useState(false);

  const visibledata = () => {
    if (!showAllRecords) {
      setVisibleRecords(tableContent?.slice(0, 3));
    } else {
      setVisibleRecords(tableContent?.slice(0, 5));
    }
  };

  useEffect(() => {
    visibledata();
  }, [showAllRecords]);

  const handleFileDownload = async (fileName: string) => {
    setShowDownloadInProgress(true);
    const reportBlob  = await downloadReport(fileName.toString());
    if(reportBlob){
      setShowDownloadInProgress(false);
      downloadReportBlob(reportBlob);
    }
  }

  return (
    <div className='mrtable__wrapper-sector' key={`key-${Guid.create()}`}>
      <table className='mrtable__content-table-sector'>
        <tbody>
          <tr key={`mrtable-header-${'files'}`}>
            <th>{sanitizeHeaderText('Document Title')}</th>
            <th key={`mrtable-header-${'Page'}`}>{sanitizeHeaderText('Page no.s')}</th>
            <th key={`mrtable-header-${'Source'}`}>{sanitizeHeaderText('Source')}</th>
          </tr>

          {visibleRecords &&
            visibleRecords.length > 0 &&
            visibleRecords?.map((value: ISectorContent, i: number) => (
              <tr key={`mrtable-row-${value.file_name}`}>
                <td key={`mrtable-data-value-${value.file_name}`} className='parent-container'>
                  <a
                    className='files-download-btn'
                    onClick={() => handleFileDownload(value.file_name)}
                  >
                    <div className= "ellipsis" title={value?.file_name}>{value?.file_name}</div>
                  </a>
                </td>
                <td>
                  <span  className='files-slide-download-btn'>
                    {value?.page_number?.map((val: any, i: number) =>
                      value.page_number.length - 1 === i ? (
                        <React.Fragment key={i.toString() + val}>
                          {' '}
                          <span
                            key={i.toString() + val}>{`${val}`}</span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment key={i.toString() + val}>
                          {' '}
                          <span
                            key={i.toString() + val}
                          >{`${val},`}</span>
                        </React.Fragment>
                      )
                    )}
                  </span>
                </td>
                <td>
                  <span>{value?.source}</span>
                </td>
              </tr>
            ))}
          {tableContent && tableContent?.length > 10 && (
            <tr key={`mrtable-row-${Guid.create()}`}></tr>
          )}
          {tableContent && tableContent?.length > 3 && (
            <tr key={`mrtable-row-${Guid.create()}`}>
              <td colSpan={3} className='mrtable__footer'>
                <button
                  className='btn-show'
                  onClick={() => {
                    setShowAllRecords(!showAllRecords);
                  }}>
                  {showAllRecords ? 'View less' : 'View more'}
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showDownloadInPorgress && (
          <MotifToast
            className="documents-ready"
            variant="success"
            actionName=""
            position="top"
            onClose={() => {
              setShowDownloadInProgress(false);
            }}
          >
            <div>
              Downloading..
            </div>
          </MotifToast>
        )}
    </div>
  );
};

export default React.memo(TableMarketReport);
