import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import MarketReportLanding from "../../marketReportLanding/MarketReportLanding";
import MarketReportTable from "../../marketReportTable/MarketReportTable";
import Header from '../header/Header';
import { ENVIRONMENTS } from "../../../app.constant";

const RoutingManager = (): JSX.Element => {
    const navigation = useNavigate();
    // const APP_ENVIRONMENT = process.env.REACT_APP_SE_APP_ENV;
    // const INSIGHT_EDGE_URL = process.env.REACT_APP_INSIGHT_EDGE_URL;
    // const mr_url = process.env.REACT_APP_MR_APP_URL;
  
    // useEffect(() => {
    //       if (
    //       APP_ENVIRONMENT === ENVIRONMENTS.LOCAL &&
    //       !window.opener &&
    //       window.location.search === "" &&
    //       window.location.href === mr_url
    //     ) {
    //       navigation("/")
    //     } else if (APP_ENVIRONMENT !== ENVIRONMENTS.LOCAL && !window.opener ) {
    //     window.open(INSIGHT_EDGE_URL, "_self");
    //   }
    // });
    
    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<MarketReportLanding />} />
                <Route path="/market-report" element={<MarketReportTable />} />
            </Routes>
        </div>
    );
};

export default RoutingManager;
