import pptxgen from 'pptxgenjs';
import { sanitizeHeaderText, downloadFilenameGenerator } from '.';
import { IQueryContent, RESPONSE_BY, IRelevantFiles } from '../../../models/IQueryType';
import LABELS from '../../../ui-constants/en.json';
import { downloadFilenameGeneratorForPPT } from '../../../utils/commonUtils';

export type pptDataType = {
  userQueryWithResponse: any;
  checkedCheckboxes?: any;
  eyIPInterRadio?: any;
  validURLinputValue?: any;
};

export const exportToPpt = (
  userQueryWithResponse: any,
  validURLinputValue?: any
) => {
  let response = userQueryWithResponse?.SEResponse;
  let query = userQueryWithResponse?.user?.summary;

  try {
    let presentation = new pptxgen();
    let slide = presentation.addSlide();
    let rows: pptxgen.TableRow[] = [];
    const sourceTable = [
      {
        text: `Source: Market Reports`,
        options: {
          border: { type: 'none' },
          colspan: 3,
          margin: [10, 0, 10, 0],
          fill: {
            color: '#ffffff'
          }
        }
      }
    ];
    rows.push([{ text: query, options: { bold: true } }]);
    if (response) {
      let { relevantFiles, summary } = response;
      rows.push([
        {
          text: summary.replaceAll(`#`, ' ').replaceAll(`*`, ' '),
          options:
            response?.responseType === 'stop_generation' ? { color: '656579', italic: true } : {}
        }
      ]);

      if (!relevantFiles) {
        if (response?.isParitallyAbortedResponse) {
          rows.push([
            {
              text: LABELS.chat_reply.PARTIAL_STOP_GENERATION_MSG,
              options: { color: '656579', italic: true }
            }
          ]);
        }
        rows.push([{ text: `Source: Market Reports` }]);
      }

      slide.addTable(rows, {
        autoPage: true
      });

      if (
        relevantFiles &&
        relevantFiles.length > 0 &&
        relevantFiles.every((el: any) => Boolean(el) && typeof el === 'object')
      ) {
        const documentTableRows: pptxgen.TableRow[] = [];
        const docuementTableHead: pptxgen.TableCell[] = [
          {
            text: sanitizeHeaderText('Document Title'),
            options: { bold: true }
          },
          {
            text: sanitizeHeaderText('Page no.s'),
            options: { bold: true }
          },
          {
            text: sanitizeHeaderText('Source'),
            options: { bold: true }
          }
        ];
        documentTableRows.push(docuementTableHead);
        relevantFiles.forEach((el: Partial<IRelevantFiles>) => {
          const rowValues: pptxgen.TableCell[] = [];
          const filesName = el.file_name;
          let filesPage = '';

          el.page_number?.forEach((slide: string, i) => {
            if (el?.page_number && el.page_number.length > i + 1) {
              filesPage = filesPage + slide + ', ';
            } else {
              filesPage = filesPage + slide;
            }
          });

          rowValues.push({
            text: filesName
          });
          rowValues.push({
            text: filesPage
          });
          rowValues.push({
            text: el.source
          });
          documentTableRows.push(rowValues);
        });

        if (response?.isParitallyAbortedResponse) {
          documentTableRows.push([
            {
              text: LABELS.chat_reply.PARTIAL_STOP_GENERATION_MSG,
              options: { color: '656579', italic: true }
            }
          ]);
        }

        documentTableRows.push(sourceTable as pptxgen.TableRow);
        presentation.addSlide().addTable(documentTableRows, {
          border: { color: 'e8e8ee', pt: 1, type: 'solid' },
          fill: {
            color: 'f7f7f8'
          },
          autoPage: true,
          autoPageCharWeight: -0.5,
          autoPageLineWeight: 0.5
        });
      }
    }

    presentation
      .write({ outputType: 'blob' })
      .then((file: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(file as Blob);
        link.download = downloadFilenameGeneratorForPPT(
          validURLinputValue,
          'ppt'
        );
        link.click();
      })
      .catch((err: any) => console.log('::PPT catch errr', err));
  } catch (err) {
    console.log('::::PPT error', err);
  }
};
