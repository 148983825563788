import {
  CLEAR_CHAT_HISTORY,
  GET_CHAT_HISTORY,
  SET_CHAT_HISTORY,
  TOGGLE_GPT_SIDEBAR,
  TOGGLE_LOADER,
  SHOW_GPT_BUTTON,
  TOOBLE_CLEAR_LOADER,
  SET_DOWNLOAD_FILE_MESSAGE,
  SET_ERROR_MESSAGE,
  SET_CHAT_SUMMARY,
  SET_CLEAR_SUMMARY,
  SET_CHAT_TABLE,
  SET_WHOLE_CHAT_TABLE,
  SET_CHUNK_DATA_LOADING,
  SET_CHAT_ERRORS,
  SET_SELECTED_CHAT_THREAD,
  SET_CHATDATA_SUMMARY,
  SET_RELEVANT_FILES
} from "../actionType";
import LABELS from "../../components/openai/configs/ui/en.json"

export const setChatErrors = (payload: any) => {
  return {
    type: SET_CHAT_ERRORS,
    payload,
  };
};
export const setChatHistory = (payload: any) => {
  return {
    type: SET_CHAT_HISTORY,
    payload,
  };
};

export const setChatTable  = (payload: any) => {
  return {
    type: SET_CHAT_TABLE,
    payload,
  };
};

export const setChatSummary = (payload: any) => {
  return {
    type: SET_CHAT_SUMMARY,
    payload,
  };
};

export const setWhoelChatTable = (payload: any) => {
  return {
    type: SET_WHOLE_CHAT_TABLE,
    payload,
  };
};

export const setClearSummary = () => {
  return {
    type: SET_CLEAR_SUMMARY,
  };
};

export const getChatHistory = () => {
  return {
    type: GET_CHAT_HISTORY,
  };
};

export const clearChatHistory = () => {
  return {
    type: CLEAR_CHAT_HISTORY,
  };
};

export const toggleGPTSidebar = (payload: any) => {
  return {
    type: TOGGLE_GPT_SIDEBAR,
    payload,
  };
};
export const showGPTButton = (payload: any) => {
  return {
    type: SHOW_GPT_BUTTON,
    payload,
  };
};

export const toggleLoader = (payload: any) => {
  return {
    type: TOGGLE_LOADER,
    payload,
  };
};
export const setClearLoader = (payload: any) => {
  return {
    type: TOOBLE_CLEAR_LOADER,
    payload,
  };
};
export const setDownloadFileMessage = (payload: any) => {
  return {
    type: SET_DOWNLOAD_FILE_MESSAGE,
    payload,
  };
};

export const setChunkLoading = (payload: boolean) => {
  return {
    type: SET_CHUNK_DATA_LOADING,
    payload,
  };
};

export const setSelectedChatThread = (thread: object) => {
  return {
    type: SET_SELECTED_CHAT_THREAD,
    thread
  };
}

export const clearDownloadFileMessage = () => setDownloadFileMessage('')

export const setDownloadFileMessagePPT = () => setDownloadFileMessage(LABELS.download_file_message.PPT)
export const setDownloadFileMessageWord = () => setDownloadFileMessage(LABELS.download_file_message.WORD)
export const setDownloadFileMessageExcel = () => setDownloadFileMessage(LABELS.download_file_message.EXCEL)

export const setErrorMessage = (payload: any) => {
  return {
    type: SET_ERROR_MESSAGE,
    payload,
  };
};
export const clearErrorMessage = () => setErrorMessage('')

export const setChatSummaryData = (payload: any) => {
  return {
    type: SET_CHATDATA_SUMMARY,
    payload,
  };
}

export const setRelevantFiles = (payload: any) => {
  return {
    type: SET_RELEVANT_FILES,
    payload
  };
};

export const getRelevantFiles = (payload: any) => {
  return {
    type: SET_RELEVANT_FILES,
    payload
  };
};
