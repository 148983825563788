import React from 'react';
import { MotifIconButton } from '@ey-xd/motif-react/components/button';
import { MotifIcon } from '@ey-xd/motif-react';
import styled from 'styled-components';
import DOWNLOAD_ICON  from '../../assets/images/download.svg';
import SEARCH_ICON from '../../assets/images/search.svg';
import { numberWithCommas } from '../../utils/commonUtils';

interface ICustomHeaderProps {
    handleReportSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handledReportDownload: () => void;
    handleSectorProfileDownload: () => void;
    rowData: any;
    showDownloadInPorgress?: boolean;
    selectedRows: number;
    selectedSourceRadio?: string;
    numberRowsSelected: number;
    filterAppiled: boolean;
}

const CustomHeader: React.FC<ICustomHeaderProps> = ({ 
    handleReportSearch, 
    handleSectorProfileDownload,
     handledReportDownload, 
     rowData, 
     showDownloadInPorgress, 
     selectedRows,
     selectedSourceRadio,
     numberRowsSelected,
     filterAppiled
    }) => {
    return (
        <StyledCustomHeader backgroundUrl={SEARCH_ICON}>
            <div className='custom'>
                <div className='title-text'>
                    <label className='textCss title'>{`${ filterAppiled ? "List of Reports" : "List of All Reports"} | ${selectedSourceRadio === "All" ? "IBIS World & TBRC" : selectedSourceRadio === "TBRC" ? "TBRC": "IBIS World"}`}: {numberWithCommas(numberRowsSelected)}</label>
                    <div className='textCss subTitle'>Only 10 reports can be downloaded at a time</div>
                </div>
                <div className='button-container'>
                    <div className='part-1'>
                        <input className='input-field' type="text" placeholder="Search the Report name" onChange={handleReportSearch}/>
                    </div>
                    <div className='part-2'>
                        <button className={`button ${selectedRows > 0 ? "button-single-selected" : "button-single" }`} onClick={handledReportDownload} disabled={showDownloadInPorgress || selectedRows >= 11}>
                            <img src={DOWNLOAD_ICON} className="" alt=''/> Download
                        </button>
                    </div>
                    <div className='part-3'>
                        <button className='button button-multi' onClick={handleSectorProfileDownload}>
                            <img src={DOWNLOAD_ICON} className="" alt='' /> Download sector profiles
                        </button>
                    </div>
                </div>
            </div>
        </StyledCustomHeader>
    );
};

const StyledCustomHeader = styled.div<{ backgroundUrl: string }>`
 .custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
 }

 .textCss {
    font-family: EYInterstate;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #F3F3F5;
 }

 .title {
    font-size: 18px;
    color: #F3F3F5;
    font-weight: 400;
}
 .subTitle {
    font-size: 16px;
    color: #A4A3B1;
    font-weight: 300;
}

.title-text {
    padding: 24px;
    flex: 0 0 40%;
}
.button-container {
  flex: 0 0 60%;
  display: flex;
  gap: 10px;
}
  .part-1 {
    flex: 0 0 40%;
}

.part-2 {
    flex: 0 0 20%;
}
.part-3 {
    flex: 0 0 40%;
}

.button {
    height: 44px;
    color: #A4A3B1;
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 6px;
}
.button-single {
    background: #2D2D3E;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.button-single-selected {
    cursor: pointer;
    color: #fff;
    background: #2D2D3E;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.button-multi {
    background: #2D2D3E;
    border: none;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    color: #fff;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
}

input[type=text] {
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  background: #2D2D3E;
  border: 1px solid #2D2D3E;
  color: #A4A3B1;
  font-size: 14px;
  background-image: url(${props => props.backgroundUrl});
  background-position: 10px 10px; 
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  border-radius: 6px;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.6; 
}
`;

export default CustomHeader;