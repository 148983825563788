export const TOGGLE_SIDE_BAR = false;
export const CLEAR_LOADING_INDICATORS = 'CLEAR_LOADING_INDICATORS';
export const SHOW_PRIVACY_AND_TERMS_MODAL = 'SHOW_PRIVACY_AND_TERMS_MODAL';
export const UPDATE_TIMELINE = 'UPDATE_TIMELINE';
export const ADD_ENTITY_ADVANCED_SEARCH = 'ADD_ENTITY_ADVANCED_SEARCH';
export const REMOVE_ENTITY_ADVANCED_SEARCH = 'REMOVE_ENTITY_ADVANCED_SEARCH';
export const ADD_ENTITY_ADVANCED_MARKET_SEARCH = 'ADD_ENTITY_ADVANCED_MARKET_SEARCH';
export const REMOVE_ENTITY_ADVANCED_MARKET_SEARCH = 'REMOVE_ENTITY_ADVANCED_MARKET_SEARCH';
export const RESET_ADVANCED_SEARCH_CRITERIA = 'RESET_ADVANCED_SEARCH_CRITERIA';
export const ADVANCED_SEARCH_FETCH_COMPANY = 'ADVANCED_SEARCH_FETCH_COMPANY';
export const ADVANCED_SEARCH_FETCH_COMPANY_SUCCESS = 'ADVANCED_SEARCH_FETCH_COMPANY_SUCCESS';
export const ADVANCED_SEARCH_FETCH_COMPANY_FAILED = 'ADVANCED_SEARCH_FETCH_COMPANY_FAILED';
export const ADVANCED_SEARCH_SET_NEXT_COMPANIES = 'ADVANCED_SEARCH_SET_NEXT_COMPANIES';
export const RESET_ADVANCED_SEARCH_COMPANY = 'RESET_ADVANCED_SEARCH_COMPANY';
export const UPDATE_TIMESCALE_SETTINGS_OPTION = 'UPDATE_TIMESCALE_SETTINGS_OPTION';
export const RESET_TIMELINE_OPTION = 'RESET_TIMELINE_OPTION';
export const UPDATE_START_END_DATE = 'UPDATE_START_END_DATE';
export const SET_TIMELINE_VISIBILITY = 'SET_TIMELINE_VISIBILITY';
export const UPDATE_SPLIT_START_END_DATE = 'UPDATE_SPLIT_START_END_DATE';
export const UPDATE_TIMELINE_FREQUENCY = 'UPDATE_TIMELINE_FREQUENCY';
export const TOGGLE_SPLIT_MODE = 'TOGGLE_SPLIT_MODE';
export const UPDATE_DOWNLOAD_FILTER='UPDATE_DOWNLOAD_FILTER';
export const IS_ACTIVE_TAB = 'IS_ACTIVE_TAB';
export const RESET_ADVANCED_MARKET_SEARCH_CRITERIA = "RESET_ADVANCED_MARKET_SEARCH_CRITERIA";
export const SET_ADVANCED_SEARCH_SET_QUERY_ENTITIES="SET_ADVANCED_SEARCH_SET_QUERY_ENTITIES";
export const SET_ADVANCED_SEARCH_GEOGRAPHY_COUNT="SET_ADVANCED_SEARCH_GEOGRAPHY_COUNT";
export const SET_ADVANCED_SEARCH_GEOGRAPHY_EXCLUDE_COUNT="SET_ADVANCED_SEARCH_GEOGRAPHY_EXCLUDE_COUNT";
export const SETTING_EXPANDED_PARENT = "SETTING_EXPANDED_PARENT";
export const SET_CHAT_HISTORY = "SET_CHAT_HISTORY";
export const GET_CHAT_HISTORY = "GET_CHAT_HISTORY";
export const CLEAR_CHAT_HISTORY = "CLEAR_CHAT_HISTORY";
export const TOGGLE_GPT_SIDEBAR = "TOGGLE_GPT_SIDEBAR";
export const TOGGLE_LOADER = "TOGGLE_LOADER";
export const TOOBLE_CLEAR_LOADER = "TOOBLE_CLEAR_LOADER";
export const SET_DOWNLOAD_FILE_MESSAGE = "SET_DOWNLOAD_FILE_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SHOW_GPT_BUTTON = "SHOW_GPT_BUTTON";
export const SET_CHAT_SUMMARY = "SET_CHAT_SUMMARY";
export const SET_CLEAR_SUMMARY = "SET_CLEAR_SUMMARY";
export const SET_CHAT_TABLE = "SET_CHAT_TABLE";
export const SET_WHOLE_CHAT_TABLE = "SET_WHOLE_CHAT_TABLE";
export const SET_CHUNK_DATA_LOADING = "SET_CHUNK_DATA_LOADING";
export const SET_CHAT_ERRORS = "SET_CHAT_ERRORS";
export const SET_SELECTED_CHAT_THREAD = 'SET_SELECTED_CHAT_THREAD';
export const SET_CHATDATA_SUMMARY = 'SET_CHATDATA_SUMMARY';
export const SET_RELEVANT_FILES = 'SET_RELEVANT_FILES';
