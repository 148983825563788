import { type AnyAction } from 'redux';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const ADD_LOADING_INDICATOR = 'ADD_LOADING_INDICATOR';
export const REMOVE_LOADING_INDICATOR = 'REMOVE_LOADING_INDICATOR';
export const SHOW_PRIVACY_AND_TERMS_MODAL = 'SHOW_PRIVACY_AND_TERMS_MODAL';
export const SHOW_UNAUTHORIZED_ACCESS = 'SHOW_UNAUTHORIZED_ACCESS';
export const SET_CHUNK_RESPONSE = 'SET_CHUNK_RESPONSE';
export const IS_MR_CHIP_ACTIVE = 'IS_MR_CHIP_ACTIVE';
export const SET_FILE_NAME_FOR_DOWNLOAD = 'SET_FILE_NAME_FOR_DOWNLOAD';
export const SET_FILTER_DROPDOWN = 'SET_FILTER_DROPDOWN';
export const SET_FILTERS_FOR_API = 'SET_FILTERS_FOR_API';

export function setUSerEmail(email: string): AnyAction {
  return {
    type: SET_USER_EMAIL,
    email
  };
}

export function addLoadingIndicator(name: string): AnyAction {
  return {
    type: ADD_LOADING_INDICATOR,
    value: name
  };
}

export function removeLoadingIndicator(name: string): AnyAction {
  return {
    type: REMOVE_LOADING_INDICATOR,
    value: name
  };
}

export function showPrivacyAndTermsModal(value: boolean, policyTypeList:any): AnyAction {
  return {
    type: SHOW_PRIVACY_AND_TERMS_MODAL,
    data: value,
    policyTypeList
  };
}

export function showUnauthorizedAccess(value: boolean): AnyAction {
  return {
    type: 'SHOW_UNAUTHORIZED_ACCESS',
    value
  };
}

export function setChunkResponse(text?: string): AnyAction {
  return {
    type: 'SET_CHUNK_RESPONSE',
    text
  };
}

export function triggerIsMRChipActive(value: boolean): AnyAction {
  return {
    type: IS_MR_CHIP_ACTIVE,
    value
  };
}
export function setFileNameForDownload(fileNames: string[]): AnyAction {
  return {
    type: 'SET_FILE_NAME_FOR_DOWNLOAD',
    fileNames
  };
}

export function setFilterDropDown(filterDropDownObj: {}): AnyAction {
  return {
    type: 'SET_FILTER_DROPDOWN',
    filterDropDownObj
  };
}

export function setFiltersForAPI(filtersData: {}, selectedSourceRadio: string): AnyAction {
  return {
    type: 'SET_FILTERS_FOR_API',
    filtersData,
    selectedSourceRadio
  }
}

