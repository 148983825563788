import { RESPONSE_BY } from "../configs/type/types";
import { Guid } from "guid-typescript";
import { getCurrentChatSessionId, initializeChatSession, setCurrentChatSessionId } from "../services/session.services";
import { setSelectedChatThread } from "../../../store/actions/openAiActions";
import { HttpStatusCode } from "../configs/base/enum";
import LABELS from "../configs/ui/en.json";

export const charLimit = 1000;

export const sanitizeHeaderText = (header: string) => {
  return header.replace("company_", "").replace("_", " ");
};

export const toPascalCase = (str: string) => {
  return str.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};

export const downloadFilenameGenerator = (
  from: RESPONSE_BY | "ALL",
  extension: "ppt" | "docx" | "csv" | 'zip', isEGPTOnly?: boolean
) => {
  const date = new Date();
  const timestamp = `${date.getDate().toString().padStart(2, '0')}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getFullYear()}${date.getHours().toString().padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date.getSeconds().toString().padStart(2, '0')}`;
  const source = "Market Reports"
  const fileName = `${source}-${timestamp}.${extension}`;
  return fileName;
};

export const handleSessionID = (selectedChatThread: any) => {
  if (selectedChatThread && selectedChatThread?.sessionId) {
    setCurrentChatSessionId(selectedChatThread?.sessionId);
  } else {
    initializeChatSession(true);
  }
};

export const getComputedRequestObject = (
  selectedChatThread: any,
  isEgptOnly: boolean,
  chatDataSummmary: any,
  firstResponseTime?: number,
  totalResponseTime?: number | null,
) => {
  let targetUserSummary = chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 1].user?.summary;
  let targetSEResponseSummary = JSON.stringify(
    chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 1].SEResponse
  );

  return {
    threadId: selectedChatThread?.threadId || 0,
    messageId: 0,
    title: targetUserSummary,
    dataSource: 6,
    dataSubSource: isEgptOnly? "Embryonic": "Embryonic, Open AI",
    sessionId: selectedChatThread?.sessionId || getCurrentChatSessionId(),
    question: targetUserSummary,
    response: targetSEResponseSummary,
    firstResponseTime : Number(firstResponseTime?.toFixed(2)),
    totalResponseTime : Number(totalResponseTime?.toFixed(2))
  };
};

export const IsJSONResponse = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const handleUserChatThreadHistory = (
  reqObj: any,
  savedNewThreadId: any,
  dispatch: any
) => {
  let savedChatThread: any = {};
  savedChatThread = {
    threadId: savedNewThreadId,
    sessionId: reqObj.sessionId,
    title: reqObj.title,
    isEditEnabled: false,
    isSelected: true,
    dataSource: reqObj.dataSource,
    dataSubSource: reqObj.dataSubSource
  };
  // let updatedChatThreads = [savedChatThread, ...userChatThreads];
  // let chatThreadsToBeDeleted = updatedChatThreads.slice(10, updatedChatThreads.length);
  // if (chatThreadsToBeDeleted?.length > 0) {
  //   const currentThread = chatThreadsToBeDeleted[0];
  //   setCurrentSessionId(reqObj.sessionId);
  //   deleteChatThreadHistory(currentThread?.threadId);
  //   const targetAPIURL = getCacheRemoveAPIURL(currentThread);
  //   if (targetAPIURL) removeThreadFromCache(targetAPIURL, currentThread.sessionId);
  // }
  dispatch(setSelectedChatThread(savedChatThread));
  // dispatch(saveUserChatThreadHistory(updatedChatThreads.slice(0, 10)));
  // dispatch(setLastSavedThreadID(savedNewThreadId));
};

export const getErrorLabel = (statusCode: number, isEGPTOnly: boolean) => {
  if (isEGPTOnly) {
    if (statusCode === HttpStatusCode.InternalServerError) return LABELS.chat_reply.NETWOR_ERROR_MSG;
    else 
      if (statusCode === HttpStatusCode.NotAcceptable)
      {
        return LABELS.chat_reply.NOT_ACCEPTABLE_ERROR;
      }
      else
      {
        return LABELS.chat_reply.EMBROYNIC_ERROR;
      }
  }
  else {
    if (statusCode === HttpStatusCode.InternalServerError) return LABELS.chat_reply.NETWOR_ERROR_MSG;
    else return LABELS.chat_reply.OPENAI_ERROR_MSG;
  }
};
