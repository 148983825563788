export enum ModalType {
  Error,
  Submit,
  Confirmation,
  Privacy,
  Warning,
  Terms,
  AdvancedSearch,
  Client,
  SourceChange,
  PrivacyHeaderChange,
  CreateNewProject,
  Feedback, 
  Preview
}
