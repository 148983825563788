/* eslint-disable no-case-declarations */
import { type LoopReducer, loop, Cmd, type Loop } from 'redux-loop';
import { type AnyAction } from 'redux';
import * as userProfileActions from '../actions/userProfileActions';
import { getUserProfile, fetchUserRoles } from '../../services/userProfile.service';
import * as userRolesActions from '../actions/userRolesActions';
import * as commonActions from '../actions/commonActions';
import { jwtDecode } from 'jwt-decode';
import { LOCAL_STORAGE } from '../../app.constant';

export interface IUserProfileState {
  userData: {
    displayName: string;
    email: string;
    isExternal: boolean;
    profileImage: string;
    sid: { preferredName: string; rawIdToken?: string };
    rawIdToken?: string;
    sharePointTokenForTeamsApp?: string;
  };
  userRoles: string[] | undefined;
  tenantId: string | undefined;
  region: string | undefined;
}

export const initialState: IUserProfileState = {
  userData: {
    displayName: '',
    email: '',
    isExternal: false,
    profileImage: '',
    sid: { preferredName: '' },
    sharePointTokenForTeamsApp: ''
  },
  userRoles: undefined,
  tenantId: undefined,
  region: undefined
};

export const userProfileReducer: LoopReducer<IUserProfileState> = (
  state: IUserProfileState = initialState,
  action: AnyAction
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
): IUserProfileState | Loop<IUserProfileState, AnyAction> => {
  switch (action.type) {
    case userProfileActions.FETCH_USER_PROFILE:
      return loop(
        {
          ...state
        },
        Cmd.list([
          Cmd.run(getUserProfile, {
            successActionCreator: userProfileActions.storeUserProfile,
            args: []
          })
        ])
      );
    case userRolesActions.FETCH_USER_ROLE:
      return loop(
        state,
        Cmd.list([
          Cmd.run(fetchUserRoles, {
            successActionCreator: userRolesActions.storeUserRole,
            failActionCreator: userRolesActions.fetchUserRoleFailure,
            args: []
          }),
          Cmd.action(commonActions.addLoadingIndicator(userRolesActions.FETCH_USER_ROLE))
        ])
      );

    case userProfileActions.STORE_USER_PROFILE:
      const sid = action.value?.idToken;
      const claims = action.value?.idToken?.claims || {};
      const { name, preferredUsername, acct } = claims;
      return {
        ...state,
        userData: {
          displayName: name || initialState?.userData?.displayName,
          email: preferredUsername || initialState?.userData?.email,
          isExternal: !!acct || initialState?.userData?.isExternal,
          profileImage: action.value?.image ? `data:image/png;base64, ${action.value?.image}` : '',
          sid: sid || ''
        }
      };
    
    default:
      return state;
  }
};
