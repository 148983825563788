import React, { useEffect, useState } from 'react';
import './SectorInsights.scss';
import Input from './Input';
import { MAX_TEXT_LENGTH } from '../../app.constant';
import { closeIcon, searchIconWhite } from '../../commonImage';

interface IDropdownHierarchyFilterProps {
  placeholder: string;
  filter?: (text: string) => void;
  setInputFilter: boolean;
}

export const DropdownHierarchyFilter = (props: IDropdownHierarchyFilterProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [filterTextPresent, setFilterTextPresent] = useState<boolean>(false);

  useEffect(() => {
    handleRemove();
  }, [props.setInputFilter]);
  const handleChange = (event: any) => {
    const inputValue: string = event.target.value;
    setInputValue(inputValue);
    if (inputValue && inputValue.length > 0) {
      setFilterTextPresent(true);
    } else {
      setFilterTextPresent(false);
    }
    props.filter && props.filter(inputValue);
  };

  const handleRemove = () => {
    if (inputValue.length > 0) {
      setInputValue('');
      props.filter && props.filter('');
      setFilterTextPresent(false);
    }
  };

  return (
    <div className='hierarchy-search-filter'>
      <Input
        value={inputValue}
        handleChange={handleChange}
        maxLength={MAX_TEXT_LENGTH}
        placeholder={props.placeholder}
        readOnly={false}
      />
      {filterTextPresent ? (
        <img onClick={handleRemove} className='se-close-icon' src={closeIcon} />
      ) : (
        <img className='fas fa-search' src={searchIconWhite} />
      )}
    </div>
  );
};

export default React.memo(DropdownHierarchyFilter);
