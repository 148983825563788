import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import scssVar from '../../../styles/variables.module.scss';
import ProfileCard from '../profileCard/ProfileCard';
import { profileMenuList } from '../../../app.constant';
import { useDispatch, useSelector } from 'react-redux';
import { type IStore } from '../../../store';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import { useNavigate } from 'react-router-dom';
const { darkwhite, white, grey15, grey50, fontInterstate } = scssVar;

const Header = (): JSX.Element => {
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const history = useNavigate();

  const homeIconClick = () => {
      history('/');
  };
  return (
    <>
        <StyleHeader>
          <header className='ie-header ie-header-fixed '>
            <Logo />
            <ErrorBoundary>
              <div onClick={(e) => e.stopPropagation()}>
                <ProfileCard
                  userData={userData}
                  profileMenuList={profileMenuList}
                  disableHelpMenu={userData?.isExternal}
                />
              </div>
            </ErrorBoundary>
          </header>
        </StyleHeader>
    </>
  );
};

const StyleHeader = styled.div<{ isSurveyDocOpen?: boolean }>`
  position: relative;
  .ie-header.ie-header-fixed {
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    margin: ${(props) => (props.isSurveyDocOpen ? 'auto' : 'auto')};
    background: #23232F;
    border-bottom: 1px solid #23232F;
  }
  .ie-header {
    display: block;
    padding: 0 0 0 0.625rem;
    z-index: 100;
    min-height: 3.5em;
  }
  header {
    .vl {
      border-left: 3px solid #d1ead1;
      height: 22px;
      padding: 8px;
      position: relative;
      top: 23px;
    }
    .logo-text {
      font-size: 18px;
      position: relative;
      left: 10px;
      top: 2px;
    }
    .ie-header .ie-header-app-name {
      position: relative;
      left: 5px;
      font-family: ${fontInterstate};
      font-size: 18px;
      line-height: 21px;
      color: ${darkwhite};
      height: auto;
      font-size: 18px;
      bottom: 9px;
    }
    .ie-header .ie-search-input-wrapper .ie-input-component .ie-input {
      background: ${grey15};
      height: 3.20714rem !important;
      position: relative;
      top: 6px;
    }
    .ie-header.ie-header-fixed {
      height: 56px;
    }
    .ie-header-logo {
      left: 12px;
      bottom: 5px;
    }

    .ie-header .ie-row {
      height: 100%;
      flex-basis: 100%;
    }
    .home-icon {
      position: relative;
      top: 10px;
      cursor: pointer;
    }
    @media screen and (min-width: 64em) {
      .ie-header-company-container {
        flex: 0 0 20%;
        max-width: 100%;
        box-sizing: border-box;
        box-flex: 0;
      }
    }
    @media screen and (min-width: 0) {
      .ie-between-xs {
        justify-content: flex-start;
      }
    }
    @media screen and (min-width: 48em) {
      .ie-header .ie-header-search {
        width: 64.85714rem;
      }
    }
    .ie-header-search-field {
      border-color: black;
    }
    .ie-header-icons-placeholder {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
`;

export default Header;