import React, { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import {
  MIN_SYMBOLS_TO_START_SEARCHING,
  contentAge,
} from '../../app.constant';
import './MarketReportsHome.scss';
import { getMRSectorsHierarchy } from '../../services/chat.service';
import MarketReportsInsightHeader from './MarketReportsInsightHeader';
import { useDispatch, useSelector } from "react-redux";
import { setFilterDropDown } from '../../store/actions/commonActions';

interface IOwnProps {
  checkedsector: any;
  setCheckedsector: any;
  checkedSubsector: any;
  setCheckedSubSector: any;
  checkedgeography: any;
  setCheckedgeography: any;
  checkedReportType: any;
  setCheckedReportType: any;
  selectedContentAge: any;
  setSelectedContentAge: any;
  selectedSourceRadio: any;
  setSelectedSourceRadio: any;
  onClickSearchbutton: any;
 }

const MarketReportsHome: React.FC<IOwnProps> = ({
  checkedsector,
  setCheckedsector,
  checkedSubsector,
  setCheckedSubSector,
  checkedgeography,
  setCheckedgeography,
  checkedReportType,
  setCheckedReportType,
  selectedContentAge,
  setSelectedContentAge,
  selectedSourceRadio,
  setSelectedSourceRadio,
  onClickSearchbutton
}) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();


  const [sector, setSector] = useState<any[]>([]);
  const [subSector, setsubSector] = useState<any[]>([]);
  const [reports, setReportType] = useState<any[]>([]);
  const [geography, setGeography] = useState<any[]>([]);
  const [allsector, setAllsector] = useState<any[]>([]);
  const [allReports, setAllReportType] = useState<any[]>([]);

  const [allgeography, setAllgeography] = useState<any[]>([]);
  const [allsectorChilds, setAllsectorChilds] = useState<any[]>([]);

  const [sectorenable, setSectorenable] = useState<string>('none');
  const [subSectorEnable, setSubSectorEnable] = useState<string>('none');
  const [geographyenable, setGeographyenable] = useState<string>('none');
  const [reportEnable, setReportEnable] = useState<string>('none');


  let filteredData: Array<any> = [];
  let filteredlabels: Array<string> = [];

  const extracSourcesValues = (sources: any) => {
    const sourcesWithoutChildred = sources.map((source: any) => {
      return {
        label: source.label,
        value: source.value,
        children: null
      };
    });
    return sourcesWithoutChildred;
  };

  const getHierarchy = async () => {
    try {
      const data = await getMRSectorsHierarchy();
      if (selectedSourceRadio === 'IBIS World') {
        setSector(extracSourcesValues(data.IBIS['sector']));
        setAllsector(extracSourcesValues(data.IBIS['sector']));
        setGeography(data.IBIS['geography']);
        setAllgeography(data.IBIS['geography']);
        setAllsectorChilds(data.IBIS['sector']);
      } else if (selectedSourceRadio === 'TBRC') {
        setSector(extracSourcesValues(data.TBRC['sector']));
        setReportType(data.TBRC['reportType']);
        setAllReportType(data.TBRC['reportType']);
        setAllsector(extracSourcesValues(data.TBRC['sector']));
      }
    } catch (error) {
      console.error('Error fetching hierarchy data:', error);
    }
  };

  const toggleSector = () => {
    if (sectorenable == 'block') {
      setSectorenable('none');
    } else {
      setSectorenable('block');
    }
    setSubSectorEnable('none');
    setGeographyenable('none');
    setReportEnable('none');
  };

  const toggleReportType = () => {
    if (reportEnable == 'block') {
      setReportEnable('none');
    } else {
      setReportEnable('block');
    }
    setSectorenable('none');
  };

  const toggleSubSector = () => {
    if (subSectorEnable == 'block') {
      setSubSectorEnable('none');
    } else {
      setSubSectorEnable('block');
    }
    setSectorenable('none');
    setGeographyenable('none');
  };

  const toggleGeography = () => {
    if (geographyenable == 'block') {
      setGeographyenable('none');
    } else {
      setGeographyenable('block');
    }
    setSectorenable('none');
    setSubSectorEnable('none');
  };

  const setContentAge = (val: any) => {
    setTimeout(() => {
      setSelectedContentAge(val);
    }, 100);
  };

  const filterSectorNodes = (text: string) => {
    const filteredNodes: any[] =
      text.length >= MIN_SYMBOLS_TO_START_SEARCHING
        ? filterData(text, sector)
        : defaultData(allsector);
    setSector(filteredNodes);
    if (text.length < 2) {
      setSector(allsector);
    }
  };

  const filterSubSectorNodes = (text: string) => {
    const filteredNodes: any[] =
      text.length >= MIN_SYMBOLS_TO_START_SEARCHING
        ? filterData(text, subSector)
        : defaultData(subSector);
    setsubSector(filteredNodes);
    if (text.length < 2) { setsubSector(subSector) };
  };

  const filterReportTypeNodes = (text: string) => {
    const filteredNodes: any[] =
      text.length >= MIN_SYMBOLS_TO_START_SEARCHING
        ? filterData(text, reports)
        : defaultData(reports);
    setReportType(filteredNodes);
    if (text.length < 2) {
      setReportType(allReports)
    };
  };

  const filterGeographyNodes = (text: string) => {
    const filteredNodes: any[] =
      text.length >= MIN_SYMBOLS_TO_START_SEARCHING
        ? filterData(text, geography)
        : defaultData(allgeography);
    setGeography(filteredNodes);
    if (text.length < 2) setGeography(allgeography);
  };

  const filterData = (value: string, nodes: Array<any>) => {
    nodes.filter((node: any) => {
      if (node?.label?.toLowerCase().includes(value.toLowerCase())) {
        const lebels: Array<string> = extractAllNodes(node);
        if (!haveCommonElements(filteredlabels, lebels)) {
          filteredData.push(node);
          filteredlabels.push(...lebels);
        }
      }
      if (node.children) {
        filterData(value, node.children);
      }
    });
    return filteredData;
  };

  function haveCommonElements(array1: Array<string>, array2: Array<string>) {
    return array1.some((element) => array2.includes(element));
  }

  const extractAllNodes = (allNodes: any) => {
    let childrenArray: Array<any> = [];
    allNodes &&
      allNodes.children &&
      allNodes.children.length > 0 &&
      allNodes.children.map((currentEntity: any) => {
        if (currentEntity.children) {
          const recursiveChildren: Array<string> =
            extractAllCheckedChildrenRecursive(currentEntity);
          childrenArray = [...childrenArray, ...recursiveChildren];
        }
        childrenArray = [...childrenArray, currentEntity.value];
      });
    childrenArray.push(allNodes.value);
    return childrenArray;
  };

  const extractAllCheckedChildrenRecursive = (entity: any): Array<string> => {
    let allChildren: Array<any> = [];
    const children: Array<any> = entity.children;
    children &&
      children.map((currentEntity: any) => {
        if (currentEntity.children) {
          const recursiveChildren: Array<any> = extractAllCheckedChildrenRecursive(currentEntity);
          allChildren = [...allChildren, ...recursiveChildren];
        }
        allChildren.push(currentEntity.value);
      });
    return allChildren;
  };

  const defaultData = (nodes: Array<string>) => {
    return nodes;
  };

  useEffect(() => {
    if (selectedSourceRadio) {
      getHierarchy();
    }
  }, [selectedSourceRadio]);

  const setSourceRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ids = (event.target as HTMLInputElement)?.id;
    setCheckedsector([]);
    setCheckedSubSector([]);
    setCheckedReportType([]);
    setCheckedgeography([]);
    setSelectedContentAge('');
    clsoeOtherDropdowns();
    setSelectedSourceRadio(ids);
  };

  const clsoeOtherDropdowns = () => {
    setGeographyenable('none');
    setSectorenable('none');
    setSubSectorEnable('none');
    setReportEnable('none');
  };

  useEffect(() => {
    let subSectorNodes: any = [];
    if (sector && sector.length > 0 && selectedSourceRadio === 'IBIS World') {
      checkedsector.forEach((element: any) => {
        allsectorChilds.forEach((subElement: any) => {
          if (element === subElement.label) {
            subSectorNodes.push(...subElement?.children);
          }
        });
      });
      setsubSector(subSectorNodes);
    }
  }, [checkedsector]);

  return (
    <StyleNewChatComponent
      ref={dropdownRef}>
      <Fragment>
        <MarketReportsInsightHeader
          length={length}
          contentAge={contentAge}
          selectedContentAge={selectedContentAge}
          sectorenable={sectorenable}
          subSectorEnable={subSectorEnable}
          geographyenable={geographyenable}
          reportEnable={reportEnable}
          sector={sector}
          subSector={subSector}
          reports={reports}
          geography={geography}
          checkedgeography={checkedgeography}
          checkedsector={checkedsector}
          checkedSubsector={checkedSubsector}
          checkedReportType={checkedReportType}
          selectedSourceRadio={selectedSourceRadio}
          filterSectorNodes={filterSectorNodes}
          filterSubSectorNodes={filterSubSectorNodes}
          filterReportTypeNodes={filterReportTypeNodes}
          filterGeographyNodes={filterGeographyNodes}
          setCheckedsector={setCheckedsector}
          setCheckedSubSector={setCheckedSubSector}
          setCheckedReportType={setCheckedReportType}
          setCheckedgeography={setCheckedgeography}
          setContentAge={setContentAge}
          clsoeOtherDropdowns={clsoeOtherDropdowns}
          toggleReportType={toggleReportType}
          toggleSector={toggleSector}
          toggleSubSector={toggleSubSector}
          toggleGeography={toggleGeography}
          setSourceRadio={setSourceRadio}
          onClickSearchbutton={onClickSearchbutton}
        />
      </Fragment>
    </StyleNewChatComponent>
  );
};

const StyleNewChatComponent = styled.div<{
}>`
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28vh;
    margin-top: 10%;
  }

  .responsive-image {
    max-width: 100%;
    height: auto;
  }
  .breadcrumbOpen {
    margin-left: 2%;
    margin-top: 3vh;
    @media screen and (min-width: 1000px) and (max-width: 1250px) {
      margin-left: 16px;
    }
    @media screen and (min-width: 1250px) and (max-width: 1370px) {
      margin-left: 22px;
    }
    @media screen and (min-width: 1370px) and (max-width: 1550px) {
      margin-left: 29px;
    }
    @media screen and (min-width: 1550px) and (max-width: 1750px) {
      margin-left: 40px;
    }
  }
  .breadcrumb {
    margin-left: 3%;
    margin-top: 3vh;
    @media screen and (min-width: 1000px) and (max-width: 1250px) {
      margin-left: 29px;
    }
    @media screen and (min-width: 1370px) and (max-width: 1550px) {
      margin-left: 41px;
    }
    @media screen and (min-width: 1550px) and (max-width: 1750px) {
      margin-left: 51px;
    }
  }
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .input-bar {
    width: 100%;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #c3c3cb;
  }
  .warning {
    color: red;
    font-size: 12px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    position: relative;
    top: 2px;
    margin-left: 4%;
  }
  .question-card {
    width: 15%;
    height: 100px;
    background-color: #f9f9fa;
    flex: 0.5;
    margin: 0 10px 0 30px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    padding: 1%;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  .question-card: hover {
    background-color: #f3f3f5;
    cursor: pointer;
  }

  .card-container {
    display: flex;
    justify-content: space-between;
  }

  .motif-label {
    font-size: 18px;
    font-weight: 400;
    color: #2e2e38;
    text-align: center;
    position: relative;
  }

  .chat-page {
    display: flex;
    justify-content: flex-end !important;
    width: 100%;
  }

  .number-validate {
    color: #747480;
    position: relative;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
  }
  .query-text-length {
    text-align: right;
  }
  .warning-length {
    width: 100%;
    display: flex;
    color: #747480;
  }
  .warning {
    color: red;
    font-size: 12px;
    width: 100%;
  }
  .send-btn {
    position: relative;
    top: -10px;
  }
  .font-family {
    font-family: 'EYInterstate';
  }
`;

export default MarketReportsHome;
