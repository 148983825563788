import { IRelevantFiles } from "../../../../models/IQueryType";

export enum RESPONSE_TYPE {
  ANSWER = "answer",
  TABLE = "table",
  ERROR = "error",
}

export type IContent = {
  [key: string]: string;
};

export type IQueryContent = {
  Content?: IContent[];
  ResponseType?: RESPONSE_TYPE;
  Sources?: string[];
  summary: string;
  downloadUrl?: string;
  sqlQuery?: string;
  showTable?: boolean;
  showSummary?: boolean;
  suggestions?: { followup: any; download: any, portfolio: any, sector: any };
  isChunkLoading?: boolean;
  relevantFiles?: IRelevantFiles[];
  responseType?: string;
  isCompletelyAbortedResposne?: boolean;
  isParitallyAbortedResponse?: boolean;
};

export type IQuery = {
  [key: string]: IQueryContent | User ;
};

export enum RESPONSE_BY {
  USER = "user",
  OPENAI = "LLMResponse",
  STRATEGY_EDGE = "SEResponse",
  EMBROYNIC = "E-GPT"
}

export interface User {
  summary: string;
}

export type ItableCntentProps = {
  val: string | number;
  title: string | undefined | number;
};
