import React, { useEffect, useState } from "react";
import { Link, useNavigate as useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Close_icon from "../../assets/close_icon.svg";
import CHAT_ICON from "../../../../assets/images/chatIcon.svg";

// import SeLogo from "../../assets/se_logo.svg";
import LinkButton from "../LinkButton/LinkButton";
import StrategyEdge from "../../pages/strategy-edge";
import "../../OpenAi.css";
import {
  clearChatHistory,
  setSelectedChatThread,
  toggleGPTSidebar,
  toggleLoader,
} from "../../../../store/actions/openAiActions";
import sassVars from "../../../../styles/variables.module.scss";
import "./OpenAISidebar.scss";
import styled from "styled-components";
import { closeIcon } from "../../../../app.constant";
// import DataSourceToggleModal from "../Modal/DataSourceToggleModal";
import AIDisclaimerText from "../../../../components/common/AIDisclaimerText";
import DASHBOARD_IMG from "../../../../assets/images/dashboard.svg";
// import { useNavigate } from "react-router-dom";

const { alternateblack, gray40 } = sassVars;

const examples = [
  "What is Unilever's investment strategy in Asia?",
  "What is the supply chain for P&G?",
  "Can you provide a list of companies in eMobility with revenues more than $200m?",
];

interface IOpenAISidebar {
  isSideBarOpen: boolean;
  toggleSidebar: () => void;
}

const OpenAISidebar = (props: IOpenAISidebar) => {
  const { isSideBarOpen, toggleSidebar } = props;

  const [showSidebar, setShowSidebar] = useState(false);
  const dispatch = useDispatch();

  const seQuery = useSelector(
    (state: any) => state.openAiChat.chatDataSummmary.table || []
  );

  // const { isSideBarOpen } = useSelector(
  //   (state: any) => state.openAiChat
  // );
  const isLoading = useSelector((state: any) => state.openAiChat.isLoading);
  const isOpenaiSideBarOpen = useSelector(
    (state: any) => state.openAiChat.isSideBarOpen
  );
  const isCompanyProfilePage = useSelector(
    (state: any) => state.common.isCompanyProfilePage
  );
  const isCompetitiveAnalysisLandingPage = useSelector(
    (state: any) => state.common.isCompetitiveAnalysisLandingPage
  );
  const [isEGPTOnly, setIsEGPTOnly] = useState(true);
  const [isShowWarningModal, setIsShowWarningModal] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(String);
  const [queryText, setQueryText] = useState(String);

  const confirmDataSourceToggleHandler = () => {
    setIsShowWarningModal(false);
    dispatch(clearChatHistory());
    dispatch(toggleLoader(false));
    dispatch(setSelectedChatThread({}));
    if (selectedRadio === "no") {
      setIsEGPTOnly(false);
    } else {
      setIsEGPTOnly(true);
    }
  };

  const cancelDataSourceToggleClosingHandler = () => {
    setIsShowWarningModal(false);
  };

  const onConvoChange = (e: any) => {
    if (seQuery.length > 0) {
      setSelectedRadio(e.target.value);
      setIsShowWarningModal(true);
    } else {
      setIsShowWarningModal(false);
      const selection = e.target.value;
      if (selection === "no") {
        setIsEGPTOnly(false);
      } else {
        setIsEGPTOnly(true);
      }
    }
  };
  const handleQuerySelection = (query: React.SetStateAction<string>) => {
    setQueryText(query);
  };

  useEffect(() => {
    setQueryText(queryText);
  }, [queryText]);

  return (
    <StyleOpeanAI
      isOpenaiSideBarOpen={isOpenaiSideBarOpen}
      isCompanyProfilePage={isCompanyProfilePage}
      isCompetitiveAnalysisLandingPage={isCompetitiveAnalysisLandingPage}
      seQuery={seQuery}
    >
      {isSideBarOpen && <div className="overlay"></div>}
      <div className={isSideBarOpen ? "sidebar " : "sidebar active"}>
        <div className="header">
          <div className="header-left">
            <img src={CHAT_ICON} alt="" className="chatIconImage" />
            <div className="chatText">Chat with selected reports</div>
          </div>
          <div className="header-right">
            <div
              className="back-link"
              style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
            ></div>
            <LinkButton onClick={toggleSidebar}>
              <img src={Close_icon} />
            </LinkButton>
          </div>
        </div>
        {!seQuery.length &&
        <div className="dashboard">
          <img src={DASHBOARD_IMG} alt="" className="dashboard-img"/>
          <div className="askAnything">Ask anything related to reports</div>
        </div>}
       
        <div
          className={
            isSideBarOpen
              ? "sidebar-small-container"
              : "sidebar-chatbox-container"
          }
          style={{ overflow: seQuery.length ? "hidden" : "inherit" }}
        >
          <StrategyEdge isEGPTOnly={isEGPTOnly} tryQueryText={queryText} />
        </div>
        {!seQuery.length && <AIDisclaimerText />}

        
      </div>

      {/* {isShowWarningModal && (
        <DataSourceToggleModal
          headerIcon={
            <img
              onClick={cancelDataSourceToggleClosingHandler}
              className="se-close"
              src={closeIcon}
            />
          }
          onClose={cancelDataSourceToggleClosingHandler}
          onConfirmToggleHandler={confirmDataSourceToggleHandler}
          show={isShowWarningModal}
        />
      )} */}
    </StyleOpeanAI>
  );
};
export default OpenAISidebar;

const StyleOpeanAI = styled.div<{
  isOpenaiSideBarOpen: boolean;
  isCompanyProfilePage: boolean;
  isCompetitiveAnalysisLandingPage: boolean;
  seQuery: Array<any>;
}>`
  .sidebar-small-container {
    height: ${(props) => (props.seQuery.length ? "100%" : "15%")} !important;
  }
  .toggle-button {
    right: ${(props) => (!props.isOpenaiSideBarOpen ? "100%" : "98%")};
    top: ${(props) =>
      ((props.isCompanyProfilePage || props.isCompetitiveAnalysisLandingPage) &&
        "10.2rem")};
  }
  input.disabled-radio {
    pointer-events: none;
    opacity: 0.5;
  }
  .convo-style-box {
    margin-bottom: ${(props) =>
      !props.seQuery.length ? "2.5rem" : "0.8rem"} !important;
  }
`;
