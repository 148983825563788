import React from "react";
import { useSelector } from "react-redux";
import SendIcon from "../../assets/send.svg";
import LinkButton from "../LinkButton/LinkButton";
import LoadingIndicator from "../LoadingIndicator";
import { charLimit } from "../../utils";
import "./InputBox.scss";
import SuggestionText from "../../../common/errorPages/ErrorSuggestion";
type InputBoxProps = {
  queryText: string;
  selectedSegment?: boolean;
  refInput: React.RefObject<HTMLTextAreaElement>;
  hasQueries: boolean;
  handleOnChange: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSendMessage: () => void;
  handleResetMessage: () => void;
  handleKeyDown: (ev: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  disabled: boolean;
};

const InputBox = ({
  selectedSegment,
  queryText = "",
  refInput,
  handleOnChange,
  handleSendMessage,
  handleResetMessage,
  handleKeyDown,
  hasQueries,
  disabled,
}: InputBoxProps) => {
  const {
    isClearLoading,
    downloadFileMessage,
    errorMessage,
    isSideBarOpen,
  } = useSelector((state: any) => state.openAiChat);
  // const isSideBarOpen = useSelector((state: State) => state.isSideBarOpen);
  const INPUT_PLACEHOLDER = "Ask a question";
  const TEXT_HEIGHT = queryText.length > 150 ? 60 : "auto";
  const labelClass = selectedSegment ? "chatbox__label" : "chatbox__label-dark";
  const seQuery = useSelector(
    (state: any) => state.openAiChat.chatDataSummmary.table || []
  );
  return (
    <div className={"chatbox"}>
      <div className={"chatbox__text-area-container"}>
        <textarea
          ref={refInput}
          className={
            !isSideBarOpen
              ? "chatbox__text-area"
              : "chatbox__text-area sidebar-open"
          }
          value={queryText}
          onChange={handleOnChange}
          rows={1}
          placeholder={INPUT_PLACEHOLDER}
          maxLength={charLimit}
          style={{
            height: TEXT_HEIGHT,
            cursor: disabled ? "not-allowed" : "default",
          }}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <div onClick={handleSendMessage} className="chatbox__send-icon">
          <span style={{ cursor: disabled ? "not-allowed" : "default" }}>
            <img src ={SendIcon} />
          </span>
        </div>
      </div>

      <div className="chatbox__char-limit">
        <div className={"number-validate"}>
          {seQuery.length !== 0 && <SuggestionText />}
        </div>
        <div className={"query-length"}>
          {" "}
          ({queryText.length}/{charLimit})
        </div>
      </div>

      {hasQueries && (
        <>
          <div className="chatbox__clearbtn-box">
            {!isClearLoading && !downloadFileMessage && (
              <LinkButton
                onClick={handleResetMessage}
                disabled={disabled || isClearLoading}
                className="chatbox__clearbtn"
              >
                Clear chat
              </LinkButton>
            )}
            {isClearLoading && <LoadingIndicator color="#FFF" />}
            <span className="chatbox__clearbtn-box">
              {(errorMessage || downloadFileMessage) && (
                <p className="chatbox__download-message">
                  {errorMessage || downloadFileMessage}
                  <LoadingIndicator
                    color="#FFF"
                    parentWidth="4px"
                    zeroPercentheight="4px"
                    fifltyPercentheight="4px"
                    thirdDotSpace={"19px"}
                    secDotSpace={"13px"}
                  />
                </p>
              )}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
export default InputBox;
