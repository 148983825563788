import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CustomDropdown from './CustomDropdown';
import scssVar from '../../styles/variables.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import './MarketReportsInsights.scss';
import { mrDataSources } from '../../app.constant';
import RadioButton from '../motifComponents/radioButton/RadioButton';
import { MotifChip } from '@ey-xd/motif-react';
import { closeIcon } from '../../commonImage';
import CustomFilterDropdown from './CustomFilterDropdown';
import { triggerIsMRChipActive } from '../../store/actions/commonActions';

const { darkwhite, gray15, alternategray, fontInterstate } = scssVar;

const MarketReportsInsightHeader = ({
  toggleSector,
  toggleReportType,
  toggleSubSector,
  toggleGeography,
  contentAge,
  setContentAge,
  selectedContentAge,
  sectorenable,
  subSectorEnable,
  reportEnable,
  filterSectorNodes,
  filterSubSectorNodes,
  filterReportTypeNodes,
  sector,
  subSector,
  reports,
  setCheckedsector,
  setCheckedSubSector,
  setCheckedReportType,
  geographyenable,
  filterGeographyNodes,
  geography,
  setCheckedgeography,
  clsoeOtherDropdowns,
  hideShowOptions,
  checkedgeography,
  checkedsector,
  checkedSubsector,
  checkedReportType,
  hasDefaultChecks,
  defaultExpanded,
  setSourceRadio,
  selectedSourceRadio,
  onClickSearchbutton
}: any) => {
  const [geographyItem, setGeopgraphyItems] = useState(false);
  const [sectorItem, setSectorItems] = useState(false);
  const [subSectorItem, setSubSectorItem] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const [isPopupVisibleGeography, setIsPopupVisibleGeography] = useState(false);
  const [isPopupVisibleSector, setIsPopupVisibleSector] = useState(false);
  const [isPopupVisibleSubSector, setIsPopupVisibleSubSector] = useState(false);
  const [isPopupVisibleReport, setIsPopupVisibleReport] = useState(false);
  const [screenWidth, setScreenWidth] = useState<any>();

  const [reportItem, setReportItem] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [isInputSectorCleared, setIsInputSectorCleared] = useState(false);
  const [isInputSubSectorCleared, setIsInputSubSectorCleared] = useState(false);
  const [isInputRepotTypeCleared, setIsInputRepotTypeCleared] = useState(false);

  const [isInputGeographyCleared, setIsInputGeographyCleared] = useState(false);
  const dispatch = useDispatch();
  const clearGeographyItems = () => {
    setGeopgraphyItems(!geographyItem);
    isInputGeographyCleared ? setIsInputGeographyCleared(false) : setIsInputGeographyCleared(true);
  };
  const dropdownRef = useRef<HTMLElement>(null);

  const clearSectorItems = () => {
    setSectorItems(!sectorItem);
    isInputSectorCleared ? setIsInputSectorCleared(false) : setIsInputSectorCleared(true);
  };

  const clearSubSectorItems = () => {
    setSubSectorItem(!subSectorItem);
    isInputSubSectorCleared ? setIsInputSubSectorCleared(false) : setIsInputSubSectorCleared(true);
  };

  const clearReportType = () => {
    setReportItem(!reportItem);
    isInputRepotTypeCleared ? setIsInputRepotTypeCleared(false) : setIsInputRepotTypeCleared(true);
  };

  const toggleSectorDrops = () => {
    setShowOptions(false);
    toggleSector?.();
  };

  const toggleSubSectorDrops = () => {
    setShowOptions(false);
    toggleSubSector?.();
  };

  const toggleReportDropDown = () => {
    setShowOptions(false);
    toggleReportType?.();
  };

  const toggleGeogDrops = () => {
    toggleGeography?.();
    setShowOptions(false);
  };

  const closeGeaographyFilter = () => {
    toggleGeography?.();
    isInputGeographyCleared ? setIsInputGeographyCleared(false) : setIsInputGeographyCleared(true);
  };

  const closeSectorFilter = () => {
    toggleSector?.();
    isInputSectorCleared ? setIsInputSectorCleared(false) : setIsInputSectorCleared(true);
  };

  const closeSubSectorFilter = () => {
    toggleSubSector?.();
    isInputSubSectorCleared ? setIsInputSubSectorCleared(false) : setIsInputSubSectorCleared(true);
  };

  const closeReportTypeFilter = () => {
    toggleReportType?.();
    isInputRepotTypeCleared ? setIsInputRepotTypeCleared(false) : setIsInputRepotTypeCleared(true);
  };

  const clearAllFilter = () => {
    if (!defaultExpanded?.length) {
      clearGeographyItems();
    }
    setGeopgraphyItems(!geographyItem);
    setContentAge('');
    setSectorItems(!sectorItem);
    setSubSectorItem(!subSectorItem);
    setShowOptions(false);
    setReportItem(!reportItem);
    clsoeOtherDropdowns?.();
    isInputGeographyCleared ? setIsInputGeographyCleared(false) : setIsInputGeographyCleared(true);
    isInputSectorCleared ? setIsInputSectorCleared(false) : setIsInputSectorCleared(true);
    isInputSubSectorCleared ? setIsInputSubSectorCleared(false) : setIsInputSubSectorCleared(true);
    isInputRepotTypeCleared ? setIsInputRepotTypeCleared(false) : setIsInputRepotTypeCleared(true);
  };

  const toggleDropdown = () => {
    clsoeOtherDropdowns?.();
    setShowOptions(!showOptions);
  };

  const clearDropdownValues = () => {
    setShowOptions(!showOptions);
    setContentAge('');
  };

  useEffect(() => {
    setShowOptions(false);
  }, [hideShowOptions]);

  const getDisableMode = useCallback(() => {
    if (selectedContentAge !== '') {
      return false;
    } else if (checkedgeography.length) {
      return false;
    } else if (checkedsector.length) {
      return false;
    } else if (checkedSubsector.length) {
      return false;
    } else if (checkedReportType.length) {
      return false;
    }
    return true;
  }, [checkedsector, checkedSubsector, checkedgeography, selectedContentAge, checkedReportType]);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
        clsoeOtherDropdowns?.();
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSourceRadio = (e: any) => {
    clearAllFilter();
    setSourceRadio?.(e);
  };

  const handleRemoveChip = (selectedChipList: any, chipToRemove: string, flag: string) => {
    const newChipList = selectedChipList.filter((chip: any) => chip !== chipToRemove);
    if (flag === "geography") {
      setCheckedgeography(newChipList);
    }
    if (flag === "sector") {
      setCheckedsector(newChipList);
      setCheckedSubSector([]);
    }
    if (flag === "subSector") {
      setCheckedSubSector(newChipList);
    }
    if (flag === "reportType") {
      setCheckedReportType(newChipList);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef?.current && !popupRef?.current?.contains(event.target)) {
        setIsPopupVisibleGeography(false);
        setIsPopupVisibleSector(false);
        setIsPopupVisibleSubSector(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (checkedgeography.length > 0 || checkedsector.length > 0 || checkedSubsector.length > 0 || checkedReportType.length > 0 || selectedContentAge !== '') {
      dispatch(triggerIsMRChipActive(true));
    }
    else {
      dispatch(triggerIsMRChipActive(false));
    }
    return () => {
      dispatch(triggerIsMRChipActive(false));
    }
  }, [checkedgeography, checkedsector, checkedSubsector, checkedReportType, selectedContentAge]);

  const setIsPopupVisibleFunc: any = (flag: any) => {
    if (flag === "geography") {
      setIsPopupVisibleGeography(!isPopupVisibleGeography);
    }
    if (flag === "sector") {
      setIsPopupVisibleSector(!isPopupVisibleSector);
    }
    if (flag === "subSector") {
      setIsPopupVisibleSubSector(!isPopupVisibleSubSector);
    }
    if (flag === "reportType") {
      setIsPopupVisibleReport(!isPopupVisibleReport);
    }
  }

  const renderChips = (selectedItems: any, flag: any) => {
    let chipLength = screenWidth > 1600 ? 2 : 1
    if (!selectedItems) {
      return null;
    }
    const chipsToDisplay = selectedItems.slice(0, chipLength);
    const additionalChipsCount = selectedItems.length - chipLength;

    let isPopupVisible: any =
      flag === "geography" ? isPopupVisibleGeography :
        flag === "sector" ? isPopupVisibleSector :
          flag === "subSector" ? isPopupVisibleSubSector :
            isPopupVisibleReport;

    return (
      <>
        {chipsToDisplay.map((chip: any, index: any) => (
          <MotifChip
            className='motif-chip'
            key={index}
            title={chip}
          >
            <span className="chip-text">{chip}</span>
            <span className="chip-close" onClick={() => handleRemoveChip(selectedItems, chip, flag)}>
              <img src={closeIcon} alt="close-icon" />
            </span>
          </MotifChip>
        ))}
        {additionalChipsCount > 0 && (
          <div className="more-chips-container">
            <div
              className="more-chips"
              onClick={() => setIsPopupVisibleFunc(flag)}
            >
              +{additionalChipsCount} more
            </div>
            {isPopupVisible && (
              <div className="popup" ref={popupRef}>
                {selectedItems.slice(1).map((chip: any, index: any) => (
                  <MotifChip
                    className='motif-chip'
                    key={index}
                    title={chip}
                  >
                    <span className="chip-text">{chip}</span>
                    <span className="chip-close" onClick={() => handleRemoveChip(selectedItems, chip, flag)}>
                      <img src={closeIcon} alt="close-icon" />
                    </span>
                  </MotifChip>
                ))}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const renderChipsForContentAge = (selectedItems: any) => {
    if (!selectedItems) {
      return null;
    }
    let chipsToDisplay = "";
    if (selectedItems < 1) {
      chipsToDisplay = `Last 6 months`
    } else {
      if (selectedItems === 1) {
        chipsToDisplay = `${selectedItems} year`
      } else {
        chipsToDisplay = `${selectedItems} years`
      }
    }
    return (
      <MotifChip
        className='motif-chip'
        title={selectedItems}
        onClose={() => { }}
      >
        {chipsToDisplay}
        <span className="chip-close" onClick={() => setContentAge("")}>
          <img src={closeIcon} alt="close-icon" />
        </span>
      </MotifChip>
    );
  };

  useEffect(() => {
    if (selectedSourceRadio) {
      clearSectorItems();
      clearSubSectorItems();
      clearGeographyItems();
      clearReportType();
      clearDropdownValues();
    }
  }, [selectedSourceRadio]);

  return (
    <StyleHeader
      buttonColor={getDisableMode()}
      sectorenable={sectorenable}
      geographyenable={geographyenable}
      subSectorEnable={subSectorEnable}
      screenWidth={screenWidth}
      reportEnable={reportEnable}>
      <div className='insight-header insight-header-fixed insight-header-has-search'>
        <div className={'sectorChatContainer__header-container'}>
          <div className='insight-header__radios'>
            Select Data Source:
            <div className='insight-header__radios__radio'>
              {mrDataSources &&
                mrDataSources.map((item: any) => {
                  return (
                    <>
                      <RadioButton
                        type='radio'
                        id={`${item.value}`}
                        name={item.value}
                        value={selectedSourceRadio}
                        checked={selectedSourceRadio === item.value}
                        onChange={handleSourceRadio}
                        className={`motif-radio-button button-position ${item.value !== "TBRC" ? "radio-button-disabled" : ""}`}
                        disabled={item.value !== "TBRC"}
                      />
                      <label htmlFor={`${item.value}`} className={`radio-text ${item.value !== "TBRC" ? "radio-button-disabled" : ""}`}>
                        {item.label}
                      </label>
                    </>
                  );
                })}
            </div>
          </div>
          {selectedSourceRadio !== 'All' ? (
            <div className='insight-dropdowns'>
              <div className='dropdowns' ref={dropdownRef as React.RefObject<HTMLDivElement>}>
                {selectedSourceRadio === 'IBIS World' && (
                  <div className='dropdown-parent'>
                    <CustomFilterDropdown
                      placeholder={'Geography'}
                      title={'Geography'}
                      customCss={'geography-display'}
                      toggleSelectedSource={toggleGeogDrops}
                      checkedSource={checkedgeography}
                      filter={filterGeographyNodes}
                      setInputFilter={isInputGeographyCleared}
                      treeNode={geography}
                      setCheckBoxValue={setCheckedgeography}
                      setClearFilter={geographyItem}
                      customCondition={
                        geography &&
                        geography
                          .filter((item: { disabled: boolean }) => item.disabled)
                          .includes(true)
                      }
                      hasDefaultChecks={hasDefaultChecks}
                      defaultExpanded={defaultExpanded}
                      clearAll={clearGeographyItems}
                      closeFilter={closeGeaographyFilter}
                      checkedNodes={checkedgeography}
                    />
                    <div className="chips">
                      {checkedgeography.length > 0 && renderChips(checkedgeography, "geography")}
                    </div>
                  </div>
                )}

                <div className='dropdown-parent'>
                  <CustomFilterDropdown
                    placeholder={'Sectors'}
                    title={'Sectors'}
                    customCss={'sector-display'}
                    toggleSelectedSource={toggleSectorDrops}
                    checkedSource={checkedsector}
                    filter={filterSectorNodes}
                    setInputFilter={isInputSectorCleared}
                    treeNode={sector || []}
                    setCheckBoxValue={setCheckedsector}
                    setClearFilter={sectorItem}
                    clearAll={clearSectorItems}
                    closeFilter={closeSectorFilter}
                    checkedNodes={checkedsector}
                  />
                  <div className="chips">
                    {checkedsector.length > 0 && renderChips(checkedsector, "sector")}
                  </div>
                </div>

                {selectedSourceRadio === 'TBRC' && (
                  <div className='dropdown-parent'>
                    <CustomFilterDropdown
                      placeholder={'Report type'}
                      title={'Report type'}
                      customCss={'reporttype-display'}
                      toggleSelectedSource={toggleReportDropDown}
                      checkedSource={checkedReportType}
                      filter={filterReportTypeNodes}
                      setInputFilter={isInputRepotTypeCleared}
                      treeNode={reports || []}
                      setCheckBoxValue={setCheckedReportType}
                      setClearFilter={reportItem}
                      clearAll={clearReportType}
                      closeFilter={closeReportTypeFilter}
                      checkedNodes={checkedReportType}
                    />
                    <div className="chips">
                      {checkedReportType.length > 0 && renderChips(checkedReportType, "reportType")}
                    </div>
                  </div>
                )}

                {selectedSourceRadio === 'IBIS World' && (
                  <div className='dropdown-parent'>
                    <CustomFilterDropdown
                      placeholder={'Sub sector'}
                      title={'Sub sector'}
                      customCss={'subsector-display'}
                      toggleSelectedSource={toggleSubSectorDrops}
                      checkedSource={checkedSubsector}
                      filter={filterSubSectorNodes}
                      setInputFilter={isInputSubSectorCleared}
                      treeNode={subSector}
                      setCheckBoxValue={setCheckedSubSector}
                      setClearFilter={subSectorItem}
                      customCondition={subSector.length === 0}
                      clearAll={clearSubSectorItems}
                      closeFilter={closeSubSectorFilter}
                      checkedNodes={checkedSubsector}
                    />
                    <div className="chips">
                      {checkedSubsector.length > 0 && renderChips(checkedSubsector, "subSector")}
                    </div>
                  </div>
                )}

                <div className='dropdown-parent'>
                  <CustomDropdown
                    options={contentAge}
                    placeholder={'Content age'}
                    setdropdownValue={setContentAge}
                    showOptions={showOptions}
                    selectedContentAge={selectedContentAge}
                    customRef={dropdownRef as React.RefObject<HTMLDivElement>}
                    toggleDropdown={toggleDropdown}
                    clearDropdownValues={clearDropdownValues}
                    isContentAgeWithRadio={true}
                    mrFlag={true}
                  />
                  <div className="chips">
                    {renderChipsForContentAge(selectedContentAge)}
                  </div>
                </div>

                <div className='dropdown-parent-search'>
                  <button className='launch-button' onClick={onClickSearchbutton}>Search</button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </StyleHeader>
  );
};
export default MarketReportsInsightHeader;
const StyleHeader = styled.div<{
  buttonColor?: boolean;
  sectorenable: string;
  geographyenable: string;
  subSectorEnable: string;
  reportEnable: string;
  screenWidth?: any;
}>`
  .dropdown-parent {
    position: relative;
    width: 33%;
  }
  .dropdown-parent-search {
    position: relative;
    width: 15%;
  }
  .dropdown-parent-next {
    max-width: 100%;
  }
  .insight-header.insight-header-fixed {
    // width: 100vw;
    // padding-top: 62px;
    // position: fixed;
  }
  .insight-header {
    display: block;
    z-index: 100;
    min-height: 3.85714rem;
  }
  .insight-header-bg {
    background: var(--primary-ey-black-1-a-1-a-24, #1a1a24);
    padding: 36px 60px;
    opacity: 0.7;
  }
  .insight-header-bg-wc {
    background: var(--primary-ey-black-1-a-1-a-24, #1a1a24);
    padding: 22px 60px 4px;
    opacity: 0.7;
  }

  .vl {
    border-left: 3px solid #d1ead1;
    height: 22px;
    padding: 8px;
    position: relative;
    top: 23px;
  }
  .image-round {
    height: 2.51429rem;
    width: 2.51429rem;
    margin-right: 37px;
  }
  .logo-text {
    color: var(--primary-ey-yellow-ffe-600, #ffe600);
    font-family: EYInterstate;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .logo-descr {
    color: var(--primary-grey-100-f-6-f-6-fa, #f6f6fa);
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin: 0;
  }
  .insight-header .insight-header-app-name {
    position: relative;
    left: 5px;
    font-family: ${fontInterstate};
    font-size: 18px;
    line-height: 21px;
    color: ${darkwhite};
    height: auto;
    font-size: 18px;
    bottom: 9px;
  }
  .insight-header .se-search-input-wrapper .se-input-component .se-input {
    background: ${gray15};
    height: 3.20714rem !important;
    position: relative;
    top: 6px;
  }
  .insight-header.insight-header-fixed {
    height: 40px;
  }
  .insight-header-logo {
    left: 12px;
    bottom: 5px;
  }
  .insight-dropdowns {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    align-items: center;
  }
  .clear-all-filter {
    color: var(--specific-use-colors-disabled-text-747480, #747480);
    font-family: EYInterstate;
    font-size: 14px;
    padding: 6px 14px 6px 8px;
    width: 140px;
    text-overflow: ellipsis;
    font-weight: bold;
    border: 1px solid #c3c3cb;

    color: ${(props): string => `${props.buttonColor ? '#747480' : '#2E2E38'}`};
  }
  .dropdowns {
    display: flex;
    gap: 16px;
    width: 97%;
  }
  .hamburgur .se-icon {
    color: ${alternategray} !important;
  }
  .hamburgur {
    background-color: transparent !important ;
    background-color: transparent;
    position: relative;
    left: 5px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 5px;
    disaplay: none;
  }
  .hamburgur:hover {
    background-color: transparent;
  }
  .sticky-filter {
    max-height: 200px;
    overflow: auto;
    padding: 0px 0px 0px 16px;
  }
  @media screen and (max-width: 1600px) {
    .sticky-filter {
      max-height: 136px;
    }
  }
  @media screen and (max-width: 1500px) {
    .sticky-filter {
      max-height: 125px;
    }
  }
  @media screen and (max-width: 1300px) {
    .sticky-filter {
      max-height: 100px;
    }
  }
  .btn-filter {
    width: 44px;
    border: 1px solid ${scssVar.gray40};
    height: 44px;
    position: relative;
    cursor: pointer;
    .filter-icon {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
    }
    .se-icon-button {
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      padding: 0;
      transition: box-shadow 150ms;
      display: none;
      color: #1a1a24;
      & .se-icon {
        background: transparent;
        border: none;
        & img {
          width: 24px;
          height: 24px;
        }
      }

      .insight-header .se-row {
        height: 100%;
        flex-basis: 100%;
      }
    }
    .btn-filter-text {
      width: 100px;
      border: 1px solid ${scssVar.gray40};
      height: 44px;
      position: relative;
      cursor: pointer;
      .filter-text {
        position: absolute;
        top: 0.5rem;
        left: 0.9rem;
      }
    }
    @media screen and (min-width: 64em) {
      .insight-header-company-container {
        flex: 0 0 20%;
        max-width: 100%;
        box-sizing: border-box;
        box-flex: 0;
      }
    }
    @media screen and (min-width: 0) {
      .se-between-xs {
        justify-content: flex-start;
      }
    }
    @media screen and (min-width: 48em) {
      .insight-header .insight-header-search {
        width: 64.85714rem;
      }
    }
    .insight-header-search-field {
      border-color: black;
    }
    .insight-header-icons-placeholder {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
  .sector-display {
    display: ${(props): string => `${props.sectorenable}`};
  }
  .subsector-display {
    display: ${(props): string => `${props.subSectorEnable}`};
  }
  .reporttype-display {
    display: ${(props): string => `${props.reportEnable}`};
  }
  .geography-display {
    display: ${(props): string => `${props.geographyenable}`};
  }

  .button-position {
    position: relative;
    top: 2px;
  }
  .motif-radio-button .motif-radio-button-border {
    background-color: #fff;
    fill: #fff;
  }
  .radio-button-disabled {
     color: #97a3b1;
     fill: #97a3b1;
  }  
  .motif-radio-button-custom {
    border: 1px solid;
  }

  .input:checked + .motif-radio-button-custom .motif-radio-button-bullet {
    background-color: #fff;
    border: 1px solid #fff;
  }

  .motif-radio-button .motif-radio-button-bullet {
    fill: #fff;
    width: 5px !important; /* Adjust this value to change the bullet size */
    height: 5px !important;
  }
  .motif-radio-button svg {
    width: 13.3px;
    height: 13.3px;
  }

  .motif-radio-button-input:disabled + .motif-radio-button-custom {
    background-color: #ccc;
    border: 2px;
  }
  .radio-text {
    padding-right: 12px;
  }

  .chips {
      margin-top: 10px;
      display: flex; 
      flex-wrap: wrap; 
      gap: 8px;
      margin-top: 10px;
    }
   
.motif-chip {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  margin-right: 8px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}

.motif-chip .chip-text {
  max-width: ${(props): string => `${props.screenWidth > 1600 ? '90px' : '70px'}`};
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.motif-chip .chip-close {
  width: 10px;
  height: 15px;
  cursor: pointer;
}

  .more-chips-container {
    display: inline-block;
    position: relative;
    top: 5px;
  }

  .more-chips {
    cursor: pointer;
    color: #85B9FD;
  }

  .popup {
    position: absolute;
    left: 110%;
    top: 0;
    background-color: #2e2e38;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    white-space: nowrap;
    max-height: 150px;
    overflow-y: auto;
  }

  .popup-content {
    background-color: #17171c;
    padding: 20px;
    border-radius: 5px;
  }

  .popup-chip {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .chip-close {
    margin-left: 8px;
  }
  .launch-button{
      height: 48px;
      width: 100%;
      background: rgb(255, 255, 255);
      color: rgb(26, 26, 36);
      border-radius: 6px;
      outline: none;
      border: 1px solid #fff;
      cursor: pointer;
    }
`;
