import { DataSourceTypes } from '../models/IDataSourceTypes';
import { RESPONSE_BY } from '../models/IQueryType';
import { authProvider } from '../services/msalConfig';
import { Guid } from 'guid-typescript';
import {
  getCurrentSessionId,
  initializeSession,
  setCurrentSessionId
} from '../services/session.service';
import chatApiRoutes from '../services/api';
import CryptoJS from 'crypto-js';

/**
 * Returns the URL of the app based on the app name
 * @param appName
 * @returns
 */

export const sanitizeHeaderText = (header: string) => {
  return header.replace('company_', '').replace('_', ' ');
};
/**
 * Opens the link in a new tab with the user's email ID first time and if it is already open, it navigates to the same tab
 * @param item
 * @param userMailID
 */

export function getFileExtension(fileName: any) {
  var re = /(?:\.([^.]+))?$/;
  const match = re.exec(fileName);
  return match && match[1] != null ? match[1] : '';
}
export const isValidURL = (urlString: string) => {
  const urlRegex =
    /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3})|(\[[0-9a-f:]+\]))(:\d+)?(\/[-a-z\d%_.~+:@]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
  return urlRegex.test(urlString);
};

export const toPascalCase = (str: string) => {
  return str.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};
export const checkIsJson = (str: string) => {
  const rx =
    /^[\],:{}\s]*$|\/(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})*$|"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?$/;
  const value = rx.test(str);
  return value;
};
export const downloadFilenameGenerator = (
  from: RESPONSE_BY | 'ALL',
  extension: 'ppt' | 'docx' | 'csv'
) => {
  const guid = Guid.create();
  const source = "Market Reports";

  const fileName = `${source}-${
    extension === 'docx' ? 'conversation' : 'chat reply'
  }-${guid}.${extension}`;
  return fileName;
};

export const GenrateRandomAuthor = () => {
  const random = Math.floor(Math.random() * 10);
  const Anshul = [
    {
      name: 'Anshul Gupta',
      profile:
        'https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Canshul.gupta%40uk.ey.com'
    }
  ];
  const Priyam = [
    {
      name: 'Priyam Lahiri',
      profile:
        'https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Cpriyam.lahiri%40uk.ey.com'
    }
  ];

  const multipleAuthors = [
    {
      name: 'Priyam Lahiri',
      profile:
        'https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Cpriyam.lahiri%40uk.ey.com'
    },
    {
      name: 'Anshul Gupta',
      profile:
        'https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Canshul.gupta%40uk.ey.com'
    }
  ];
  if (random % 3 === 0) {
    return Anshul;
  }
  if (random % 3 === 1) {
    return Priyam;
  } else {
    return multipleAuthors;
  }
};

export const checkOnySpecialCharacter = (str: string) => {
  const rx = /^[^a-zA-Z]+$/;
  var res = rx.test(str);
  return res;
};

export const checkOnlySpaces = (str: string) => {
  const rx = /^\s+$/;
  var res = rx.test(str);
  return res;
};
export const getAuthorizationToken = async () => {
  const res = await authProvider?.getIdToken();
  return res.idToken.rawIdToken;
};

export const getSourceTypeName = (sourceType: string) => {
  switch (sourceType) {
    case 'public':
      return 'External Data';
    case 'internal':
      return 'EY Source Data';
    case 'uploadDocument':
      return 'Upload Document and Query';
    case 'uploadUrl':
      return 'Upload URL and query';
    case 'findEyExpert':
      return 'Find EY Expert';
    default:
      return 'Public';
  }
};

/**
 * Set all threads in the chat history to unselected
 * @param threads
 */
export const setThreadsUnSelected = (threads: any) => {
  threads?.forEach((thread: any) => {
    thread.isSelected = false;
  });
};

/**
 * Return the current data source value using enum based on the selected data source
 * @param checkedCheckboxes
 * @param eyIPInterRadio
 * @param validURLinputValue
 * @returns
 */
export const getCurrentDataSource = (
  checkedCheckboxes: any,
  eyIPInterRadio: any,
  validURLinputValue: any,
  selectedDataSourceSection?: number
) => {
  if (checkedCheckboxes?.length > 0) return DataSourceTypes.PublicData;
  else if (eyIPInterRadio && eyIPInterRadio.length > 0) return DataSourceTypes.EYIP;
  else if (validURLinputValue && validURLinputValue.length > 0) return DataSourceTypes.UploadURL;
  else if (selectedDataSourceSection === 5) return DataSourceTypes.Experts;
  else return DataSourceTypes.BYOD;
};

/**
 * Return the current sub data source value(s) as text based on the selected data source
 * @param checkedCheckboxes
 * @param eyIPInterRadio
 * @param validURLinputValue
 * @returns
 */
export const getCurrentSubDataSource = (
  checkedCheckboxes: any,
  eyIPInterRadio: any,
  validURLinputValue: any
) => {
  if (checkedCheckboxes?.length > 0) return checkedCheckboxes.join(',');
  else if (eyIPInterRadio && eyIPInterRadio.length > 0) return eyIPInterRadio;
  else if (validURLinputValue && validURLinputValue.length > 0) return validURLinputValue;
  else return 'Expert';
};


export const IsJSONResponse = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const handleSessionID = (selectedChatThread: any) => {
  if (selectedChatThread && selectedChatThread?.sessionId) {
    setCurrentSessionId(selectedChatThread?.sessionId);
  } else {
    initializeSession(true);
  }
};

export const truncateString = (str = '', maxLength?: number): string => {
  if (str) {
    maxLength = maxLength ? maxLength : 250;
    if (str?.length > maxLength) {
      const truncatedText = str.substring(0, maxLength).replace(/([^a-zA-Z]+)$/, '');
      return `${truncatedText}...`;
    }
    return str;
  }
  return '';
};
export const getXMonthsDate = (date: Date, months: number) => {
  date.setMonth(date.getMonth() - (months - 1));
  return date;
};

export const handleAvatarInitials = (
  eyIPInterRadio: string = '',
  checkedCheckboxes: string[] = [],
  selectedDataSourceSection?: number
) => {
  let source =
    eyIPInterRadio === ''
      ? checkedCheckboxes?.length > 1
        ? 'Combined'
        : checkedCheckboxes[0]
      : eyIPInterRadio;
  let avatarInitial: string = '';
  if (source === 'Embryonic') {
    avatarInitial = 'E ';
  } else if (source === 'Open AI') {
    avatarInitial = 'Artifical Intelligence';
  } else if (source === 'Surveys') {
    avatarInitial = 'S U';
  } else if (source === 'Discover: Sector Insights') {
    avatarInitial = 'Sector Insights';
  } else if (selectedDataSourceSection === 5) {
    avatarInitial = 'Knowledge Capture';
  } else {
    avatarInitial = 'Competitive Edge';
  }
  return avatarInitial;
};


/**
 * Decode the encoded html string to plain text
 * @param html
 * @returns plain text
 */
export const decodeHTML = (html: string): string => {
  const parser = new DOMParser();

  const decode = (input: string): string => {
    const doc = parser.parseFromString(input, 'text/html');
    const text = doc.body.textContent || '';

    // Check if the output is different from the input,
    // indicating that there was some HTML to parse and remove.
    // If so, call the decode function recursively.
    if (text !== input) {
      return decode(text);
    } else {
      // If the output is the same as the input, return the result,
      // as there is nothing left to decode.
      return text;
    }
  };

  return decode(html);
};

export const routeToLogin = async () => {
    localStorage.clear();
    const currentUrl =
      window.location.pathname === 'error' ? window.location.origin : window.location.href;
    window.location.href =
      'https://login.microsoftonline.com/common/oauth2/logout?client_id=' +
      authProvider.getCurrentConfiguration().auth.clientId +
      '&post_logout_redirect_uri=' +
      encodeURIComponent(currentUrl);
};


export const getCurrentTimeSlotIndex = (date: Date): number => {
  const minutes = date.getMinutes();
  const hours = date.getHours();

  const totalMinutes = hours * 60 + minutes;
  const interval = 15; // 15 minutes gap

  return Math.floor(totalMinutes / interval);
};



export const getFirstFolderName = (path: string) => {
  if (!path) {
    return null;
  }
  const parts = path?.split('/');
  for (const part of parts) {
    if (part.trim() !== '') {
      return part;
    }
  }
  return null;
}
/**
 * Returns the file size from bytes
 * @param bytes 
 * @returns 
 */
export const formatFileSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
};
/**
 * Returns true if the string contains special characters
 * @param name 
 * @returns 
 */
export const isContainSpecialCharacters = (name:string) => {
  // var regex = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
  var regex = /[`!@#$%^*+\=\[\]{};':"\\|,<>\/?~]/;
   return regex.test(name);
 }

 /**
  * Convert an array of headers and values to a plain text table
  * @param array Contains headers and values
  * @returns 
  */
 export const convertArrayToPlainTextTable = (array:any, enableTableBorder = true)=> {
  const columnLengths = array.headers.map((header:string, index:number) => {
    // Start with the length of the header
    let maxLength = header.length;
    // Compare with the length of each value in the column
    array.values.forEach((row:any) => {
      const valueLength = row[index].val.length;
      if (valueLength > maxLength) {
        maxLength = valueLength;
      }     
    });
    return maxLength;
  });

  let headers, separatorRow, rows;
  if (enableTableBorder) {
    // Create the header row with padding
    headers =
      '| ' +
      array.headers
        .map((header: string, index: number) =>
          toPascalCase(sanitizeHeaderText(header)).padEnd(columnLengths[index], ' ')
        )
        .join(' | ') +
      ' |';

    // Create the separator row
    separatorRow = '| ' + columnLengths.map((length: any) => '-'.repeat(length)).join(' | ') + ' |';

    // Map each row of values to a string with padding
    rows = array.values.map(
      (row: any) =>
        '| ' +
        row
          .map((value: any, index: number) => value.val.padEnd(columnLengths[index], ' '))
          .join(' | ') +
        ' |'
    );
  } else {
    // Create the header row with padding
    headers = array.headers
      .map((header: string, index: number) =>
        toPascalCase(sanitizeHeaderText(header)).padEnd(columnLengths[index], ' ')
      )
      .join(' | ');

    // Create the separator row
    separatorRow = columnLengths.map((length: any) => '-'.repeat(length)).join('-|-');

    // Map each row of values to a string with padding
    rows = array.values.map((row: any) =>
      row
        .map((value: any, index: number) => value.val.padEnd(columnLengths[index], ' '))
        .join(' | ')
    );
  }

  

  // Combine headers, separator, and rows into a single string
  const table = [separatorRow, headers, separatorRow, ...rows].join('\n');

  return table;
}

export const convertRootObjectsToTable = (rootObjects: any[]): string => {
  const headers = ['Name', 'Role', 'Type', 'End date', 'Geography'];
  const columnLengths = headers.map(header => header.length);

  const rows = rootObjects.flatMap(rootObject => {
    return rootObject.Authors.map((author:any) => {
      const row = [
        author.author_name || "",
        author.role|| "",
        rootObject.Project_type || "",
        rootObject.Project_End_Date || "",
        rootObject?.Geography ? rootObject?.Geography.join(', ') : ""
      ];

      row.forEach((value, index) => {
        if (value.length > columnLengths[index]) {
          columnLengths[index] = value.length;
        }
      });

      return row;
    });
  });

  const formatRow = (row: string[]) => {
    return '| ' + row.map((value, index) => value.padEnd(columnLengths[index], ' ')).join(' | ') + ' |';
  };

  const separatorRow = '| ' + columnLengths.map(length => '-'.repeat(length)).join(' | ') + ' |';

  const headerRow = formatRow(headers);
  const formattedRows = rows.map(formatRow);

  const table = [separatorRow, headerRow, separatorRow, ...formattedRows, separatorRow].join('\n');
  return table;
};

export const includeParentsIfAllChildrenChecked = (checkedNodes: any, nodes: any) => {
  const updatedChecked = new Set(checkedNodes);

  const areAllChildrenChecked = (node: any, checked: any) => {
    return node.children.every((child: any) => checked.has(child.value));
  };

  const checkParents = (node: any, parents: any) => {
    if (checkedNodes.includes(node.value)) {
      let allChildrenChecked = true;
      for (let i = parents.length - 1; i >= 0; i--) {
        const parent = parents[i];
        if (allChildrenChecked && areAllChildrenChecked(parent, updatedChecked)) {
          updatedChecked.add(parent.value);
        } else {
          allChildrenChecked = false;
        }
      }
    }

    if (node.children) {
      node.children.forEach((child: any) => {
        checkParents(child, [...parents, node]);
      });
    }
  };

  nodes.forEach((node: any) => {
    checkParents(node, []);
  });

  return Array.from(updatedChecked);
};  

export const encryptEmail =(email: string)=> {
  return CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
}

export const numberWithCommas = (x: number) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const splitName = (params: any) => {
  const reportName = params;
  if (!reportName) {
    return { firstLine: '', secondLine: '' };
  }
  if (reportName.length <= 43) {
    return { firstLine: reportName, secondLine: '' };
  }
  const middleIndex = Math.floor(reportName.length / 2);
  let splitIndex = reportName.lastIndexOf(' ', middleIndex);
  if (splitIndex === -1) {
    splitIndex = reportName.indexOf(' ', middleIndex);
  }
  if (splitIndex === -1) {
    splitIndex = middleIndex;
  }
  const firstLine = reportName.substring(0, splitIndex).trim();
  const secondLine = reportName.substring(splitIndex + 1).trim();
  return { firstLine, secondLine };
};

export enum HttpStatusCode {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  Locked = 423,
  InternalServerError = 500,
  Forbidden = 403,
  TimedOut = 504,
  FunctionSelectionError = 501
}

const formatDate = (date: any) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}${month}${year}${hours}${minutes}${seconds}`;
};

export const downloadFilenameGeneratorForPPT = (
  validURLinputValue: any,
  extension: 'ppt' | 'docx' | 'csv' | 'zip'
) => {
  const guid = Guid.create();
  const today = new Date();
  const formattedDate = formatDate(today);

  let sourceType = '';
  
  if (validURLinputValue) {
    sourceType = 'Market Report';
  }

  const fileName = `${sourceType}-${'conversation'}-${formattedDate}.${extension}`;
  return fileName;
};

export const openLinkInSameTab = (item: any, userMailID: string) => {
  const itemName = item?.heading || item?.name;
  if (item?.url) {
    const url = encodeURI(`${item?.url}`);
    const existingWindow = window.open('', `${itemName}_myWindowName`);
    if (existingWindow) {
      existingWindow.location.href = url;
    } else {
      window.open(url, `${itemName}_myWindowName`);
    }
  }
};
